import ErrorFallback from "@/components/error-fallback/error-fallback";
import { LabsListings, LabsIndex } from "@/pages/labs";

const LabsRoutes = {
  path: `labs`,
  children: [
    {
      index: true,
      element: <LabsListings />,
      errorElement: <ErrorFallback />,
    },
    {
      path: `:slug`,
      element: <LabsIndex />,
      errorElement: <ErrorFallback />,
    },
  ],
};

export default LabsRoutes;
