import { apolloApi } from "@/shared/api";
import { ApolloLeagueId } from "@suns/api";
import { useAsync } from "@/shared/hooks";

export function useLeagues() {
  const { loading, response, error, refresh } = useAsync(async () => {
    const data = await apolloApi.getLeagues({});
    return data.leagues.filter(
      (league) =>
        ![ApolloLeagueId.WNBA, ApolloLeagueId.FIBA17].includes(league.id)
    );
  });

  return { leagues: response, loading, error, refresh };
}
