/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IntelTag } from './IntelTag';
import type { PlayerRow } from './PlayerRow';
import type { TeamRow } from './TeamRow';
export type IntelRow = {
    id: number;
    players: Array<PlayerRow>;
    teams: Array<TeamRow>;
    level: IntelRow.level;
    status: IntelRow.status;
    statusUpdatedAt: string;
    notes: string | null;
    source: string | null;
    trustworthiness: number | null;
    trustworthinessMax: number | null;
    authorUsername: string;
    authorName: string;
    tags: Array<IntelTag>;
    updatedAt: string;
    createdAt: string;
};
export namespace IntelRow {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
}

