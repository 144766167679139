import ErrorFallback from "@/components/error-fallback/error-fallback";
import { Glossary } from "@/pages/glossary";

const GlossaryRoutes = {
  path: `glossary`,
  children: [
    {
      index: true,
      element: <Glossary />,
      errorElement: <ErrorFallback />,
    },
  ],
};

export default GlossaryRoutes;
