import { Flex, Skeleton, SkeletonText, Text } from "@suns/design-system";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { TeamLogo } from "@/components/TeamLogo/TeamLogo";
import { ApolloLeagueId } from "@suns/api";

dayjs.extend(relativeTime);

interface TeamHeaderProps {
  teamNbaId?: number;
  teamName?: string;
  teamDivision?: string;
  variant?: "compact" | "wide";
  leagueId?: ApolloLeagueId;
  image?: string;
}

export function TeamHeader({
  teamNbaId,
  teamName,
  teamDivision,
  variant = "compact",
  leagueId,
  image,
}: TeamHeaderProps) {
  return (
    <Flex gap="md" className="content-center items-center">
      <Flex className="aspect-square">
        <TeamLogo
          size="lg"
          teamId={teamNbaId}
          leagueId={leagueId}
          image={image}
        />
      </Flex>
      <Flex
        direction={variant === "compact" ? "down" : "right"}
        gap={variant === "compact" ? undefined : "lg"}
      >
        <Flex direction="down" gap="sm">
          <Text heading={true} size="2xl">
            {teamName}
          </Text>
          <Text>{teamDivision}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export function TeamHeaderSkeleton() {
  return (
    <Flex gap="lg" className="content-center">
      <Skeleton className="aspect-square h-24 w-auto rounded-full" />
      <Flex direction="down" gap="sm">
        <SkeletonText rows={1} className="h-6 w-64" />
        <SkeletonText rows={1} className="h-4 w-40" />
      </Flex>
    </Flex>
  );
}
