import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@suns/design-system/src/components/Pagination/Pagination";

interface PaginationControlParams {
  offset: number;
  limit: number;
  count: number;
  onPageClicked: (page: number) => void;
}

export function PaginationControl({
  offset,
  limit,
  count,
  onPageClicked,
}: PaginationControlParams) {
  const numPages = Math.ceil(count / limit);
  const currentPage = Math.floor(offset / limit);

  // we don't need to render pagination for only 1 page (0 pages)
  if (numPages <= 1) {
    return null;
  }

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            aria-disabled={currentPage < 1}
            className={
              currentPage < 1 ? "pointer-events-none opacity-50" : undefined
            }
            onClick={() => onPageClicked(currentPage)}
          />
        </PaginationItem>
        {[...Array(numPages).keys()].map((i) => {
          if (i === 0 || i === numPages - 1) {
            // first and last page
            return (
              <PaginationItem key={i}>
                <PaginationLink
                  isActive={currentPage === i}
                  onClick={() => onPageClicked(i + 1)}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            );
          } else if (i === currentPage) {
            return (
              <PaginationItem key={i}>
                <PaginationLink
                  isActive={currentPage === i}
                  onClick={() => onPageClicked(i + 1)}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            );
          } else if (
            Math.abs(currentPage - i) === 1 ||
            (i < currentPage && currentPage < 4) ||
            (i > currentPage && currentPage > numPages - 5)
          ) {
            // one page to the left and right of current page
            // or the second or second to last page if it would otherwise be an elipses
            return (
              <PaginationItem key={i}>
                <PaginationLink
                  isActive={currentPage === i}
                  onClick={() => onPageClicked(i + 1)}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            );
          } else if (Math.abs(currentPage - i) === 2) {
            return (
              <PaginationItem key={i}>
                <PaginationEllipsis />
              </PaginationItem>
            );
          }
        })}
        <PaginationItem>
          <PaginationNext
            aria-disabled={currentPage >= numPages - 1}
            className={
              currentPage >= numPages - 1
                ? "pointer-events-none opacity-50"
                : undefined
            }
            onClick={() => onPageClicked(currentPage + 2)}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}
