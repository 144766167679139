import { NotifiableError } from "@bugsnag/js";
import { useCallback, useState } from "react";
import { notify } from "./reporter";

export function useNotify() {
  const [errorHash, setErrorHash] = useState<string | undefined>();

  return useCallback(
    (error: NotifiableError) => {
      const hash = JSON.stringify(error);
      if (errorHash == hash) {
        return;
      }
      setErrorHash(hash);
      notify(error);
    },
    [errorHash]
  );
}
