/* eslint-disable no-console */
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import { getAccount } from "@/shared/auth";
import { SunsApiErrorName } from "@suns/api";

export function isBugsnagConfigured() {
  return import.meta.env.VITE_BUGSNAG_API_KEY != undefined;
}

export function startBugsnag() {
  if (!isBugsnagConfigured()) {
    return;
  }

  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.VITE_RELEASE_STAGE,
    enabledReleaseStages: ["staging", "production"],
    onError: function (event, callback) {
      try {
        const isAuthError = event.errors.some((error) => {
          return error.errorClass === SunsApiErrorName.SunsApiAuthError;
        });
        if (isAuthError) {
          callback(null, false);
          return;
        }
        const { info, isAuthenticated } = getAccount();
        if (isAuthenticated && info) {
          event.setUser(info.homeAccountId, info.username, info.name);
        } else {
          event.setUser(undefined, undefined, "Account not authenticated");
        }
        callback(null);
      } catch (e) {
        callback(e as Error);
      }
    },
    logger: {
      debug: (msg) => console.log(`[BUGSNAG DEBUG] ${msg}`),
      info: (msg) => console.log(`[BUGSNAG INFO] ${msg}`),
      warn: (msg) => console.log(`[BUGSNAG WARN] ${msg}`),
      error: (msg) => console.log(`[BUGSNAG ERROR] ${msg}`),
    },
  });
  BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_API_KEY });
}
