import {
  Flex,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Text,
  TextEditor,
} from "@suns/design-system";
import { FormSkillSelect } from "../FormSkillSelect";
import {
  OffenseShootingScores,
  OffenseSkillScores,
  ReportScoreDescriptions,
} from "@/pages/reports/reports-const";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { useFormState } from "react-hook-form";

export default function OffenseScores({ readonly = false }) {
  const formState = useFormState();
  const type = formState.defaultValues?.type ?? ReportResponseItem.type.GAME;

  return (
    <>
      <Flex direction="down" gap="md">
        <Text size="lg" heading asChild>
          <FormLabel>Offense</FormLabel>
        </Text>
        <Text size="md" muted heading asChild>
          <FormLabel>Skill</FormLabel>
        </Text>
        <Flex direction="right" gap="lg" wrap>
          {OffenseSkillScores.map((score) => (
            <FormSkillSelect
              key={score}
              name={`scores.${score}`}
              label={ReportScoreDescriptions[score].label}
              tooltip={ReportScoreDescriptions[score].description}
              allowNA={type !== ReportResponseItem.type.PHASE}
            />
          ))}
        </Flex>

        <Text size="md" className="mt-4" muted heading asChild>
          <FormLabel>Shooting</FormLabel>
        </Text>
        <Flex direction="right" gap="lg" wrap>
          {OffenseShootingScores.map((score) => (
            <FormSkillSelect
              key={score}
              name={`scores.${score}`}
              label={ReportScoreDescriptions[score].label}
              tooltip={ReportScoreDescriptions[score].description}
              allowNA={type !== ReportResponseItem.type.PHASE}
            />
          ))}
        </Flex>
      </Flex>

      <FormField
        name="offensiveNotes"
        render={({ field }) => (
          <FormItem>
            <Text size="md" muted heading asChild>
              <FormLabel>Offensive notes</FormLabel>
            </Text>
            <FormControl>
              <TextEditor
                readonly={readonly}
                className="h-44"
                {...field}
                defaultValue={formState.defaultValues?.offensiveNotes}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </>
  );
}
