/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlayerStatsResponse } from '../models/PlayerStatsResponse';
import type { TeamStatResponse } from '../models/TeamStatResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BoxScoreService {
    /**
     * @param seasonTypes
     * @param teamAbbr
     * @param calculationType
     * @param season
     * @param noOfGames
     * @returns PlayerStatsResponse Success.
     * @throws ApiError
     */
    public static getPlayerStats(
        seasonTypes: Array<'Pre Season' | 'Regular Season' | 'Playoffs' | 'Play-In Season'>,
        teamAbbr: string,
        calculationType: 'Per_75' | 'Per_Game' | 'Per_100' | 'Single_Game_High' | 'Total',
        season?: string,
        noOfGames?: number,
    ): CancelablePromise<PlayerStatsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/box-score/player-stats',
            query: {
                'season': season,
                'seasonTypes': seasonTypes,
                'teamAbbr': teamAbbr,
                'calculationType': calculationType,
                'noOfGames': noOfGames,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param seasonTypes
     * @param teamAbbr
     * @param calculationType
     * @param season
     * @param noOfGames
     * @returns TeamStatResponse Success.
     * @throws ApiError
     */
    public static getTeamStats(
        seasonTypes: Array<'Pre Season' | 'Regular Season' | 'Playoffs' | 'Play-In Season'>,
        teamAbbr: string,
        calculationType: 'Per_75' | 'Per_Game' | 'Per_100' | 'Single_Game_High' | 'Total',
        season?: string,
        noOfGames?: number,
    ): CancelablePromise<TeamStatResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/box-score/team-stats',
            query: {
                'season': season,
                'seasonTypes': seasonTypes,
                'teamAbbr': teamAbbr,
                'calculationType': calculationType,
                'noOfGames': noOfGames,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
}
