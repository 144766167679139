export const columns = [
  {
    accessor: "title",
  },
  {
    Header: "Stat",
    accessor: "stat",
  },
  {
    Header: "Rank",
    accessor: "rank",
  },
  {
    Header: "Value",
    accessor: "value",
  },
  {
    Header: "League Avg.",
    accessor: "leagueAvg",
  },
];

export const modalColumns = [
  {
    Header: "Rank",
    accessor: "rank",
  },
  {
    Header: "Team",
    accessor: "team",
  },
  {
    Header: "Value",
    accessor: "value",
  },
];
