export class Page {
  limit = 25;

  count = 0;

  offset = 0;

  orderBy = "MP";

  orderDir = "desc";
}
