/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PlayerMetadataRow = {
    target: PlayerMetadataRow.target;
};
export namespace PlayerMetadataRow {
    export enum target {
        TRADE = 'TRADE',
        FREE_AGENT = 'FREE_AGENT',
    }
}

