/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ReportPlayerAttribute = {
    id: number;
    reportId: number;
    key: ReportPlayerAttribute.key;
    value: boolean;
};
export namespace ReportPlayerAttribute {
    export enum key {
        TEAMMATE = 'TEAMMATE',
        COMPETES = 'COMPETES',
        WINNING_PLAYS = 'WINNING_PLAYS',
        UPSIDE = 'UPSIDE',
    }
}

