import { gameReportLoader } from "../reports-create-game/loaders/create-game-report-loader";
import {
  ReportQueryParams,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import { apolloApi } from "@/shared/api";

export async function reportLoader({ reportId }: { reportId: string }) {
  const { report } = await apolloApi.getReport(parseInt(reportId, 10));

  if (report.type == ReportResponseItem.type.GAME) {
    if (!report.gameVendorId)
      throw new Error("Game report is missing gameVendorId");

    const { game } = await gameReportLoader({
      gameVendorId: report.gameVendorId,
    });

    return { report, game };
  }

  if (report.type == ReportResponseItem.type.PHASE) {
    // get all published reports that are not part of a phase report
    const availableReportResponse = await apolloApi.getReports({
      authorUsername: [report?.authorUsername ?? ""],
      status: ReportQueryParams.status.PUBLISHED,
      playerId: `${report?.playerId}`,
      noAssociatedPhaseReport: true,
    });

    // load all games for displaying opponent team names
    // games are populated from reports that are available to be added to this phase report
    // or games that are already part of this phase report
    const gameReportIds = (report?.reports ?? []).map((report) =>
      report.gameVendorId ? `${report.gameVendorId}` : undefined
    );
    const availablePhaseReportGameIds =
      availableReportResponse?.reports?.map((report) => report.gameVendorId) ??
      [];
    const gameIds = [...gameReportIds, ...availablePhaseReportGameIds].filter(
      Boolean
    );

    const gameResponses = await Promise.all(
      gameIds.map(async (gameId) => {
        const gameResponse = await gameReportLoader({
          gameVendorId: gameId ?? "",
        });

        return gameResponse.game;
      })
    );

    // convert array of games to object where keys are gameIds and values are the cooresponding games
    const games = Object.fromEntries(
      gameResponses.map((game) => [game.gameId, game])
    );

    return {
      report,
      availableReports: availableReportResponse.reports.concat(report.reports),
      games,
    };
  }

  return { report };
}
