import { useAccount, useAsync } from "@/shared/hooks";
import { phaseReportLoader } from "./loaders/create-phase-report-loader";
import {
  Badge,
  Button,
  Card,
  Flex,
  Select,
  SelectOption,
  SkeletonText,
  Text,
} from "@suns/design-system";
import { PlayerRow } from "@suns/api/generated-client/apollo";
import { useEffect, useState } from "react";
import {
  PlayerHeader,
  PlayerHeaderSkeleton,
} from "@/components/PlayerHeader/PlayerHeader";
import {
  ReportForm,
  ReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { gameLoader } from "./loaders/game-loader";
import { ReportResponseItem } from "@/swagger";
import {
  reportFormDataToUpsertParams,
  reportResponseToFormData,
} from "../report-utils";
import { apolloApi } from "@/shared/api";
import { toast, ToastType } from "@/shared/utils/toaster";
import { notify } from "@/components/bugsnag";
import Page from "@/components/Page/Page";
import { ReportGame } from "../reports-create-game/loaders/create-game-report-loader";
import { LoaderCircle } from "@suns/design-system/icons";
import { Link } from "react-router-dom";
import { URL } from "@/shared/utils/route-constant";
import { SunsApiError } from "@suns/api";

export function CreatePhaseReport() {
  const account = useAccount();
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerRow | null>(null);
  const [savingPlayerId, setSavingPlayerId] = useState<number | null>(null);
  const [games, setGames] = useState<Record<string, ReportGame> | null>(null);
  const [reports, setReports] = useState<Record<string, ReportResponseItem>>(
    {}
  );

  const { loading, response, refresh, error } = useAsync(phaseReportLoader, {
    authorUsername: account.info?.username ?? "",
  });

  const { response: gamesResponse, error: gamesError } = useAsync(gameLoader, {
    gameIds:
      selectedPlayer && response?.players
        ? (response.players[selectedPlayer.id] ?? [])
            .map((report) => report.gameVendorId)
            .filter((id): id is string => id !== null)
        : null,
  });

  useEffect(() => {
    if (gamesResponse) {
      const newGames = Object.fromEntries(
        gamesResponse.map((game) => [game.gameId, game])
      );

      setGames((prevValue) => ({
        ...prevValue,
        ...newGames,
      }));
    }
  }, [gamesResponse]);

  useEffect(() => {
    if (
      response &&
      !selectedPlayer &&
      !loading &&
      Object.keys(response.players).length > 0
    ) {
      const firstPlayerId = Object.keys(response.players)[0];
      setSelectedPlayer(response.players[firstPlayerId][0].player);
    }

    setReports(response?.phaseReports ?? {});
  }, [response, loading, selectedPlayer]);

  async function handleSubmit(
    report: ReportFormSchema,
    publish: boolean = false
  ) {
    if (publish) {
      setIsPublishing(true);
    } else {
      setIsSaving(true);
    }

    setSavingPlayerId(report.playerId);

    try {
      const upsertParams = reportFormDataToUpsertParams(report);

      const existingReport = reports[report.playerId ?? ""] ?? null;

      if (existingReport != undefined) {
        upsertParams.id = existingReport.id;
      }

      const res = await apolloApi.saveReport(upsertParams);
      setReports({
        ...reports,
        [res.report.playerId]: res.report,
      });

      if (publish) {
        toast(ToastType.SUCCESS, "Report Published");
        setReports({});
        setSelectedPlayer(null);
        refresh();
      } else {
        toast(ToastType.INFO, "Report Saved");
      }

      return res.report.id;
    } catch (e) {
      notify(new Error("Error saving report.", { cause: e }));
      toast(ToastType.ERROR, "Unable to save report. Please try again.");
    } finally {
      setIsSaving(false);
      setIsPublishing(false);
      setSavingPlayerId(null);
    }
  }

  if (error) {
    throw new SunsApiError("Error loading the report.", { cause: error });
  }

  if (gamesError) {
    throw new SunsApiError("Error loading the game data.", {
      cause: gamesError,
    });
  }

  return (
    <Page
      loading={loading || !games}
      loadingState={<CreatePhaseReportLoading />}
      title="Phase Report"
      render={() => {
        if (!response || !account.info) {
          return null;
        }

        if (Object.keys(response.players).length === 0) {
          return (
            <Card className="text-center">
              <Text heading>
                One or more published Game Reports are required to create a
                Phase Report
              </Text>
              <Link
                to={`/${URL.REPORTS.path}/${URL.REPORTS.children.CREATE_REPORT.path}`}
              >
                <Button variant="link">
                  Click here to create a Game Report
                </Button>
              </Link>
            </Card>
          );
        }

        if (!selectedPlayer || !games) {
          return null;
        }

        const activeReport = reportResponseToFormData(
          selectedPlayer,
          account.info,
          ReportResponseItem.type.PHASE,
          [],
          reports[selectedPlayer.id] ?? null,
          null,
          null,
          response.players[selectedPlayer.id]
        );

        return (
          <Flex direction="down" gap="md">
            <Text size="4xl" heading>
              Phase Report
            </Text>
            <Card className="md:hidden">
              <Select
                className="w-full md:hidden"
                value={`${selectedPlayer.id}`}
                onValueChange={(value) => {
                  setSelectedPlayer(response.players[value][0].player);
                }}
              >
                {Object.keys(response.players).map((playerId) => {
                  const firstReport = response.players[playerId][0];
                  const isSaving = `${savingPlayerId}` === playerId;
                  return (
                    <SelectOption key={playerId} value={`${playerId}`}>
                      <Flex direction="right" gap="sm" align="center">
                        <Text
                          heading={`${selectedPlayer.id}` === playerId}
                          muted={`${selectedPlayer.id}` !== playerId}
                        >
                          {firstReport.player.lastName},{" "}
                          {firstReport.player.firstName}
                        </Text>
                        <Badge>{response.players[playerId].length}</Badge>
                        {isSaving ? (
                          <LoaderCircle size={18} className="animate-spin" />
                        ) : null}
                      </Flex>
                    </SelectOption>
                  );
                })}
              </Select>
            </Card>
            <Card className="md:grid md:grid-cols-5 md:space-x-8">
              <Flex direction="down" gap="sm" className="hidden md:flex">
                {Object.keys(response.players).map((playerId) => {
                  const firstReport = response.players[playerId][0];
                  const isSaving = `${savingPlayerId}` === playerId;
                  return (
                    <Button
                      variant="ghost"
                      className="justify-start"
                      size="xs"
                      disabled={isSaving}
                      key={playerId}
                      onClick={() => {
                        setSelectedPlayer(response.players[playerId][0].player);
                      }}
                    >
                      <Flex direction="right" gap="sm" align="center">
                        <Text
                          heading={`${selectedPlayer.id}` === playerId}
                          muted={`${selectedPlayer.id}` !== playerId}
                        >
                          {firstReport.player.lastName},{" "}
                          {firstReport.player.firstName}
                        </Text>
                        <Badge>{response.players[playerId].length}</Badge>
                        {isSaving ? (
                          <LoaderCircle size={18} className="animate-spin" />
                        ) : null}
                      </Flex>
                    </Button>
                  );
                })}
              </Flex>

              <Flex className="col-span-4" direction="down" gap="lg">
                <PlayerHeader
                  player={selectedPlayer}
                  teamNbaId={selectedPlayer.currentTeam?.nbaId ?? undefined}
                  teamName={selectedPlayer.currentTeam?.name}
                  teamImage={selectedPlayer.currentTeam?.image ?? undefined}
                  leagueId={selectedPlayer.currentTeam?.domesticLeagueId}
                />
                <ReportForm
                  key={selectedPlayer.id}
                  report={activeReport}
                  availableReports={response.players[selectedPlayer.id]}
                  gameData={games}
                  isSaving={isSaving}
                  isPublishing={isPublishing}
                  onSubmit={handleSubmit}
                />
              </Flex>
            </Card>
          </Flex>
        );
      }}
    />
  );
}

function CreatePhaseReportLoading() {
  return (
    <>
      <Card className="grid grid-cols-4 space-x-8">
        <Flex direction="down">
          <SkeletonText rows={5} />
        </Flex>
        <PlayerHeaderSkeleton />
      </Card>
    </>
  );
}
