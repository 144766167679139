import { ApolloLeagueId } from "@suns/api";
import {
  cn,
  classVariants,
  type VariantParams,
  responsiveVariants,
} from "@suns/design-system/src/utils";

const sizes = {
  xxs: "w-6",
  xs: "w-10",
  sm: "w-12",
  md: "w-16",
  lg: "w-24",
};

const teamLogoVariants = classVariants([], {
  variants: {
    size: {
      ...sizes,
      ...responsiveVariants("sm", sizes),
      ...responsiveVariants("md", sizes),
      ...responsiveVariants("lg", sizes),
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type TeamLogoProps = React.HTMLAttributes<HTMLDivElement> &
  VariantParams<typeof teamLogoVariants> & {
    image?: string | null;
    teamId?: number | null;
    leagueId?: ApolloLeagueId;
  };

export function TeamLogo({
  image,
  teamId,
  leagueId,
  size,
  className,
  ...props
}: TeamLogoProps) {
  let nbaURL;
  if (leagueId == ApolloLeagueId.WNBA) {
    nbaURL = `https://cdn.wnba.com/logos/wnba/${teamId}/primary/L/logo.svg`;
  } else if (leagueId == ApolloLeagueId.GLEAGUE) {
    nbaURL = `https://ak-static.cms.nba.com/wp-content/uploads/logos/nbagleague/${teamId}/primary/L/logo.svg`;
  } else if (leagueId == ApolloLeagueId.NBA) {
    nbaURL = `https://cdn.nba.com/logos/nba/${teamId}/primary/L/logo.svg`;
  }
  const src = nbaURL ?? image;

  return src ? (
    <div className={cn(teamLogoVariants({ size }), className)} {...props}>
      <img src={src} className="w-full" />
    </div>
  ) : null;
}
