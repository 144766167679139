import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import { ApolloLeagueId } from "@suns/api";

export { labsList } from "./labs";
export { glossaryList, StatName } from "./glossary";

export const Suns = {
  id: 1610612756,
  abbr: "PHX",
  fullName: "Phoenix Suns",
};

export const Mercury = {
  id: 1611661317,
  abbr: "PHO",
  fullName: "Phoenix Mercury",
};

export const defaultFilterValues = {
  level: PlayerLevel.PRO,
  teamId: [],
  playerId: undefined,
  authorUsername: [],
};

export const AvailableLeagues = [
  ApolloLeagueId.NBA,
  ApolloLeagueId.NCAA,
  ApolloLeagueId.SPAIN,
  ApolloLeagueId.ITALY,
  ApolloLeagueId.AUSTRALIA,
  ApolloLeagueId.FRANCE_PRO_A,
  ApolloLeagueId.FRANCE_PRO_B,
  ApolloLeagueId.GREECE,
  ApolloLeagueId.TURKEY,
  ApolloLeagueId.RUSSIA,
];
