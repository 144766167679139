import { toast as toastify } from "react-toastify";

export enum ToastType {
  INFO,
  SUCCESS,
  ERROR,
}

export function toast(type: ToastType, message: string, duration = 2000) {
  let toastFunction = toastify.info;
  switch (type) {
    case ToastType.ERROR:
      toastFunction = toastify.error;
      break;
    case ToastType.SUCCESS:
      toastFunction = toastify.success;
      break;
    case ToastType.INFO:
      toastFunction = toastify.info;
  }

  toastFunction(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: duration,
    hideProgressBar: true,
    theme: "colored",
  });
}
