import { loginRequest } from "@/msal.config";
import { msalInstance } from "./instance";
import { getAccount } from "./account";
import { AccountInfo } from "@azure/msal-browser";
import { promptLogin } from "@/components/LoginDialog/LoginDialog";

export async function getAuthToken(): Promise<string | null> {
  let account: AccountInfo | null = null;
  account = getAccount().info;

  if (!account) {
    try {
      account = await promptLogin();

      if (!account) {
        return null;
      }
    } catch {
      return null;
    }
  }

  try {
    const { idToken } = await acquireTokenSilent(account);

    if (!idToken) {
      return await acquireTokenPopup(account);
    }

    return idToken;
  } catch {
    return await acquireTokenPopup(account);
  }
}

let forceTokenRefresh = true;
async function acquireTokenSilent(account: AccountInfo) {
  const token = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    forceRefresh: forceTokenRefresh,
    account,
  });

  if (forceTokenRefresh) {
    forceTokenRefresh = false;
    setTimeout(
      () => {
        forceTokenRefresh = true;
      },
      1000 * 60 * 15 // 15 minutes
    );
  }

  return token;
}

async function acquireTokenPopup(account: AccountInfo) {
  try {
    const { idToken } = await msalInstance.acquireTokenPopup({
      ...loginRequest,
      account,
    });
    return idToken;
  } catch {
    return null;
  }
}
