export enum LeagueId {
  Wnba = "10",
  GLeague = "20",
  NBA = "00",
  SacramentoSummerLeague = "13",
  OrlandoSummerLeague = "14",
  VegasSummerLeague = "15",
  UtahSummerLeague = "16",
  Nba2k5v5 = "12",
  Nba2k3v3 = "22",
}

export enum ApolloLeagueId {
  NBA = 1,
  NCAA = 101,
  SPAIN = 201,
  ITALY = 202,
  GERMANY = 203,
  AUSTRALIA = 204,
  FRANCE_PRO_A = 301,
  FIBA17 = 402,
  WNBA = 701,
  GLEAGUE = 801,
  GREECE = 901,
  TURKEY = 902,
  FRANCE_PRO_B = 903,
  RUSSIA = 1001,
}
