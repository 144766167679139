import { TableSkeleton } from "../table-skeleton/table-skeleton";
import { BoxScoreTableTabs } from "../../types/enums/gameDna.enums";
import { boxScoreColumns } from "../../pages/game-dna/game-dna-index/game-dna-columns";
import Table from "../table/table";
import { Game } from "../../swagger";
type BoxScoreTypes = {
  selectedBoxScoreTab: string;
  setSelectedBoxScoreTab: React.Dispatch<React.SetStateAction<string>>;
  setBoxScoreTableData: React.Dispatch<React.SetStateAction<object[]>>;
  boxScoreLoading: boolean;
  boxScoreTableData: object[];
  game?: Game;
  boxScoreData: {
    all: object[];
    firstHalf: object[];
    secondHalf: object[];
    overTime: object[];
  };
};
const BoxScore = ({
  selectedBoxScoreTab,
  setSelectedBoxScoreTab,
  setBoxScoreTableData,
  boxScoreLoading,
  boxScoreTableData,
  game,
  boxScoreData,
}: BoxScoreTypes) => {
  return (
    <div className="common-card box-score-card">
      <div className="card-head">
        <h4>BOX SCORE</h4>
      </div>
      <div className="tabbing-ui">
        <ul>
          <li
            className={
              selectedBoxScoreTab === BoxScoreTableTabs.ALL ? "active" : ""
            }
          >
            <a
              onClick={() => {
                setSelectedBoxScoreTab(BoxScoreTableTabs.ALL);
                setBoxScoreTableData(boxScoreData.all);
              }}
            >
              All
            </a>
          </li>
          <li
            className={
              selectedBoxScoreTab === BoxScoreTableTabs.FIRST_HALF
                ? "active"
                : ""
            }
          >
            <a
              onClick={() => {
                setSelectedBoxScoreTab(BoxScoreTableTabs.FIRST_HALF);
                setBoxScoreTableData(boxScoreData.firstHalf);
              }}
            >
              First Half
            </a>
          </li>
          <li
            className={
              selectedBoxScoreTab === BoxScoreTableTabs.SECOND_HALF
                ? "active"
                : ""
            }
          >
            <a
              onClick={() => {
                setSelectedBoxScoreTab(BoxScoreTableTabs.SECOND_HALF);
                setBoxScoreTableData(boxScoreData.secondHalf);
              }}
            >
              Second Half
            </a>
          </li>
          <li
            className={
              selectedBoxScoreTab === BoxScoreTableTabs.OVER_TIME
                ? "active"
                : ""
            }
          >
            <a
              onClick={() => {
                setSelectedBoxScoreTab(BoxScoreTableTabs.OVER_TIME);
                setBoxScoreTableData(boxScoreData.overTime);
              }}
            >
              Overtime
            </a>
          </li>
        </ul>
      </div>
      <div className="table-container-block">
        <div className="table-ui ignore-first-column text-center">
          <TableSkeleton
            columnsCount={13}
            rowsCount={3}
            outerRowsCount={1}
            fromTeamDNA={false}
            isLoading={boxScoreLoading}
          >
            <Table
              columns={boxScoreColumns}
              data={boxScoreTableData}
              upperHeading={[
                { key: game?.teamAbbr, col: 7, isPrimary: true },
                { key: game?.oppTeamAbbr, col: 7, isPrimary: false },
              ]}
            />
          </TableSkeleton>
        </div>
      </div>
    </div>
  );
};

export default BoxScore;
