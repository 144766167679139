import {
  Card,
  Flex,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Button,
  Text,
} from "@suns/design-system";

import GameSelection from "./components/GameSelection";
import LeagueSelect from "./components/LeagueSelect";
import { DatePicker } from "@suns/design-system/src/components/DatePicker/DatePicker";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Game, useReportSelect } from "./hooks/useReportSelect";
import { SunsApiError } from "@suns/api";
import { usePlayerList } from "./hooks/usePlayerList";
import { PlayerSearch } from "@/components";
import Page from "@/components/Page/Page";
import UserRoleGuard from "@/components/UserRoleGuard/UserRoleGuard";
import { URL } from "@/shared/utils/route-constant";
import { AvailableLeagues } from "@/shared/const";

enum ReportTypeValue {
  GAME = "Game",
  PLAYER = "Player",
}

export function ReportsCreate() {
  const [date, setDate] = useState<Date>(new Date());
  const [selectedGame, setSelectedGame] = useState<Game>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<ReportTypeValue>(
    ReportTypeValue.GAME
  );

  const { leagues, selectedLeague, setSelectedLeague, games, loading, error } =
    useReportSelect(date);
  const {
    response: playerList,
    error: playerListError,
    loading: playerListLoading,
  } = usePlayerList();

  const navigate = useNavigate();

  function handleTabChange(newTab: string) {
    if (newTab !== activeTab) {
      setActiveTab(newTab as ReportTypeValue);
      setSearchParams({ reportType: newTab });
    }
  }
  const reportTypeFromParams = searchParams.get("reportType");

  const handleSelectGame = (game: Game) => {
    setSelectedGame(game);
    navigate(`game/${game.gameId}`);
  };

  if (error || playerListError) {
    throw new SunsApiError("Unable to load games.", {
      cause: error ?? playerListError!,
    });
  }

  const handleSelectPlayer = (playerId: string | null) => {
    if (!playerId) return;
    navigate(`player/${playerId}`);
  };

  const filteredLeagues =
    leagues
      ?.filter((league) => AvailableLeagues.includes(league.id))
      .sort((a, b) => a.name.localeCompare(b.name)) || [];

  return (
    <Page title="Create Report">
      <Card className="h-full">
        <Tabs
          defaultValue={reportTypeFromParams ?? ReportTypeValue.GAME}
          className="w-full"
          onValueChange={handleTabChange}
        >
          <Flex justify="between">
            <TabsList>
              <TabsTrigger value={ReportTypeValue.GAME}>
                <span>{ReportTypeValue.GAME}</span>
              </TabsTrigger>
              <TabsTrigger value={ReportTypeValue.PLAYER}>
                <span>{ReportTypeValue.PLAYER}</span>
              </TabsTrigger>
            </TabsList>
            <UserRoleGuard allowedRoles={["hasBetaScoutingAccess"]}>
              <Button type="button" size="sm">
                <Link
                  to={`/${URL.REPORTS.path}/${URL.REPORTS.children.CREATE_PHASE_REPORT.path}`}
                >
                  Create Phase Report
                </Link>
              </Button>
            </UserRoleGuard>
          </Flex>
          <TabsContent className="mt-6" value={ReportTypeValue.GAME}>
            <Flex gap="md">
              <DatePicker date={date} setDate={setDate} />
              <LeagueSelect
                leagueList={filteredLeagues}
                selectedLeague={selectedLeague}
                setSelectedLeague={setSelectedLeague}
              />
            </Flex>
            <GameSelection
              selectedGame={selectedGame}
              handleSelectGame={handleSelectGame}
              games={games}
              loading={loading}
            />
          </TabsContent>
          <TabsContent className="mt-6" value={ReportTypeValue.PLAYER}>
            <Text className="mb-2" heading>
              Create a report for a player
            </Text>
            <PlayerSearch
              players={playerList || []}
              loading={playerListLoading}
              onSelectedValue={handleSelectPlayer}
            />
          </TabsContent>
        </Tabs>
      </Card>
    </Page>
  );
}
