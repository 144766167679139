import { Button, Flex, Select, SelectOption } from "@suns/design-system";
import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import { FilterValues } from "../all-reports-listings";
import { X } from "lucide-react";
import { Filter } from "@suns/design-system/src/components/Filter/Filter";
import { PlayerSearch } from "@/components/PlayerSearch/PlayerSearch";
import { PlayerCacheItem } from "@suns/api/generated-client/apollo";

interface ReportFiltersProps {
  type: "Report" | "Intel";
  onChange: (filterValues: FilterValues) => void;
  onClear: () => void;
  filterValues: FilterValues;
  teams: {
    key: string;
    name: string;
  }[];
  authors?: {
    key: string;
    name: string;
  }[];

  loading: boolean;
  playerList: PlayerCacheItem[];
  playerListLoading: boolean;
}

export default function ReportFilters({
  type,
  onChange,
  onClear,
  filterValues,
  teams,
  authors,
  loading,
  playerList,
  playerListLoading,
}: ReportFiltersProps) {
  const player = filterValues.playerId
    ? playerList?.find(
        (player) => player.id.toString() === filterValues.playerId
      )
    : null;

  return (
    <Flex gap="sm" align="center" wrap={true} className="w-full">
      <PlayerSearch
        players={playerList || []}
        loading={playerListLoading}
        onSelectedValue={(value) => {
          onChange({ ...filterValues, playerId: value || undefined });
        }}
        defaultSelectedPlayer={player}
        className="list"
      />
      <Select
        className="w-36 bg-white"
        value={filterValues.level || PlayerLevel.PRO}
        onValueChange={(value) => onChange({ ...filterValues, level: value })}
      >
        <SelectOption value={PlayerLevel.PRO} id={PlayerLevel.PRO}>
          {PlayerLevel.PRO}
        </SelectOption>
        <SelectOption value={PlayerLevel.AMATEUR} id={PlayerLevel.AMATEUR}>
          {PlayerLevel.AMATEUR}
        </SelectOption>
      </Select>

      {type === "Report" && (
        <Filter
          avoidCollisions={false}
          title={loading ? "Loading..." : "Team"}
          value={filterValues.teamId || []}
          onChange={(value) =>
            onChange({ ...filterValues, teamId: value as string[] })
          }
          options={teams.map((team) => ({
            key: team.key,
            name: team.name,
          }))}
        />
      )}

      {authors && (
        <Filter
          avoidCollisions={false}
          title={loading ? "Loading..." : "Author"}
          value={filterValues.authorUsername || []}
          onChange={(value) =>
            onChange({
              ...filterValues,
              authorUsername: value as string[],
            })
          }
          options={authors?.map((author) => ({
            key: author.key,
            name: author.name,
          }))}
        />
      )}
      <Button variant="ghost" onClick={onClear}>
        <Flex gap="xs" align="center" justify="center">
          Reset
          <X className="w-4 pt-0.5" />
        </Flex>
      </Button>
    </Flex>
  );
}
