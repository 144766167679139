import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  componentRef: null,
};

const printRef = createSlice({
  name: "printRef",
  initialState,
  reducers: {
    setPrintRef: (state, action) => {
      state.componentRef = action.payload;
    },
  },
});

export const { setPrintRef } = printRef.actions;
export default printRef.reducer;
