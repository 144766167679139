import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  constants: { season: " " },
};

const setConstantsSlice = createSlice({
  name: "constants",
  initialState,
  reducers: {
    setConstants: (state, action) => {
      state.constants = action.payload;
    },
  },
});

export const { setConstants } = setConstantsSlice.actions;
export default setConstantsSlice.reducer;
