import { gameReportLoader } from "../../reports-create-game/loaders/create-game-report-loader";

interface GameLoaderParams {
  gameIds: string[] | null;
}
export async function gameLoader({ gameIds }: GameLoaderParams) {
  if (!gameIds) {
    return [];
  }

  const games = await Promise.all(
    gameIds.map(async (gameId) => {
      const gameResponse = await gameReportLoader({
        gameVendorId: gameId,
      });

      return gameResponse.game;
    })
  );

  return games;
}
