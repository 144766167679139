import React, { useMemo } from "react";
import Bugsnag from "@bugsnag/js";
import { isBugsnagConfigured } from "./start-bugsnag";

interface BugsnagProps {
  children: React.ReactNode;
}

export function BugsnagBoundary({ children }: BugsnagProps) {
  const ErrorBoundary = useMemo(() => {
    if (isBugsnagConfigured()) {
      return Bugsnag.getPlugin("react")?.createErrorBoundary(React);
    }
  }, []);

  return ErrorBoundary ? <ErrorBoundary>{children}</ErrorBoundary> : children;
}
