import { LeagueRow } from "@/swagger";
import { Select, SelectOption } from "@suns/design-system";

interface LeagueSelectProps {
  leagueList: LeagueRow[];
  selectedLeague?: LeagueRow;
  setSelectedLeague: (league: LeagueRow) => void;
}

export default function LeagueSelect({
  leagueList,
  selectedLeague,
  setSelectedLeague,
}: LeagueSelectProps) {
  return (
    <Select
      className="bg-white"
      value={selectedLeague?.name || "Loading..."}
      onValueChange={(leagueName) =>
        setSelectedLeague(leagueList.find((l) => l.name === leagueName)!)
      }
    >
      {leagueList.map((league) => (
        <SelectOption key={league.id} value={league.name}>
          {league.name}
        </SelectOption>
      ))}
    </Select>
  );
}
