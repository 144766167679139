import { useRouteError } from "react-router-dom";
import { notify } from "../bugsnag";
import { PageError } from "../PageError/PageError";
import { SunsApiError } from "@suns/api";
import { NavigateLogin } from "../NavigateLogin/NavigateLogin";

const ErrorFallback = () => {
  const error = useRouteError();
  if (error) {
    if ((error as SunsApiError).authError) {
      return <NavigateLogin />;
    } else {
      notify(error as Error);
    }
  }

  return (
    <PageError
      title="Something went wrong."
      message="We've been alerted that there was an issue. We'll do our best to address the problem in a timely manner. We apologize for any inconvenience."
      error={error as Error}
    />
  );
};

export default ErrorFallback;
