import dayjs from "dayjs";
import { Calendar as CalendarIcon } from "@suns/design-system/icons";

import { cn } from "../../utils";
import { Button } from "../Button/Button";

import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import { useState } from "react";
import { Calendar } from "../Calendar/Calendar";

export function DatePicker({
  date,
  setDate,
}: {
  date: Date;
  setDate: (date: Date) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleDateSelect = () => {
    setIsOpen(false);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-48 justify-start text-left text-sm font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? (
            dayjs(date).format("MMMM DD, YYYY")
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          required
          selected={date}
          onSelect={setDate}
          defaultMonth={date ? new Date(date) : new Date(2024, 9)}
          onDayClick={handleDateSelect}
        />
      </PopoverContent>
    </Popover>
  );
}
