/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LineupsResponse } from '../models/LineupsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LineupsService {
    /**
     * @param lineups
     * @param seasonTypes
     * @param teamAbbr
     * @param season
     * @param noOfGames
     * @param minimumMinutesPlayed
     * @param playersInLineup
     * @param playersNotInLineup
     * @param limit
     * @param offset
     * @param orderBy
     * @param orderDir
     * @returns LineupsResponse Success.
     * @throws ApiError
     */
    public static getLineups(
        lineups: string,
        seasonTypes: Array<'Pre Season' | 'Regular Season' | 'Playoffs' | 'Play-In Season'>,
        teamAbbr: string,
        season?: string,
        noOfGames?: number,
        minimumMinutesPlayed: string = '5',
        playersInLineup?: Array<string>,
        playersNotInLineup?: Array<string>,
        limit: number = 25,
        offset?: number,
        orderBy: string = 'MP',
        orderDir: string = 'desc',
    ): CancelablePromise<LineupsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/lineups/{lineups}',
            path: {
                'lineups': lineups,
            },
            query: {
                'season': season,
                'seasonTypes': seasonTypes,
                'teamAbbr': teamAbbr,
                'noOfGames': noOfGames,
                'minimumMinutesPlayed': minimumMinutesPlayed,
                'playersInLineup': playersInLineup,
                'playersNotInLineup': playersNotInLineup,
                'limit': limit,
                'offset': offset,
                'orderBy': orderBy,
                'orderDir': orderDir,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
}
