import { Column, Row } from "react-table";
import { shortenName } from "@/shared/utils/helper-functions";
import { LineupsRow } from "@/swagger";
import { SeasonTypes } from "@/types/enums/gameDna.enums";

export const boxScoreColumns = [
  {
    Header: "OREB",
    accessor: "oreb",
  },
  {
    Header: "TOV",
    accessor: "tov",
  },
  {
    Header: "FTA",
    accessor: "fta",
  },
  {
    Header: "FG%",
    accessor: "fgpct",
  },
  {
    Header: "PPP",
    accessor: "ppp",
  },
  {
    Header: "PTS",
    accessor: "points",
  },
  {
    Header: "QTR",
    accessor: "quarter",
  },
  {
    Header: "PTS",
    accessor: "opp_point",
  },
  {
    Header: "PPP",
    accessor: "opp_ppp",
  },
  {
    Header: "FG%",
    accessor: "opp_fgpct",
  },
  {
    Header: "FTA",
    accessor: "opp_fta",
  },
  {
    Header: "TOV",
    accessor: "opp_tov",
  },
  {
    Header: "OREB",
    accessor: "opp_oreb",
  },
];

export const playTypesColumns = [
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "PTS",
    accessor: "pts",
  },
  {
    Header: "POSS",
    accessor: "poss",
  },
  {
    Header: "PPP",
    accessor: "ppp",
  },
  {
    Header: "XPPP",
    accessor: "xppp",
  },
];

export function advancedBoxColumns(seasonType?: string) {
  const seasonHeader =
    seasonType == SeasonTypes.PLAY_IN_SEASON
      ? "Play-in"
      : seasonType == SeasonTypes.POST_SEASON
        ? "Playoff"
        : "Season";
  return [
    {
      Header: "Stat",
      accessor: "stat",
    },
    {
      Header: "Value",
      accessor: "value",
    },

    {
      Header: `${seasonHeader} Avg.`,
      accessor: "seasonStat",
    },
    {
      Header: `${seasonHeader} Rank`,
      accessor: "seasonRank",
    },
  ];
}

export const pushingThePaceColumns = [
  {
    Header: "Team",
    accessor: "TEAM",
  },
  {
    Header: "PTS",
    accessor: "PTS",
  },
  {
    Header: "POSS",
    accessor: "POSS",
  },
  {
    Header: "PPP",
    accessor: "PPP",
  },
];

export const courtAndTransitionColumns = [
  {
    Header: "POSS",
    accessor: "TEAMPOSS",
  },
  {
    Header: "PTS",
    accessor: "TEAMPTS",
  },
  {
    Header: "PPP",
    accessor: "TEAMPPP",
  },
  {
    Header: "TYPE",
    accessor: "TYPE",
  },
  {
    Header: "PPP",
    accessor: "OPPPPP",
  },
  {
    Header: "PTS",
    accessor: "OPPPTS",
  },
  {
    Header: "POSS",
    accessor: "OPPPOSS",
  },
];

export const paintAttackColumns = [
  {
    Header: "POSS",
    accessor: "TEAMPOSS",
  },
  {
    Header: "PTS",
    accessor: "TEAMPTS",
  },
  {
    Header: "PPP",
    accessor: "TEAMPPP",
  },
  {
    Header: "TYPE",
    accessor: "TYPE",
  },
  {
    Header: "PPP",
    accessor: "OPPPPP",
  },
  {
    Header: "PTS",
    accessor: "OPPPTS",
  },
  {
    Header: "POSS",
    accessor: "OPPPOSS",
  },
];

export const lineupsColumns: Column<LineupsRow>[] = [
  {
    Header: "Players",
    accessor: (row: LineupsRow) => {
      return `${playerNames(row)}`;
    },
  },
  {
    Header: "Min",
    accessor: "MP",
  },
  {
    Header: "ORtg",
    accessor: "ORTG",
  },
  {
    Header: "DRtg",
    accessor: "DRTG",
  },
  {
    Header: "NRtg",
    accessor: "NET_RTG",
    sortType: sortPlusMinus,
  },
  {
    Header: "+ / -",
    accessor: "PLUS_MINUS",
    sortType: sortPlusMinus,
  },
];

export const shootingColumns = [
  {
    Header: "Location",
    accessor: "LOCATION",
  },
  {
    Header: "FGM-FGA",
    accessor: "FGM_FGA",
  },
  {
    Header: "FG%",
    accessor: "FG_PCT",
  },
  {
    Header: "PPP",
    accessor: "PPP",
  },
  {
    Header: "XPPP",
    accessor: "XPPP",
  },
  {
    Header: "FGM-FGA",
    accessor: "OPP_FGM_OPP_FGA",
  },
  {
    Header: "FG%",
    accessor: "OPP_FG_PCT",
  },
  {
    Header: "PPP",
    accessor: "OPP_PPP",
  },
  {
    Header: "XPPP",
    accessor: "OPP_XPPP",
  },
];

export const crashersColumns = [
  {
    Header: "Number of Crashers",
    accessor: "numCrashers",
  },
  {
    Header: "# of Shots",
    accessor: "numShots",
  },
  {
    Header: "% of Time",
    accessor: "pctShots",
  },
  {
    Header: "ORB",
    accessor: "orb",
  },
  {
    Header: "ORB%",
    accessor: "orbPct",
  },
  {
    Header: "PPP",
    accessor: "ppp",
  },
  {
    Header: "XPPP",
    accessor: "xppp",
  },
];

const playerNames = (row: LineupsRow) => {
  let shortenedNames = "";

  for (let i = 1; i <= 5; i++) {
    const fullName = row[`PLAYER_${i}_FULL_NAME` as keyof LineupsRow];
    if (fullName) {
      shortenedNames += `${shortenName(fullName)} - `;
    }
  }

  if (shortenedNames.endsWith(" - ")) {
    shortenedNames = shortenedNames.slice(0, -3);
  }
  return shortenedNames;
};
export const frequentPlayerActionsColumns = [
  {
    Header: "title",
    accessor: "title",
  },
  {
    Header: "Player",
    accessor: "PLAYER",
  },
  {
    Header: "Action",
    accessor: "ACTION",
  },
  {
    Header: "PTS",
    accessor: "PTS",
  },
  {
    Header: "POSS",
    accessor: "POSS",
  },
  {
    Header: "PPP",
    accessor: "PPP",
  },
  {
    Header: "XPPP",
    accessor: "XPPP",
  },
];

function sortPlusMinus<T extends object>(
  rowA: Row<T>,
  rowB: Row<T>,
  column: string
) {
  const valueA = rowA.values[column];
  const valueB = rowB.values[column];
  return Number(valueA) - Number(valueB);
}
