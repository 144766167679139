import { useAccount, useAsync } from "@/shared/hooks";
import {
  Card,
  Flex,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { myIntelLoader } from "./loaders/my-intel-loader";
import { IntelQueryParams, TeamRow } from "@suns/api/generated-client/apollo";
import { useEffect, useState } from "react";
import { PaginationControl } from "@/components";
import { IntelListItem } from "./components/IntelListItem";
import { ApolloLeagueId, SunsApiError } from "@suns/api";

import { useSearchParams } from "react-router-dom";
import { defaultFilterValues } from "@/shared/const";
import { useTeams } from "../hooks/useTeams";
import ReportFilters from "@/pages/reports/reports-listings/components/ReportFilters";
import { usePlayerList } from "@/pages/reports/reports-create/hooks/usePlayerList";
import { FilterValues } from "@/pages/reports/reports-listings/all-reports-listings";
import { PlayerLevel } from "../intel-create-player-team/tags";

const PAGE_SIZE = 20;

export function IntelListings() {
  const account = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const { response: playerList, loading: playerListLoading } = usePlayerList();

  const getParamsFromUrl = () => ({
    level: searchParams.get("level") || PlayerLevel.PRO,
    teamId: searchParams.getAll("teamId") || undefined,
    playerId: searchParams.getAll("playerId")?.[0] || undefined,
  });

  const [activeTab, setActiveTab] = useState("published");
  const [publishedPage, setPublishedPage] = useState(0);
  const [unpublishedPage, setUnpublishedPage] = useState(0);

  const [filterValues, setFilterValues] =
    useState<FilterValues>(getParamsFromUrl());

  const {
    loading: publishedLoading,
    response: publishedResponse,
    error: publishedError,
    refresh: refreshPublished,
  } = useAsync(myIntelLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: IntelQueryParams.status.PUBLISHED,
    offset: publishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamId: filterValues.teamId?.map(Number),
    playerId: filterValues.playerId
      ? parseInt(filterValues.playerId)
      : undefined,
    level: filterValues.level as IntelQueryParams.level | undefined,
  });

  const {
    loading: unpublishedLoading,
    response: unpublishedResponse,
    error: unpublishedError,
    refresh: refreshUnpublished,
  } = useAsync(myIntelLoader, {
    authorUsername: account.info?.username ? [account.info?.username] : [],
    status: IntelQueryParams.status.UNPUBLISHED,
    offset: unpublishedPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    teamId: filterValues.teamId?.map(Number),
    playerId: filterValues.playerId
      ? parseInt(filterValues.playerId)
      : undefined,
    level: filterValues.level as IntelQueryParams.level | undefined,
  });

  const league = filterValues.level?.includes(PlayerLevel.PRO)
    ? ApolloLeagueId.NBA
    : ApolloLeagueId.NCAA;

  const {
    response: teamsReponse,
    loading: teamsLoading,
    error: teamsError,
  } = useTeams(league);

  if (publishedError) {
    throw new SunsApiError("Unable to load intel.", { cause: publishedError });
  }

  if (unpublishedError) {
    throw new SunsApiError("Unable to load intel.", {
      cause: unpublishedError,
    });
  }

  if (teamsError) {
    throw new SunsApiError("Unable to load teams.", {
      cause: teamsError,
    });
  }

  const currentPublishedPage = Math.floor(
    (publishedResponse?.offset || 0) / (publishedResponse?.limit || 1)
  );
  if (publishedPage !== currentPublishedPage) {
    window.scrollTo({ top: 0 });
    refreshPublished();
  }

  const currentUnpublishedPage = Math.floor(
    (unpublishedResponse?.offset || 0) / (unpublishedResponse?.limit || 1)
  );
  if (unpublishedPage !== currentUnpublishedPage) {
    window.scrollTo({ top: 0 });
    refreshUnpublished();
  }

  const teams = teamsReponse?.conferences
    .reduce<TeamRow[]>((allTeams, conference) => {
      const teamsInConference = teamsReponse.teams[conference] || [];
      return allTeams.concat(teamsInConference);
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((team) => ({
      key: team.id.toString(),
      name: team.name,
    }));

  const handleChangeFilterValues = (value: FilterValues) => {
    setPublishedPage(0);
    setUnpublishedPage(0);
    const updatedFilters = { ...filterValues, ...value };
    setFilterValues(updatedFilters);

    const params = new URLSearchParams();

    Object.entries(updatedFilters).forEach(([key, values]) => {
      if (values == null) return;

      if (Array.isArray(values)) {
        values.forEach((val) => val && params.append(key, val));
      } else {
        params.append(key, values.toString());
      }
    });

    setSearchParams(params);
  };

  const handleClear = () => {
    setSearchParams({});
    setFilterValues(defaultFilterValues);
  };

  useEffect(() => {
    const urlParams = getParamsFromUrl();
    setFilterValues(urlParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Card
      header={
        <Text size="xl" heading>
          My Intel
        </Text>
      }
    >
      <Tabs onValueChange={setActiveTab} value={activeTab}>
        <TabsList>
          <TabsTrigger value="published">
            <Text>Published</Text>
          </TabsTrigger>
          <TabsTrigger value="unpublished">
            <Text>Unpublished</Text>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="published">
          <ReportFilters
            type="Intel"
            onChange={handleChangeFilterValues}
            onClear={handleClear}
            filterValues={filterValues}
            teams={teams || []}
            loading={teamsLoading}
            playerList={playerList || []}
            playerListLoading={playerListLoading}
          />

          {publishedLoading ? (
            <IntelListingsLoading />
          ) : (
            <>
              {publishedResponse && publishedResponse?.intel?.length > 0 ? (
                <Flex direction="down" className="divide-y-2">
                  {publishedResponse?.intel.map((intel) => (
                    <IntelListItem
                      key={intel.id}
                      className="py-4"
                      intel={intel}
                    />
                  ))}
                </Flex>
              ) : (
                <Text className="py-10 text-center" size="lg" muted>
                  No intel found.
                </Text>
              )}
              <PaginationControl
                {...(publishedResponse || {
                  offset: 0,
                  limit: PAGE_SIZE,
                  count: 0,
                })}
                onPageClicked={(page) => {
                  setPublishedPage(page - 1);
                }}
              />
            </>
          )}
        </TabsContent>
        <TabsContent value="unpublished">
          <ReportFilters
            type="Intel"
            onChange={handleChangeFilterValues}
            onClear={handleClear}
            filterValues={filterValues}
            teams={teams || []}
            loading={teamsLoading}
            playerList={playerList || []}
            playerListLoading={playerListLoading}
          />
          {unpublishedLoading ? (
            <IntelListingsLoading />
          ) : (
            <>
              {unpublishedResponse && unpublishedResponse?.intel?.length > 0 ? (
                <Flex direction="down" className="divide-y-2">
                  {unpublishedResponse?.intel.map((intel) => (
                    <IntelListItem
                      key={intel.id}
                      className="py-4"
                      intel={intel}
                    />
                  ))}
                </Flex>
              ) : (
                <Text className="py-10 text-center" size="lg" muted>
                  No intel found.
                </Text>
              )}
              <PaginationControl
                {...(unpublishedResponse || {
                  offset: 0,
                  limit: PAGE_SIZE,
                  count: 0,
                })}
                onPageClicked={(page) => {
                  setUnpublishedPage(page - 1);
                }}
              />
            </>
          )}
        </TabsContent>
      </Tabs>
    </Card>
  );
}

function IntelListingsLoading() {
  return (
    <Flex className="py-4" direction="down" gap="lg">
      <SkeletonText />
      <SkeletonText />
    </Flex>
  );
}
