import { useAsync } from "@/shared/hooks";
import { Link, useParams } from "react-router-dom";
import { usePlayer } from "../hooks/usePlayers";
import { PlayerHeader, PlayerHeaderSkeleton } from "@/components";
import { URL } from "@/shared/utils/route-constant";

import {
  Button,
  Card,
  Flex,
  Text,
  TextEditor,
  Badge,
  Container,
  Skeleton,
  SkeletonText,
} from "@suns/design-system";
import { PencilIcon, PrinterIcon } from "@suns/design-system/icons";
import Page from "@/components/Page/Page";
import dayjs from "dayjs";
import { intelByIdLoader } from "../intel-edit/loaders/intel-edit-loader";
import { useTeamById } from "../hooks/useTeams";
import { TeamHeader } from "../components/TeamHeader.tsx/TeamHeader";

export function IntelView() {
  const { playerId, teamId, intelId } = useParams();
  const {
    player,
    loading: playerLoading,
    error: playerError,
  } = usePlayer(Number(playerId));

  const {
    response: team,
    loading: teamLoading,
    error: teamError,
  } = useTeamById(teamId ? Number(teamId) : undefined);

  const {
    response: intel,
    loading: intelLoading,
    error: intelError,
  } = useAsync(intelByIdLoader, {
    intelId: intelId ? Number(intelId) : undefined,
  });

  return (
    <Page
      title={
        intel && playerId
          ? `${intel?.players?.[0]?.firstName} ${intel?.players?.[0]?.lastName}`
          : intel && teamId
            ? `${team?.fullName} Intel`
            : "View Intel"
      }
      loading={playerLoading || intelLoading || teamLoading}
      loadingState={<IntelViewLoading />}
      render={() => {
        if (playerError) {
          throw Error("There was an error requesting a player.", {
            cause: playerError,
          });
        }

        if (intelError) {
          throw Error("There was an error requesting intel.", {
            cause: intelError,
          });
        }

        if (teamError) {
          throw Error("There was an error requesting a team.", {
            cause: teamError,
          });
        }

        return (
          <Container size="xl">
            <Card>
              <Flex direction="down" align="center" className="w-full">
                <Flex direction="down" className="max-w-4xl" gap="md">
                  <Flex direction="down" gap="sm" className="mb-4">
                    <Text heading size="3xl">
                      Intel
                    </Text>
                    <Flex direction="right" gap="xs">
                      <Text heading>{intel?.authorName}</Text>
                      <Text muted>on</Text>
                      <Text heading>
                        {dayjs(intel?.createdAt).format("MMMM Do YYYY")}
                      </Text>
                    </Flex>
                  </Flex>

                  {playerId ? (
                    <PlayerHeader
                      player={player!}
                      teamNbaId={player?.currentTeam?.nbaId ?? undefined}
                      teamName={player?.currentTeam?.fullName ?? undefined}
                      teamImage={player?.currentTeam?.image ?? undefined}
                      leagueId={player?.currentTeam?.domesticLeagueId}
                    />
                  ) : (
                    <TeamHeader
                      teamNbaId={team?.nbaId ?? undefined}
                      teamName={team?.fullName ?? undefined}
                      image={team?.image ?? undefined}
                      leagueId={team?.domesticLeagueId}
                      teamDivision={
                        team?.division || team?.conference || team?.countryCode
                      }
                    />
                  )}

                  <Flex gap={"lg"} align="start">
                    <Flex direction="down" gap="sm">
                      <Text size="lg" muted heading>
                        Source
                      </Text>
                      <Text size="lg" heading>
                        {intel?.source}
                      </Text>
                    </Flex>
                    <Flex direction="down" gap="sm">
                      <Text size="lg" muted heading>
                        Trustworthiness
                      </Text>
                      <Text size="lg" heading>
                        {intel?.trustworthiness}
                      </Text>
                    </Flex>
                  </Flex>
                  {intel?.tags?.length ? (
                    <>
                      <Flex gap="sm" wrap>
                        {intel?.tags?.map((tag) => {
                          return (
                            <Badge
                              variant="primary"
                              className="rounded-md px-2 font-normal"
                              key={tag}
                            >
                              {tag}
                            </Badge>
                          );
                        })}
                      </Flex>
                    </>
                  ) : null}
                  <TextEditor
                    defaultValue={intel?.notes ?? undefined}
                    readonly
                  />
                  <Flex direction="right" gap="sm" className="print:hidden">
                    <Link
                      to={`/reports/${URL.INTEL.path}/${URL.INTEL.children.INTEL_EDIT.path}/player/${playerId}/${intelId}`}
                    >
                      <Button type="button" variant="outline">
                        <Flex direction="right" align="center" gap="sm">
                          <PencilIcon size={16} />
                          Edit
                        </Flex>
                      </Button>
                    </Link>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        window.print();
                      }}
                    >
                      <PrinterIcon size={16} />
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          </Container>
        );
      }}
    />
  );
}

function IntelViewLoading() {
  return (
    <Container size="xl">
      <Card>
        <Flex direction="down" align="center" className="w-full">
          <Flex direction="down" className="w-3/4" gap="md">
            <Flex direction="down" gap="sm" className="mb-4">
              <Flex direction="down" gap="md">
                <Skeleton className="h-8 w-24" />
                <Skeleton className="h-4 w-72" />
              </Flex>
            </Flex>
            <PlayerHeaderSkeleton />
            <Flex gap="lg" align="center" className="mt-4">
              <Flex direction="down" gap="md">
                <Skeleton className="h-4 w-36" />
                <Skeleton className="h-4 w-24" />
              </Flex>
              <Flex direction="down" gap="md">
                <Skeleton className="h-4 w-36" />
                <Skeleton className="h-4 w-24" />
              </Flex>
            </Flex>
            <Skeleton className="my-2 h-5 w-24" />
            <Flex direction="down" gap="sm">
              <SkeletonText rows={1} className="h-4 w-full" />
              <SkeletonText rows={1} className="h-4 w-full" />
              <SkeletonText rows={1} className="h-4 w-64" />
            </Flex>
            <Skeleton className="h-10 w-20" />
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
}
