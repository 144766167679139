import { useForm } from "react-hook-form";
import {
  Button,
  Flex,
  Text,
  Form,
  FormField,
  FormLabel,
} from "@suns/design-system";
import { useRef, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  ReportApolloGrade,
  ReportPlayerAttribute,
  ReportResponseItem,
  ReportScore,
  ReportUpsertParams,
} from "@suns/api/generated-client/apollo";
import {
  PlayerAttributeLabels,
  ReportScoutingLocationLabels,
} from "../../reports-const";
import { getErrorMessage } from "../../report-utils";
import { FormCheckboxGroup } from "./FormCheckboxGroup";
import { LoaderCircle } from "lucide-react";
import * as yup from "yup";
import { ReportGame } from "../../reports-create-game/loaders/create-game-report-loader";
import PhaseReportGameSelection from "./FormSections/PhaseReportGameSelection";
import PositionAndRole from "./FormSections/PositionAndRole";
import ApolloGrades from "./FormSections/ApolloGrades";
import TeamFit from "./FormSections/TeamFit";
import OffenseScores from "./FormSections/OffenseScores";
import DefenseScores from "./FormSections/DefenseScores";
import OtherScores from "./FormSections/OtherScores";
import { toast, ToastType } from "@/shared/utils/toaster";

const REPORT_DEBOUNCE_TIME = 2000;
const REPORT_MAX_WAIT_TIME = 5000;

const yupFormSchema = yup.object({
  id: yup.number().notRequired(),
  playerId: yup.number().required(),
  teamId: yup.number().when("type", {
    is: ReportResponseItem.type.GAME,
    then: (gameVendor) => gameVendor.required(),
    otherwise: (gameVendor) => gameVendor.notRequired(),
  }),
  type: yup.string().oneOf(Object.values(ReportResponseItem.type)).required(),
  level: yup.string().oneOf(Object.values(ReportResponseItem.level)).required(),
  status: yup
    .string()
    .oneOf(Object.values(ReportResponseItem.status))
    .required(),
  statusUpdatedAt: yup.string(),
  createdAt: yup.string(),
  updatedAt: yup.string(),
  authorUsername: yup.string().required(),
  authorName: yup.string().required(),
  opponentTeamId: yup.number().optional(),
  teamFit: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.teamFit))
    .when("level", {
      is: ReportResponseItem.level.PRO,
      then: (teamFit) => teamFit.requiredOnPublish(),
      otherwise: (teamFit) => teamFit.notRequired(),
    }),
  teamFitNotes: yup.string().notRequired(),
  scoutLocation: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.scoutLocation))
    .when("type", {
      is: ReportResponseItem.type.PHASE,
      then: (scoutLocation) => scoutLocation.notRequired(),
      otherwise: (scoutLocation) => scoutLocation.requiredOnPublish(),
    }),
  position: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.position))
    .requiredOnPublish(),
  role: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.role))
    .requiredOnPublish(),
  offensiveNotes: yup.string().notRequired(),
  defensiveNotes: yup.string().notRequired(),
  otherNotes: yup.string().notRequired(),
  playerAttributes: yup
    .array()
    .of(yup.string().oneOf(Object.keys(ReportPlayerAttribute.key)))
    .notRequired(),
  gameVendor: yup
    .string()
    .oneOf(Object.values(ReportUpsertParams.gameVendor))
    .when("type", {
      is: ReportResponseItem.type.GAME,
      then: (gameVendor) => gameVendor.required(),
      otherwise: (gameVendor) => gameVendor.notRequired(),
    }),
  gameVendorId: yup.string().when("type", {
    is: ReportResponseItem.type.GAME,
    then: (gameVendorId) => gameVendorId.required(),
    otherwise: (gameVendorId) => gameVendorId.notRequired(),
  }),
  reportIds: yup.array(yup.string().required()).when("type", {
    is: ReportResponseItem.type.PHASE,
    then: (reportIds) => reportIds.min(1).requiredOnPublish(),
    otherwise: (reportIds) => reportIds.notRequired(),
  }),
  scores: yup.object(
    Object.fromEntries(
      Object.values(ReportScore.key).map((key) => [
        key,
        yup.string().nullable().requiredOnPublish(),
      ])
    )
  ),
  apolloGrades: yup
    .object()
    .when(["type", "level"], {
      is: (type: string, level: string) =>
        (type === ReportResponseItem.type.GAME ||
          type === ReportResponseItem.type.GENERAL) &&
        level === ReportResponseItem.level.PRO,
      then: (apolloGrades) =>
        apolloGrades.concat(
          yup.object({
            [ReportApolloGrade.scope.CURRENT]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
          })
        ),
    })
    .when(["type", "level"], {
      is: (type: string, level: string) =>
        type === ReportResponseItem.type.PHASE &&
        level === ReportResponseItem.level.PRO,
      then: (apolloGrades) =>
        apolloGrades.concat(
          yup.object({
            [ReportApolloGrade.scope.CURRENT]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
            [ReportApolloGrade.scope.REMAINING_CAPACITY]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
          })
        ),
    })
    .when(["type", "level"], {
      is: (_type: string, level: string) =>
        level === ReportResponseItem.level.AMATEUR,
      then: (apolloGrades) =>
        apolloGrades.concat(
          yup.object({
            [ReportApolloGrade.scope.CEILING]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
            [ReportApolloGrade.scope.BULLSEYE]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
            [ReportApolloGrade.scope.BASEMENT]: yup
              .string()
              .oneOf(Object.values(ReportApolloGrade.value))
              .requiredOnPublish(),
          })
        ),
    }),
});

export type ReportFormSchema = yup.InferType<typeof yupFormSchema>;
interface ReportFormProps {
  onSubmit?: (
    data: ReportFormSchema,
    publish?: boolean,
    reportId?: number
  ) => Promise<number | undefined>;
  isSaving: boolean;
  isPublishing?: boolean;
  availableReports?: ReportResponseItem[];
  gameData?: Record<string, ReportGame>;
  report: ReportFormSchema;
  readonly?: boolean;
}

interface QueuedSubmit {
  publish: boolean;
}

export function ReportForm({
  onSubmit,
  isSaving,
  isPublishing,
  report,
  availableReports,
  gameData,
  readonly = false,
}: ReportFormProps) {
  const form = useForm<ReportFormSchema>({
    defaultValues: yupFormSchema.cast(report),
    mode: "onChange",
  });

  const { watch, setValue } = form;
  const queuedSubmit = useRef<QueuedSubmit | null>(null);

  async function handlePublish() {
    const values = form.getValues();
    if (values.status === ReportUpsertParams.status.PUBLISHED) {
      setValue("status", ReportUpsertParams.status.UNPUBLISHED);
      save({ publish: true });
      save.flush();
    } else {
      try {
        await yupFormSchema.validate(values, {
          abortEarly: false,
          context: { publish: true },
        });

        setValue("status", ReportUpsertParams.status.PUBLISHED);
        save({ publish: true });
        save.flush();
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = error.inner.map((e) => {
            if (e.path) {
              form.setError(e.path as keyof ReportFormSchema, {
                type: "custom",
                message: e.message,
              });
            }

            return getErrorMessage(e.path?.split("."));
          });

          toast(
            ToastType.ERROR,
            `Unable to publish report. Missing ${errors.join(", ")}.`
          );
        }
      }
    }
  }

  // debounce changes by REPORT_DEBOUNCE_TIME with a max wait of REPORT_MAX_WAIT_TIME
  // this will wait REPORT_DEBOUNCE_TIME after any changes to the form before calling the onSubmit function
  // if another change occurs before REPORT_DEBOUNCE_TIME has passed, the timer will reset
  // if REPORT_MAX_WAIT_TIME has passed since the last change, the onSubmit function will be called
  const save = useDebouncedCallback(
    async (options) => {
      if (!onSubmit) return;
      const { publish = false } = options;

      if (isPublishing || isSaving) {
        queuedSubmit.current = { publish };
        return;
      }

      const reportId = await onSubmit(form.getValues(), publish);
      if (!queuedSubmit.current) return;
      onSubmit(form.getValues(), queuedSubmit.current.publish, reportId);
      queuedSubmit.current = null;
    },
    REPORT_DEBOUNCE_TIME,
    { maxWait: REPORT_MAX_WAIT_TIME }
  );

  // watch for changes and call debounced callback on changes
  useEffect(() => {
    // trigger a save anytime a field value changes
    const sub = watch(() => {
      save({ publish: false });
    });

    // flush any pending saves on page unload
    window.onbeforeunload = () => {
      save.flush();
    };

    // remove event listeners and flush pending saves on unmount
    return () => {
      window.onbeforeunload = null;
      sub.unsubscribe();
      save.flush();
    };
  }, [watch, save]);

  return (
    <Form {...form} key={report.playerId}>
      <form>
        <Flex direction="down" gap="lg">
          {/* Phase Report Game Selection */}
          {report.type === ReportResponseItem.type.PHASE ? (
            <PhaseReportGameSelection
              reports={availableReports ?? []}
              games={gameData ?? {}}
            />
          ) : null}

          {/* Team Fit */}
          {report.level === "PRO" ? <TeamFit readonly={readonly} /> : null}

          {/* Position/Role */}
          <PositionAndRole readonly={readonly} />

          {/* Apollo Grades */}
          <ApolloGrades readonly={readonly} />

          {/* Player 15 Eval */}
          <Flex direction="down" gap="sm">
            <Text size="lg" heading asChild>
              <FormLabel>Player 15 Eval</FormLabel>
            </Text>
            <FormCheckboxGroup
              name="playerAttributes"
              options={Object.values(ReportPlayerAttribute.key).map(
                (value) => ({
                  value,
                  label: PlayerAttributeLabels[value],
                })
              )}
              multiselect={true}
            />
          </Flex>

          {/* Scores */}
          <OffenseScores readonly={readonly} />
          <DefenseScores readonly={readonly} />
          <OtherScores readonly={readonly} />

          {/* Scout Location */}
          {report.type !== ReportResponseItem.type.PHASE ? (
            <Flex direction="down" gap="sm">
              <Text size="lg" heading asChild>
                <FormLabel>Scouting location</FormLabel>
              </Text>
              <FormCheckboxGroup
                name="scoutLocation"
                options={Object.values(ReportUpsertParams.scoutLocation).map(
                  (value) => ({
                    value,
                    label: ReportScoutingLocationLabels[value],
                  })
                )}
                multiselect={false}
              />
            </Flex>
          ) : null}
        </Flex>

        {/* Save and Publish Buttons */}
        {!readonly ? (
          <Flex direction="right" gap="md" className="mt-6">
            <FormField
              name="status"
              render={({ field }) => {
                return (
                  <Button
                    type="button"
                    variant="muted"
                    disabled={isSaving || isPublishing}
                    onClick={handlePublish}
                  >
                    {isPublishing ? (
                      <LoaderCircle size={18} className="animate-spin" />
                    ) : field?.value === ReportUpsertParams.status.PUBLISHED ? (
                      "Unpublish"
                    ) : (
                      "Publish"
                    )}
                  </Button>
                );
              }}
            />
            <Button
              type="button"
              disabled={isSaving || isPublishing}
              onClick={() => {
                save({ publish: false });
                save.flush();
              }}
            >
              {isSaving ? (
                <Flex direction="right" gap="sm" align="center">
                  Saving...
                  <LoaderCircle size={18} className="animate-spin" />
                </Flex>
              ) : (
                <Flex>Save</Flex>
              )}
            </Button>
          </Flex>
        ) : null}
      </form>
    </Form>
  );
}
