export const fieldsWithReverseOrder = [
  "TOV %",
  "Opp. eFG%",
  "Opp. FTA Rate",
  "3PA",
  "3P%",
  "FT%",
  "PITP ALLOWED",
  "Opp. PLAYER ADJUSTED SHOT QUALITY",
  "FASTBREAK PTS ALLOWED",
  "FOULS COMMITTED",
  "P&R EFFICIENCY",
  "ISO EFFICIENCY",
  "DHO EFFICIENCY",
  "POST UP EFFICIENCY",
  "OFFBALL SCREEN EFFICIENCY",
  "DRIVE EFFICIENCY",
  "DRtg",
  "Opp. FG%",
  "Opp. Rim Rate",
];
