import * as yup from "yup";

declare module "yup" {
  interface Schema {
    requiredOnPublish(): Schema;
  }
}

yup.addMethod(yup.Schema, "requiredOnPublish", function requiredOnPublish() {
  return this.when("$publish", {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  });
});
