import { useEffect, useState } from "react";
import { FiltersService, WinsLosses } from "../../swagger";
import Skeleton from "react-loading-skeleton";
import { SEASON_TYPES_LIST } from "../../types/enums/teamDna.enums";
import { FiltersSelectedType } from "../../pages/box-scores/box-scores-types";

const WinLoss = ({
  filtersSelectedData,
  showCalculationType = false,
}: {
  filtersSelectedData: FiltersSelectedType;
  showCalculationType?: boolean;
}) => {
  const [teamWinsLossesCount, setTeamWinsLossesCount] = useState<WinsLosses>();
  const [teamWinsLossesLoader, setTeamWinsLossesLoader] =
    useState<boolean>(true);

  useEffect(() => {
    if (
      filtersSelectedData &&
      filtersSelectedData.selectedSeasonTypes?.length
    ) {
      getWinsLosses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSelectedData]);

  const getWinsLosses = () => {
    setTeamWinsLossesLoader(true);
    FiltersService.getWinsLosses(
      filtersSelectedData?.selectedSeasons?.value
        ? [filtersSelectedData?.selectedSeasons?.value]
        : [],
      filtersSelectedData?.selectedSeasonTypes,
      filtersSelectedData?.selectedTeam?.value || "",
      filtersSelectedData?.selectedNoOfGames?.value
    )
      .then(async (response) => {
        setTeamWinsLossesCount(response.data);
        setTeamWinsLossesLoader(false);
      })
      .catch(() => {
        setTeamWinsLossesLoader(false);
      });
  };

  return (
    <div className="card-head-md">
      <figure>
        <img
          src={`https://cdn.nba.com/logos/nba/${filtersSelectedData?.selectedTeam?.id}/primary/L/logo.svg`}
        />
      </figure>
      <div className="card-head-caption">
        <h4>
          {filtersSelectedData?.selectedTeam?.label}{" "}
          {showCalculationType
            ? `- ${filtersSelectedData?.selectedCalculationType?.label}`
            : ""}
        </h4>
        <div className="card-head-description">
          {teamWinsLossesLoader ? (
            <span>
              <Skeleton width={"10rem"} count={1} />
            </span>
          ) : (
            <span>
              {teamWinsLossesCount?.WINS}-
              {teamWinsLossesCount?.LOSSES
                ? Math.abs(teamWinsLossesCount?.LOSSES)
                : "0"}{" "}
              (
              {filtersSelectedData?.selectedNoOfGames?.value
                ? filtersSelectedData?.selectedNoOfGames.label
                : filtersSelectedData?.selectedSeasons?.label}
              )
            </span>
          )}
          {filtersSelectedData?.selectedSeasonTypes.map(
            (selectedType, index) => {
              const foundItem = SEASON_TYPES_LIST.find(
                (item) => item.value === selectedType
              );
              const label = foundItem ? foundItem.label : "";
              return (
                <div key={index} className="badge purple">
                  {label}
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default WinLoss;
