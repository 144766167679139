import { ApolloLeagueId, LeagueId } from "@suns/api";

export function getApolloLeagueIdFromNbaGameId(gameId: string) {
  const leagueId = gameId.substring(0, 2) as LeagueId;
  if (leagueId == LeagueId.NBA) return ApolloLeagueId.NBA;

  throw Error(`League ID ${leagueId} isn't valid (pulled from ${gameId})`);
}

export function getNbaLeagueIdFromNbaGameId(gameId: string) {
  const leagueId = gameId.substring(0, 2) as LeagueId;
  if (!Object.values(LeagueId).includes(leagueId)) {
    throw Error(`League ID ${leagueId} isn't valid (pulled from ${gameId})`);
  }

  return leagueId;
}
