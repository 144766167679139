import { apolloApi } from "@/shared/api";
import { useAsync } from "@/shared/hooks";

export function usePlayers(teamId?: number) {
  const { loading, response, error } = useAsync(async () => {
    if (!teamId) return null;
    const data = await apolloApi.getPlayers({ currentTeamId: teamId });
    return data.players;
  }, teamId);

  return { players: response, loading, error };
}

export function usePlayer(playerId: number) {
  const { loading, response, error } = useAsync(async () => {
    if (!playerId) return null;
    const data = await apolloApi.getPlayer(playerId);
    return data.player;
  }, playerId);

  return { player: response, loading, error };
}
