/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefenseLeagueAvgResponse } from '../models/DefenseLeagueAvgResponse';
import type { DefenseResponse } from '../models/DefenseResponse';
import type { OffenseLeagueAvgResponse } from '../models/OffenseLeagueAvgResponse';
import type { OffenseResponse } from '../models/OffenseResponse';
import type { WinsLossesResponse } from '../models/WinsLossesResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TeamDnaService {
    /**
     * @param seasonTypes
     * @param teamAbbr
     * @param gameResult
     * @param seasons
     * @param oppTeamAbbr
     * @param garbageTime
     * @param isPer100Possession
     * @param dateRange
     * @param noOfGames
     * @param includedPlayerId
     * @param excludedPlayerId
     * @param startedByPlayerId
     * @param notStartedByPlayerId
     * @returns OffenseResponse Success.
     * @throws ApiError
     */
    public static getOffenseData(
        seasonTypes: Array<string>,
        teamAbbr: string,
        gameResult: Array<string>,
        seasons?: Array<string>,
        oppTeamAbbr?: string,
        garbageTime: boolean = true,
        isPer100Possession: boolean = true,
        dateRange?: Array<string>,
        noOfGames?: number,
        includedPlayerId?: Array<string>,
        excludedPlayerId?: Array<string>,
        startedByPlayerId?: Array<string>,
        notStartedByPlayerId?: Array<string>,
    ): CancelablePromise<OffenseResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team-dna/offense',
            query: {
                'seasons': seasons,
                'seasonTypes': seasonTypes,
                'teamAbbr': teamAbbr,
                'oppTeamAbbr': oppTeamAbbr,
                'garbageTime': garbageTime,
                'gameResult': gameResult,
                'isPer100Possession': isPer100Possession,
                'dateRange': dateRange,
                'noOfGames': noOfGames,
                'includedPlayerId': includedPlayerId,
                'excludedPlayerId': excludedPlayerId,
                'startedByPlayerId': startedByPlayerId,
                'notStartedByPlayerId': notStartedByPlayerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param seasonTypes
     * @param teamAbbr
     * @param gameResult
     * @param seasons
     * @param oppTeamAbbr
     * @param garbageTime
     * @param isPer100Possession
     * @param dateRange
     * @param noOfGames
     * @param includedPlayerId
     * @param excludedPlayerId
     * @param startedByPlayerId
     * @param notStartedByPlayerId
     * @returns OffenseResponse Success.
     * @throws ApiError
     */
    public static getOffensePlayTypesData(
        seasonTypes: Array<string>,
        teamAbbr: string,
        gameResult: Array<string>,
        seasons?: Array<string>,
        oppTeamAbbr?: string,
        garbageTime: boolean = true,
        isPer100Possession: boolean = true,
        dateRange?: Array<string>,
        noOfGames?: number,
        includedPlayerId?: Array<string>,
        excludedPlayerId?: Array<string>,
        startedByPlayerId?: Array<string>,
        notStartedByPlayerId?: Array<string>,
    ): CancelablePromise<OffenseResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team-dna/offense/play-types',
            query: {
                'seasons': seasons,
                'seasonTypes': seasonTypes,
                'teamAbbr': teamAbbr,
                'oppTeamAbbr': oppTeamAbbr,
                'garbageTime': garbageTime,
                'gameResult': gameResult,
                'isPer100Possession': isPer100Possession,
                'dateRange': dateRange,
                'noOfGames': noOfGames,
                'includedPlayerId': includedPlayerId,
                'excludedPlayerId': excludedPlayerId,
                'startedByPlayerId': startedByPlayerId,
                'notStartedByPlayerId': notStartedByPlayerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param seasonTypes
     * @param teamAbbr
     * @param gameResult
     * @param seasons
     * @param oppTeamAbbr
     * @param garbageTime
     * @param isPer100Possession
     * @param dateRange
     * @param noOfGames
     * @param includedPlayerId
     * @param excludedPlayerId
     * @param startedByPlayerId
     * @param notStartedByPlayerId
     * @returns DefenseResponse Success.
     * @throws ApiError
     */
    public static getDefenseData(
        seasonTypes: Array<string>,
        teamAbbr: string,
        gameResult: Array<string>,
        seasons?: Array<string>,
        oppTeamAbbr?: string,
        garbageTime: boolean = true,
        isPer100Possession: boolean = true,
        dateRange?: Array<string>,
        noOfGames?: number,
        includedPlayerId?: Array<string>,
        excludedPlayerId?: Array<string>,
        startedByPlayerId?: Array<string>,
        notStartedByPlayerId?: Array<string>,
    ): CancelablePromise<DefenseResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team-dna/defense',
            query: {
                'seasons': seasons,
                'seasonTypes': seasonTypes,
                'teamAbbr': teamAbbr,
                'oppTeamAbbr': oppTeamAbbr,
                'garbageTime': garbageTime,
                'gameResult': gameResult,
                'isPer100Possession': isPer100Possession,
                'dateRange': dateRange,
                'noOfGames': noOfGames,
                'includedPlayerId': includedPlayerId,
                'excludedPlayerId': excludedPlayerId,
                'startedByPlayerId': startedByPlayerId,
                'notStartedByPlayerId': notStartedByPlayerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param seasonTypes
     * @param teamAbbr
     * @param gameResult
     * @param seasons
     * @param oppTeamAbbr
     * @param garbageTime
     * @param isPer100Possession
     * @param dateRange
     * @param noOfGames
     * @param includedPlayerId
     * @param excludedPlayerId
     * @param startedByPlayerId
     * @param notStartedByPlayerId
     * @returns OffenseResponse Success.
     * @throws ApiError
     */
    public static getDefensePlayTypesData(
        seasonTypes: Array<string>,
        teamAbbr: string,
        gameResult: Array<string>,
        seasons?: Array<string>,
        oppTeamAbbr?: string,
        garbageTime: boolean = true,
        isPer100Possession: boolean = true,
        dateRange?: Array<string>,
        noOfGames?: number,
        includedPlayerId?: Array<string>,
        excludedPlayerId?: Array<string>,
        startedByPlayerId?: Array<string>,
        notStartedByPlayerId?: Array<string>,
    ): CancelablePromise<OffenseResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team-dna/defense/play-types',
            query: {
                'seasons': seasons,
                'seasonTypes': seasonTypes,
                'teamAbbr': teamAbbr,
                'oppTeamAbbr': oppTeamAbbr,
                'garbageTime': garbageTime,
                'gameResult': gameResult,
                'isPer100Possession': isPer100Possession,
                'dateRange': dateRange,
                'noOfGames': noOfGames,
                'includedPlayerId': includedPlayerId,
                'excludedPlayerId': excludedPlayerId,
                'startedByPlayerId': startedByPlayerId,
                'notStartedByPlayerId': notStartedByPlayerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param seasonTypes
     * @param gameResult
     * @param teamAbbr
     * @param seasons
     * @param garbageTime
     * @param dateRange
     * @param noOfGames
     * @param isPer100Possession
     * @param oppTeamAbbr
     * @returns OffenseLeagueAvgResponse Success.
     * @throws ApiError
     */
    public static getOffenseLeagueAvgData(
        seasonTypes: Array<string>,
        gameResult: Array<string>,
        teamAbbr: string,
        seasons?: Array<string>,
        garbageTime: boolean = true,
        dateRange?: Array<string>,
        noOfGames?: number,
        isPer100Possession: boolean = false,
        oppTeamAbbr?: string,
    ): CancelablePromise<OffenseLeagueAvgResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team-dna/offense-league-avg',
            query: {
                'seasonTypes': seasonTypes,
                'gameResult': gameResult,
                'teamAbbr': teamAbbr,
                'seasons': seasons,
                'garbageTime': garbageTime,
                'dateRange': dateRange,
                'noOfGames': noOfGames,
                'isPer100Possession': isPer100Possession,
                'oppTeamAbbr': oppTeamAbbr,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param seasonTypes
     * @param gameResult
     * @param teamAbbr
     * @param seasons
     * @param garbageTime
     * @param dateRange
     * @param noOfGames
     * @param isPer100Possession
     * @param oppTeamAbbr
     * @returns OffenseLeagueAvgResponse Success.
     * @throws ApiError
     */
    public static getOffensePlayTypesLeagueAvgData(
        seasonTypes: Array<string>,
        gameResult: Array<string>,
        teamAbbr: string,
        seasons?: Array<string>,
        garbageTime: boolean = true,
        dateRange?: Array<string>,
        noOfGames?: number,
        isPer100Possession: boolean = false,
        oppTeamAbbr?: string,
    ): CancelablePromise<OffenseLeagueAvgResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team-dna/offense-league-avg/play-types',
            query: {
                'seasonTypes': seasonTypes,
                'gameResult': gameResult,
                'teamAbbr': teamAbbr,
                'seasons': seasons,
                'garbageTime': garbageTime,
                'dateRange': dateRange,
                'noOfGames': noOfGames,
                'isPer100Possession': isPer100Possession,
                'oppTeamAbbr': oppTeamAbbr,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param seasonTypes
     * @param gameResult
     * @param teamAbbr
     * @param seasons
     * @param garbageTime
     * @param dateRange
     * @param noOfGames
     * @param isPer100Possession
     * @param oppTeamAbbr
     * @returns DefenseLeagueAvgResponse Success.
     * @throws ApiError
     */
    public static getDefenseLeagueAvgData(
        seasonTypes: Array<string>,
        gameResult: Array<string>,
        teamAbbr: string,
        seasons?: Array<string>,
        garbageTime: boolean = true,
        dateRange?: Array<string>,
        noOfGames?: number,
        isPer100Possession: boolean = false,
        oppTeamAbbr?: string,
    ): CancelablePromise<DefenseLeagueAvgResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team-dna/defense-league-avg',
            query: {
                'seasonTypes': seasonTypes,
                'gameResult': gameResult,
                'teamAbbr': teamAbbr,
                'seasons': seasons,
                'garbageTime': garbageTime,
                'dateRange': dateRange,
                'noOfGames': noOfGames,
                'isPer100Possession': isPer100Possession,
                'oppTeamAbbr': oppTeamAbbr,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param seasonTypes
     * @param gameResult
     * @param teamAbbr
     * @param seasons
     * @param garbageTime
     * @param dateRange
     * @param noOfGames
     * @param isPer100Possession
     * @param oppTeamAbbr
     * @returns OffenseLeagueAvgResponse Success.
     * @throws ApiError
     */
    public static getDefensePlayTypesLeagueAvgData(
        seasonTypes: Array<string>,
        gameResult: Array<string>,
        teamAbbr: string,
        seasons?: Array<string>,
        garbageTime: boolean = true,
        dateRange?: Array<string>,
        noOfGames?: number,
        isPer100Possession: boolean = false,
        oppTeamAbbr?: string,
    ): CancelablePromise<OffenseLeagueAvgResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team-dna/defense-league-avg/play-types',
            query: {
                'seasonTypes': seasonTypes,
                'gameResult': gameResult,
                'teamAbbr': teamAbbr,
                'seasons': seasons,
                'garbageTime': garbageTime,
                'dateRange': dateRange,
                'noOfGames': noOfGames,
                'isPer100Possession': isPer100Possession,
                'oppTeamAbbr': oppTeamAbbr,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param seasonTypes
     * @param teamAbbr
     * @param gameResult
     * @param seasons
     * @param oppTeamAbbr
     * @param garbageTime
     * @param isPer100Possession
     * @param dateRange
     * @param noOfGames
     * @param includedPlayerId
     * @param excludedPlayerId
     * @param startedByPlayerId
     * @param notStartedByPlayerId
     * @returns WinsLossesResponse Success.
     * @throws ApiError
     */
    public static getWinsLosses(
        seasonTypes: Array<string>,
        teamAbbr: string,
        gameResult: Array<string>,
        seasons?: Array<string>,
        oppTeamAbbr?: string,
        garbageTime: boolean = true,
        isPer100Possession: boolean = true,
        dateRange?: Array<string>,
        noOfGames?: number,
        includedPlayerId?: Array<string>,
        excludedPlayerId?: Array<string>,
        startedByPlayerId?: Array<string>,
        notStartedByPlayerId?: Array<string>,
    ): CancelablePromise<WinsLossesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/team-dna/wins-losses',
            query: {
                'seasons': seasons,
                'seasonTypes': seasonTypes,
                'teamAbbr': teamAbbr,
                'oppTeamAbbr': oppTeamAbbr,
                'garbageTime': garbageTime,
                'gameResult': gameResult,
                'isPer100Possession': isPer100Possession,
                'dateRange': dateRange,
                'noOfGames': noOfGames,
                'includedPlayerId': includedPlayerId,
                'excludedPlayerId': excludedPlayerId,
                'startedByPlayerId': startedByPlayerId,
                'notStartedByPlayerId': notStartedByPlayerId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
}
