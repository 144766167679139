import { useEffect, useState } from "react";

type AsyncState<T> =
  | { loading: true; response: T | null; error: null }
  | { loading: false; response: T; error: null }
  | { loading: false; response: T | null; error: Error };

type AsyncReturn<T> = AsyncState<T> & {
  loading: boolean;
  refresh: () => void;
};

export function useAsync<T>(fn: () => Promise<T>): AsyncReturn<T>;
export function useAsync<T, P>(
  fn: (args: P) => Promise<T>,
  params: P
): AsyncReturn<T>;
export function useAsync<T, P>(
  fn: (args?: P) => Promise<T>,
  params?: P
): AsyncReturn<T> {
  const [state, setState] = useState<AsyncState<T>>({
    loading: true,
    response: null,
    error: null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => run(), [JSON.stringify(params)]);

  function run() {
    setState({
      loading: true,
      response: state.response,
      error: null,
    });
    fn(params)
      .then((response) => {
        setState({
          loading: false,
          response,
          error: null,
        });
      })
      .catch((error) => {
        setState({
          loading: false,
          response: state.response,
          error,
        });
      });
  }

  function refresh() {
    if (state.loading) {
      return;
    }
    run();
  }

  return {
    ...state,
    refresh,
  };
}
