import axios from "axios";
import { ApiResponse } from "./types/responses";

export type ApiKeys = Record<
  "stats" | "querytool" | "scores" | "schedule",
  string
>;

export class NbaApi {
  url: string;
  apiKeys: ApiKeys;

  constructor({ apiKeys, url }: { apiKeys: ApiKeys; url?: string }) {
    this.url = url || "https://api.nba.com/v0/api";
    this.apiKeys = apiKeys;

    this.get.bind(this);
    this.apiKey.bind(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get<Response = any, Params = any>({
    url,
    params,
  }: {
    url: `/${keyof ApiKeys}${string}`;
    params: Params;
  }): Promise<ApiResponse<Response>> {
    try {
      const response = await axios<Response>({
        method: "get",
        url: `${this.url}${url}`,
        headers: {
          "X-NBA-Api-Key": this.apiKey(url),
        },
        params,
      });
      return {
        status: 200,
        data: response.data,
        error: null,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return {
          status: error.response?.status || 400,
          data: null,
          error,
        };
      } else {
        throw error;
      }
    }
  }

  private apiKey(url: string) {
    const root = url.split("/")[1] as keyof ApiKeys;

    return this.apiKeys[root];
  }
}
