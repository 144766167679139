import { reportLoader } from "../loaders/report-loader";
import { GameHero } from "@/components/GameHero/GameHero";
import {
  reportFormDataToUpsertParams,
  reportResponseToFormData,
} from "../report-utils";
import { apolloApi } from "@/shared/api";
import {
  ReportForm,
  ReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { useState } from "react";
import { SunsApiError } from "@suns/api";
import { useParams } from "react-router-dom";
import { useAccount, useAsync } from "@/shared/hooks";
import {
  NavigateLogin,
  PlayerHeader,
  PlayerHeaderSkeleton,
} from "@/components";
import {
  Card,
  Container,
  Flex,
  SkeletonHeading,
  SkeletonText,
} from "@suns/design-system";
import { toast, ToastType } from "@/shared/utils/toaster";
import Page from "@/components/Page/Page";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";

export function ReportEdit() {
  const [navigateLogin, setNavigateLogin] = useState(false);
  const { reportId } = useParams();
  const account = useAccount();
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const { response, loading, error } = useAsync(reportLoader, {
    reportId: reportId || "",
  });

  async function handleSubmit(
    report: ReportFormSchema,
    publish: boolean = false
  ) {
    if (publish) {
      setIsPublishing(true);
    } else {
      setIsSaving(true);
    }

    try {
      const upsertParams = reportFormDataToUpsertParams(report);

      const res = await apolloApi.saveReport(upsertParams);

      if (publish) {
        toast(ToastType.SUCCESS, "Report Published");
      } else {
        toast(ToastType.INFO, "Report Saved");
      }

      return res.report.id;
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toast(ToastType.ERROR, "Please log back in to save.");
        setNavigateLogin(true);
      }
      toast(ToastType.ERROR, "Unable to save report. Please try again.");
    } finally {
      setIsSaving(false);
      setIsPublishing(false);
    }
  }

  if (navigateLogin) {
    return <NavigateLogin />;
  }

  if (error) {
    throw new SunsApiError("Error loading the report.", { cause: error });
  }

  if (loading) {
    return <ReportEditLoading />;
  }

  if (!account.info) {
    throw new Error("User Authorization Error", { cause: account });
  }

  const { report, game, availableReports, games } = response;

  if (!report) {
    throw new Error("Error loading report data");
  }

  const parsedReport = reportResponseToFormData(
    report.player,
    account.info,
    report.type,
    [],
    report
  );

  const team = report.player.currentTeam;

  return (
    <Page title="Edit Report">
      {report.type === ReportResponseItem.type.GAME && game && (
        <Card className="mb-4 overflow-hidden">
          <GameHero
            leagueId={game.leagueId}
            gameStatus={game.gameStatus || 1}
            gameTimeUTC={game.gameTimeUTC}
            gameClock={game.gameClock}
            period={game.period}
            homeTeam={{
              id: game.homeTeam.id,
              image: game.homeTeam.image,
              tricode: game.homeTeam.tricode,
              score: game.homeTeam.score,
            }}
            awayTeam={{
              id: game.awayTeam.id,
              image: game.awayTeam.image,
              tricode: game.awayTeam.tricode,
              score: game.awayTeam.score,
            }}
          />
        </Card>
      )}
      <Card>
        <Flex direction="down" align="center" className="w-full">
          <Flex direction="down" className="max-w-4xl" gap="lg">
            <PlayerHeader
              player={report.player}
              teamNbaId={team?.nbaId ?? undefined}
              teamName={team?.name}
              teamImage={team?.image ?? undefined}
              leagueId={team?.domesticLeagueId}
            />
            <ReportForm
              report={parsedReport}
              onSubmit={handleSubmit}
              isSaving={isSaving}
              isPublishing={isPublishing}
              availableReports={availableReports}
              gameData={games}
            />
          </Flex>
        </Flex>
      </Card>
    </Page>
  );
}

function ReportEditLoading() {
  return (
    <Container size="xl">
      <Card>
        <Flex justify="center">
          <Flex className="w-full max-w-4xl" direction="down" gap="lg">
            <Flex direction="down" gap="sm">
              <SkeletonHeading size="3xl" />
              <SkeletonHeading />
            </Flex>
            <PlayerHeaderSkeleton />
            <SkeletonText rows={2} />
            <SkeletonText rows={3} />
          </Flex>
        </Flex>
      </Card>
    </Container>
  );
}
