import { Skeleton } from "@suns/design-system";
import React from "react";

export const TableSkeleton = ({
  columnsCount,
  rowsCount,
  outerRowsCount,
  fromTeamDNA,
  isLoading,
  children,
}: {
  columnsCount: number;
  rowsCount: number;
  outerRowsCount: number;
  fromTeamDNA: boolean;
  isLoading: boolean;
  tableName?: string;
  children: React.ReactNode;
}) => {
  if (isLoading) {
    return (
      <table
        className={!fromTeamDNA ? "table-skelton column-auto" : "table-skelton"}
      >
        <thead>
          <tr>
            {Array.from({ length: columnsCount }, (_, index) => (
              <th key={index}>
                <Skeleton className="w-30 h-6" />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: outerRowsCount }, (_, outerRowIndex) => (
            <React.Fragment key={outerRowIndex}>
              {fromTeamDNA && (
                <tr>
                  <td colSpan={columnsCount}>
                    <Skeleton className="w-30 h-6" />
                  </td>
                </tr>
              )}
              {Array.from({ length: rowsCount }, (_, rowIndex) => (
                <tr key={`row-${outerRowIndex}-${rowIndex}`}>
                  {Array.from({ length: columnsCount }, (_, columnIndex) => (
                    <td
                      key={`cell-${outerRowIndex}-${rowIndex}-${columnIndex}`}
                    >
                      <Skeleton className="w-30 h-6" />
                    </td>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  }
  return children;
};
