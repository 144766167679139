export const labsList = [
  {
    id: 1,
    slug: "ncaa-player-comps",
    subTitle: "NCAA",
    title: "Player Comparisons",
    url: "https://suns-apps.shinyapps.io/NCAAPlayerComps",
    description:
      "This app provides statistical comparisons between NBA draft prospects and NBA players. Select a draft prospect and comparable NBA players are ranked from most similar to least similar based on their college/international season stats or their anthropometric measurements.",
  },
];
