import { apolloApi } from "@/shared/api";
import { ReportResponseItem } from "@/swagger";
import { ReportQueryParams } from "@suns/api/generated-client/apollo";

export async function phaseReportLoader({
  authorUsername,
}: {
  authorUsername: string;
}) {
  // get all published reports that are not part of a phase report
  const response = await apolloApi.getReports({
    authorUsername: [authorUsername],
    status: ReportQueryParams.status.PUBLISHED,
    noAssociatedPhaseReport: true,
  });

  //get existing unpublished phase reports for this user
  const phaseReportResponse = await apolloApi.getReports({
    authorUsername: [authorUsername],
    status: ReportQueryParams.status.UNPUBLISHED,
    type: ReportQueryParams.type.PHASE,
  });

  // combine published game reports and game reports from unpublished phase reports
  const gameReportsAssociatedToPhaseReports = phaseReportResponse.reports
    .map((r) => r.reports)
    .flat();

  // group all reports by player id {playerId: [gameReport1, gameReport2, ...]}
  const players = response.reports
    .concat(gameReportsAssociatedToPhaseReports)
    .reduce<Record<string, ReportResponseItem[]>>((acc, report) => {
      if (!acc[report.playerId]) {
        acc[report.playerId] = [];
      }

      acc[report.playerId] = [...acc[report.playerId], report];
      return acc;
    }, {});

  // Group phase reports by player ID
  const phaseReports = phaseReportResponse.reports.reduce<
    Record<string, ReportResponseItem>
  >((acc, report) => {
    acc[report.playerId] = report;
    return acc;
  }, {});

  return { players, phaseReports };
}
