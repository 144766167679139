import Select, { MultiValue, SingleValue } from "react-select";
import {
  GameResults,
  SeasonTypes,
  SEASON_TYPES_LIST,
} from "../../../types/enums/teamDna.enums";
import { URLSearchParamsInit, useLoaderData } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import { DatePicker } from "antd";
import { FiltersService } from "../../../swagger";
import {
  AllLoader,
  FiltersSelectedType,
  defaultFilter,
} from "../team-dna-types";
import {
  extractValues,
  removeEmptyValues,
} from "../../../shared/utils/helper-functions";

type GameOption = {
  value: number;
  label: string;
  isDisabled: boolean;
};

type PlayersListFilters = {
  gamesWithPlayersList: GameOption[];
  gamesWithoutPlayersList: GameOption[];
  gamesStartedByPlayersList: GameOption[];
  gamesNotStartedByPlayersList: GameOption[];
};

type SearchChildren = {
  setSearchParams: (defaultInit?: URLSearchParamsInit | undefined) => void;
  playersListFilters: PlayersListFilters;
  setPlayersListFilters: Dispatch<SetStateAction<PlayersListFilters>>;
  filtersSelectedData: FiltersSelectedType;
  setFiltersSelectedData: Dispatch<SetStateAction<FiltersSelectedType>>;
  selectedFilters: FiltersSelectedType;
  setSelectedFilters: Dispatch<SetStateAction<FiltersSelectedType>>;
  isFiltersVisible: boolean;
  tableLoader: AllLoader;
};
const TeamDNAFilters = ({
  setSearchParams,
  playersListFilters,
  setPlayersListFilters,
  filtersSelectedData,
  setFiltersSelectedData,
  selectedFilters,
  setSelectedFilters,
  isFiltersVisible = false,
  tableLoader,
}: SearchChildren) => {
  const filtersData = useLoaderData() as unknown as {
    teamsList: {
      label: string;
      options: { label: string; value: string; id: number }[];
    }[];
    seasonsList: { label: string; value: string }[];
  };

  const [isDateRangeOption, setIsDateRangeOption] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");

  const [gamesPlayed, setGamesPlayed] = useState<number>();
  const [isNGamesSelected, setIsNGamesSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [opponentsList, setOpponentsList] = useState(filtersData.teamsList);

  const gameResultsList: GameResults[] = [GameResults.WIN, GameResults.LOSS];

  useEffect(() => {
    const loaders = Object.values(tableLoader);
    setIsLoading(() => {
      return loaders.some((loader) => {
        return Object.values(loader).includes(true);
      });
    });
  }, [tableLoader]);

  useEffect(() => {
    if (filtersSelectedData?.noOfGames === 5) {
      setIsNGamesSelected(true);
    }
    if (filtersSelectedData.dateRange.includes(null)) {
      setIsDateRangeOption(false);
    } else {
      setIsDateRangeOption(true);
    }

    setSelectedFilters(filtersSelectedData);

    // TODO: DEP ARRAY IS CAUSING SEASON ERROR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersSelectedData]);

  useEffect(() => {
    getGamesPlayed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedFilters.selectedTeam,
    selectedFilters.selectedOpponent,
    selectedFilters.selectedSeasons,
    selectedFilters.selectedSeasonTypes,
  ]);

  function extractDate(
    items: [Dayjs | null, Dayjs | null]
  ): [Dayjs | null, Dayjs | null] {
    if (!items) {
      return [null, null];
    }
    return items.map((date) => (date ? date : null)) as [
      Dayjs | null,
      Dayjs | null,
    ];
  }

  interface FilterParams {
    teamId?: string;
    oppTeamId?: string;
    season?: string[];
    seasonTypes?: string[];
    dates?: [Dayjs | null, Dayjs | null];
    noOfGames?: undefined | string;
    gameResults?: string[];
    perGame?: boolean;
    gamesWithPlayer?: string[];
    gamesWithoutPlayer?: string[];
    gamesStartedByPlayer?: string[];
    gamesNotStartedByPlayer?: string[];
    isGarbageExcluded?: string;
  }

  function subtractObject<T extends object>(
    obj1: T,
    obj2: Partial<T>
  ): Partial<T> {
    const newObject: Partial<T> = {};
    for (const key in obj1) {
      const value1 = obj1[key];
      const value2 = obj2[key];
      if (JSON.stringify(value1) !== JSON.stringify(value2)) {
        newObject[key] = value1;
      }
    }
    return newObject;
  }

  const saveFilters = () => {
    if (
      !isLoading &&
      (!isDateRangeOption ||
        (selectedFilters.dateRange[0] && selectedFilters.dateRange[1]))
    ) {
      const defaultFilterData = new FiltersSelectedType(defaultFilter);

      const changedFilters = subtractObject(selectedFilters, defaultFilterData);

      const newSearchParams: FilterParams = {
        teamId: changedFilters.selectedTeam?.id.toString(),
        oppTeamId: changedFilters.selectedOpponent?.id.toString(),
        season:
          changedFilters.selectedSeasons &&
          extractValues(changedFilters.selectedSeasons),
        seasonTypes: changedFilters.selectedSeasonTypes,
        dates: !isNGamesSelected
          ? changedFilters.dateRange && extractDate(changedFilters?.dateRange)
          : undefined,
        noOfGames: !isDateRangeOption
          ? changedFilters.noOfGames?.toString()
          : undefined,
        gameResults: changedFilters.selectedGameResults,
        perGame: changedFilters.isPerGameSelected,
        gamesWithPlayer:
          (changedFilters.gamesWithPlayer &&
            extractValues(changedFilters.gamesWithPlayer)) ||
          [],
        gamesWithoutPlayer:
          (changedFilters.gamesWithoutPlayer &&
            extractValues(changedFilters.gamesWithoutPlayer)) ||
          [],
        gamesStartedByPlayer:
          (changedFilters.gamesStartedByPlayer &&
            extractValues(changedFilters.gamesStartedByPlayer)) ||
          [],
        gamesNotStartedByPlayer:
          (changedFilters.gamesNotStartedByPlayer &&
            extractValues(changedFilters.gamesNotStartedByPlayer)) ||
          [],
        isGarbageExcluded: changedFilters.isGarbageExcluded?.toString(),
      };

      setSearchParams(removeEmptyValues(newSearchParams));
      setFiltersSelectedData(selectedFilters);
    }
  };

  const resetFilters = () => {
    if (!isLoading) {
      setSearchParams({});
      setIsDateRangeOption(false);
      setIsNGamesSelected(false);
      setSelectedFilters(new FiltersSelectedType(defaultFilter));
      setFiltersSelectedData(new FiltersSelectedType(defaultFilter));
    }
  };

  useEffect(() => {
    const opponents = filtersData.teamsList.map((group) => ({
      ...group,
      options: group.options.filter(
        (team) => team.value !== selectedFilters.selectedTeam?.value
      ),
    }));

    setOpponentsList(opponents);
  }, [filtersData.teamsList, selectedFilters.selectedTeam]);

  const handleTeamChange = (
    selectedTeam: SingleValue<{
      value: string;
      label: string;
      id: number;
    }>
  ) => {
    setSelectedFilters({
      ...selectedFilters,
      selectedTeam,
      selectedOpponent: null,
      gamesWithPlayer: [],
      gamesWithoutPlayer: [],
      gamesStartedByPlayer: [],
      gamesNotStartedByPlayer: [],
    });
  };

  function handleOpponentChange(
    selectedOpponent: SingleValue<{
      value: string;
      label: string;
      id: number;
    }>
  ) {
    setSelectedFilters({
      ...selectedFilters,
      selectedOpponent,
      gamesWithPlayer: [],
      gamesWithoutPlayer: [],
      gamesStartedByPlayer: [],
      gamesNotStartedByPlayer: [],
    });
  }

  const handleSeasonsChange = (
    e: MultiValue<{
      value: string;
      label: string;
    }>
  ) => {
    setSelectedFilters({
      ...selectedFilters,
      selectedSeasons: e,
      noOfGames: undefined,
    });
    setInputValue("");
  };

  const updatePlayersFilterList = (
    selectedPlayer: MultiValue<{
      value: number;
      label: string;
      isDisabled: boolean;
    }> | null,
    filterToUpdate: string,
    listToUpdate:
      | "gamesNotStartedByPlayersList"
      | "gamesStartedByPlayersList"
      | "gamesWithPlayersList"
      | "gamesWithoutPlayersList"
  ) => {
    setSelectedFilters({
      ...selectedFilters,
      [filterToUpdate]: selectedPlayer,
    });
    const updatedList: { value: number; label: string; isDisabled: boolean }[] =
      playersListFilters?.[listToUpdate].map((player) => {
        const isPlayerSelected = selectedPlayer?.some(
          (selected) => selected.value === player.value
        );

        if (isPlayerSelected) {
          return { ...player, isDisabled: true };
        } else if (!isPlayerSelected && player.isDisabled) {
          return { ...player, isDisabled: false };
        } else {
          return player;
        }
      });

    setPlayersListFilters({
      ...playersListFilters,
      [listToUpdate]: updatedList,
    });
  };

  const handleChooseOptionChange = () => {
    setIsDateRangeOption(!isDateRangeOption);
    setIsNGamesSelected(false);
    setSelectedFilters({
      ...selectedFilters,
      dateRange: [null, null],
      noOfGames: undefined,
    });

    setInputValue("");
  };

  const handleDateRangeChange = (newDateRange: [Dayjs, Dayjs]) => {
    setSelectedFilters({ ...selectedFilters, dateRange: newDateRange });
  };

  const handleIsNGamesChange = () => {
    setIsNGamesSelected(!isNGamesSelected);
    setIsDateRangeOption(false);
    setSelectedFilters({
      ...selectedFilters,
      dateRange: [null, null],
      noOfGames: undefined,
    });
    setInputValue("");
  };

  const handleNGamesButtons = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedFilters({
      ...selectedFilters,
      noOfGames: parseInt(e.currentTarget.value),
    });
    setInputValue("");
  };

  const handleNGamesInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (value.length && parseInt(value) > 0) {
      setSelectedFilters({ ...selectedFilters, noOfGames: parseInt(value) });
    } else {
      setSelectedFilters({ ...selectedFilters, noOfGames: 1 });
    }
  };

  const handleNGamesSubmit = () => {
    if (inputValue.length && parseInt(inputValue) > 0) {
      setSelectedFilters({
        ...selectedFilters,
        noOfGames: parseInt(inputValue),
      });
    }
  };

  const filterByGameResult = (isChecked: boolean, label: GameResults) => {
    if (isChecked) {
      setSelectedFilters({
        ...selectedFilters,
        selectedGameResults: [
          ...selectedFilters.selectedGameResults,
          label,
        ].sort((a, b) => a.length - b.length),
      });
    } else if (selectedFilters.selectedGameResults.length > 1) {
      setSelectedFilters({
        ...selectedFilters,
        selectedGameResults: selectedFilters.selectedGameResults
          .filter((result) => result !== label)
          .sort((a, b) => a.length - b.length),
      });
    }
  };

  const filterBySeasonType = (isChecked: boolean, label: SeasonTypes) => {
    if (isChecked) {
      setSelectedFilters({
        ...selectedFilters,
        selectedSeasonTypes: [...selectedFilters.selectedSeasonTypes, label],
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        selectedSeasonTypes: selectedFilters.selectedSeasonTypes.filter(
          (type) => type !== label
        ),
      });
    }
  };

  const getGamesPlayed = () => {
    const seasonsList = selectedFilters.selectedSeasons.map(
      (season) => season.value
    );
    if (
      selectedFilters.selectedTeam &&
      selectedFilters.selectedSeasons.length &&
      selectedFilters.selectedSeasonTypes.length
    ) {
      FiltersService.getGamesPlayed(
        seasonsList,
        selectedFilters.selectedSeasonTypes,
        selectedFilters.selectedTeam.value,
        selectedFilters.selectedOpponent?.value
      ).then((res) => {
        setGamesPlayed(res.data.GAMES_PLAYED ? res.data.GAMES_PLAYED : 0);
      });
    }
  };

  return (
    <div
      className={
        isFiltersVisible
          ? "common-card filter-card"
          : "common-card filter-card hide"
      }
    >
      <div className="flex-form filter-block legacy-gap--15 legacy-flex">
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Team</label>
            <div className="select-box">
              <Select
                options={filtersData.teamsList}
                value={selectedFilters.selectedTeam}
                onChange={handleTeamChange}
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Opponent</label>
            <div className="select-box">
              <Select
                options={opponentsList}
                value={selectedFilters.selectedOpponent}
                onChange={handleOpponentChange}
                classNamePrefix="select"
                isClearable
              />
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Possessions</label>
            <div className="toggle-btn">
              <label htmlFor="possessions">
                <input
                  type="checkbox"
                  id="possessions"
                  checked={selectedFilters.isPerGameSelected}
                  onChange={() => {
                    setSelectedFilters({
                      ...selectedFilters,
                      isPerGameSelected: !selectedFilters.isPerGameSelected,
                    });
                  }}
                />
                <span className="toggle__slide"></span>
                <span
                  className="toggle__content"
                  data-value-first="Per 100"
                  data-value-second="Per Game"
                ></span>
              </label>
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Games With Player</label>
            <div className="select-box">
              <Select
                isMulti
                options={playersListFilters.gamesWithPlayersList}
                closeMenuOnSelect={false}
                isClearable={true}
                value={selectedFilters.gamesWithPlayer}
                onChange={(e) => {
                  updatePlayersFilterList(
                    e,
                    "gamesWithPlayer",
                    "gamesWithoutPlayersList"
                  );
                }}
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Games Without Player</label>
            <div className="select-box">
              <Select
                isMulti
                options={playersListFilters.gamesWithoutPlayersList}
                closeMenuOnSelect={false}
                isClearable={true}
                value={selectedFilters.gamesWithoutPlayer}
                onChange={(e) => {
                  updatePlayersFilterList(
                    e,
                    "gamesWithoutPlayer",
                    "gamesWithPlayersList"
                  );
                }}
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Choose Option</label>
            <div className="toggle-btn">
              <label htmlFor="season">
                <input
                  type="checkbox"
                  id="season"
                  onChange={() => {
                    handleChooseOptionChange();
                  }}
                  checked={isDateRangeOption}
                />
                <span className="toggle__slide"></span>
                <span
                  className="toggle__content"
                  data-value-first="Season"
                  data-value-second="Date Range"
                ></span>
              </label>
            </div>
          </div>
        </div>
        {!isDateRangeOption && (
          <div className="legacy-flex__item">
            <div className="form-control">
              <label className="title">Season(s)</label>
              <div className="select-box">
                <Select
                  isMulti
                  options={filtersData.seasonsList}
                  closeMenuOnSelect={false}
                  isClearable={false}
                  value={selectedFilters.selectedSeasons}
                  onChange={(e) => handleSeasonsChange(e)}
                  classNamePrefix="select"
                />
              </div>
            </div>
          </div>
        )}
        {isDateRangeOption && (
          <div className="legacy-flex__item">
            <div className="form-control">
              <label className="title">Date Range</label>
              <div className="select-box">
                <DatePicker.RangePicker
                  format="Do MMM, YYYY"
                  value={selectedFilters.dateRange}
                  separator={"-"}
                  onChange={(e) => handleDateRangeChange(e as [Dayjs, Dayjs])}
                  allowClear={true}
                  disabledDate={(d) =>
                    !d ||
                    d.isAfter(
                      `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
                        .toString()
                        .padStart(2, "0")}-${new Date()
                        .getDate()
                        .toString()
                        .padStart(2, "0")}`
                    )
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Games</label>
            <div className="toggle-btn">
              <label htmlFor="games">
                <input
                  type="checkbox"
                  id="games"
                  checked={isNGamesSelected}
                  onChange={handleIsNGamesChange}
                />
                <span className="toggle__slide"></span>
                <span
                  className="toggle__content"
                  data-value-first="All Games"
                  data-value-second="N Games"
                ></span>
              </label>
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div
            className={
              !isNGamesSelected ||
              selectedFilters.selectedSeasons.length > 1 ||
              isDateRangeOption
                ? "form-control disabled"
                : "form-control"
            }
          >
            <label className="title">N Games</label>
            <div className="n-games-button">
              <button
                value={1}
                className={selectedFilters.noOfGames === 1 ? "selected" : ""}
                onClick={(e) => {
                  handleNGamesButtons(e);
                }}
              >
                1
              </button>
              <button
                value={5}
                className={selectedFilters.noOfGames === 5 ? "selected" : ""}
                onClick={(e) => {
                  handleNGamesButtons(e);
                }}
              >
                5
              </button>
              <button
                value={10}
                className={selectedFilters.noOfGames === 10 ? "selected" : ""}
                onClick={(e) => {
                  handleNGamesButtons(e);
                }}
              >
                10
              </button>
              <input
                className={
                  selectedFilters.noOfGames &&
                  selectedFilters.noOfGames === Number(inputValue)
                    ? "selected"
                    : ""
                }
                type="text"
                placeholder={`Input Number (Max ${
                  gamesPlayed ? gamesPlayed : "0"
                })`}
                value={inputValue}
                min={gamesPlayed ? 1 : 0}
                max={gamesPlayed}
                onChange={(e) => {
                  handleNGamesInput(e);
                  const inputValue = e.target.value;
                  const numericValue = inputValue.replace(/[^0-9]/g, "");
                  setInputValue(
                    gamesPlayed && parseInt(numericValue) > gamesPlayed
                      ? gamesPlayed.toString()
                      : numericValue
                  );
                }}
              />
              <button onClickCapture={handleNGamesSubmit} type="button">
                <i className="cs-icon icon-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Games Started By Player</label>
            <div className="select-box">
              <Select
                isMulti
                options={playersListFilters.gamesStartedByPlayersList}
                closeMenuOnSelect={false}
                isClearable={true}
                value={selectedFilters.gamesStartedByPlayer}
                onChange={(e) => {
                  updatePlayersFilterList(
                    e,
                    "gamesStartedByPlayer",
                    "gamesNotStartedByPlayersList"
                  );
                }}
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Games Not Started By Player</label>
            <div className="select-box">
              <Select
                isMulti
                options={playersListFilters.gamesNotStartedByPlayersList}
                closeMenuOnSelect={false}
                isClearable={true}
                value={selectedFilters.gamesNotStartedByPlayer}
                onChange={(e) => {
                  updatePlayersFilterList(
                    e,
                    "gamesNotStartedByPlayer",
                    "gamesStartedByPlayersList"
                  );
                }}
                classNamePrefix="select"
              />
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Game Result</label>
            <div className="checkbox-group">
              {gameResultsList.map((label, index) => (
                <label key={index} className="checkbox-btn">
                  <input
                    type="checkbox"
                    checked={selectedFilters.selectedGameResults.includes(
                      label
                    )}
                    onChange={(e) =>
                      filterByGameResult(e.target.checked, label)
                    }
                  />
                  <span>{label}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="legacy-flex__item">
          <div className="form-control">
            <label className="title">Season Type</label>
            <div className="checkbox-group">
              {SEASON_TYPES_LIST.map((seasonType, index) => (
                <label key={index} className="checkbox-btn">
                  <input
                    type="checkbox"
                    checked={selectedFilters.selectedSeasonTypes.includes(
                      seasonType.value
                    )}
                    onChange={(e) =>
                      filterBySeasonType(e.target.checked, seasonType.value)
                    }
                  />
                  <span>{seasonType.label}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="action-filters">
        <button
          className={`btn sm-btn btn-primary text-normal btn-orange ${
            isLoading
              ? "disabled"
              : !selectedFilters.selectedSeasonTypes.length
                ? "disabled"
                : ""
          }`}
          onClick={saveFilters}
        >
          Filter
        </button>
        <button
          className={`btn sm-btn btn-primary text-normal ${isLoading ? "disabled" : ""}`}
          onClick={resetFilters}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default TeamDNAFilters;
