import { useState } from "react";
import {
  PlayerHeader,
  PlayerHeaderSkeleton,
  useNavigateLogin,
} from "@/components";
import { useNavigate, useParams } from "react-router-dom";
import { apolloApi } from "@/shared/api";
import IntelForm, {
  IntelFormSchema,
} from "../intel-create-player-team/components/IntelForm";
import { Button, Card, Flex, Skeleton, Text } from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";
import { usePlayer } from "../hooks/usePlayers";
import { IntelRow, IntelUpsertParams } from "@suns/api/generated-client/apollo";
import { notify } from "@/components/bugsnag";
import { useAsync } from "@/shared/hooks";
import {
  intelFormDataToUpsertParams,
  intelResponseToFormData,
} from "../intel-create-player-team/intel-utils";
import { SunsApiError } from "@suns/api";
import { intelByIdLoader } from "./loaders/intel-edit-loader";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "@/shared/utils/toast-messages";
import { useTeamById } from "../hooks/useTeams";
import { TeamHeader } from "../components/TeamHeader.tsx/TeamHeader";

export function IntelEdit() {
  const navigate = useNavigate();
  const navigateLogin = useNavigateLogin();
  const { playerId, teamId, intelId } = useParams();
  const [saving, setSaving] = useState(false);
  const [upsertedIntel, setUpsertedIntel] = useState<IntelFormSchema | null>(
    null
  );

  const {
    player,
    loading: playerLoading,
    error: playerError,
  } = usePlayer(Number(playerId));

  const {
    response: team,
    loading: teamLoading,
    error: teamError,
  } = useTeamById(teamId ? Number(teamId) : undefined);

  const {
    response: intel,
    loading: intelLoading,
    error: intelError,
  } = useAsync(intelByIdLoader, {
    intelId: intelId ? Number(intelId) : undefined,
  });

  const handleSave = async (
    intel: IntelFormSchema,
    type: "save" | "publish"
  ) => {
    try {
      setSaving(true);
      const savedIntel = await apolloApi.saveIntel({
        id: intelId ? Number(intelId) : undefined,
        ...intelFormDataToUpsertParams({
          ...intel,
          playerId: playerId ? Number(playerId) : undefined,
          teamId: teamId ? Number(teamId) : undefined,
        }),
      });

      setUpsertedIntel(savedIntel.intel);

      if (
        type === "publish" &&
        intel.status === IntelUpsertParams.status.PUBLISHED
      ) {
        toastSuccess("Intel Published");
        navigate(-1);
      } else if (
        type === "publish" &&
        intel.status === IntelUpsertParams.status.UNPUBLISHED
      ) {
        toastInfo("Intel Unpublished");
      } else {
        toastInfo("Intel Saved");
      }
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toastError("Please log back in to save.");
        navigateLogin();
      }
      notify(new Error("Error saving intel.", { cause: e }));
      toastError("Unable to save intel. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  if (intelError) {
    throw new SunsApiError("There was an error requesting intel.", {
      cause: intelError,
    });
  }

  if (playerError) {
    throw new SunsApiError("There was an error requesting a player.", {
      cause: playerError,
    });
  }

  if (teamError) {
    throw new SunsApiError("There was an error requesting a team.", {
      cause: teamError,
    });
  }

  return (
    <>
      <Flex className="mb-2" align="center">
        <Button
          size="xs"
          variant="ghost"
          className="p-0 text-sm text-gray-500"
          onClick={() => navigate("/reports/intel/create")}
        >
          Intel
        </Button>
        <ChevronRight className="text-gray-500 " size={20} />
        <Button size="xs" variant="ghost" className="p-0 text-sm text-gray-500">
          Edit Intel
        </Button>
      </Flex>
      <Text heading={true} size="4xl" className="mb-4">
        Edit Intel
      </Text>
      <Card>
        {playerLoading || intelLoading || teamLoading ? (
          <Flex direction="down" gap="lg" className="mt-1">
            <PlayerHeaderSkeleton />
            <Flex direction="down" gap="md">
              <Skeleton className="h-72 w-full" />
              <Skeleton className="h-10 w-52" />
              <Skeleton className="h-10 w-96" />
              <Flex gap="md">
                <Skeleton className="h-10 w-32" />
                <Skeleton className="h-10 w-32" />
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <>
            {playerId ? (
              <PlayerHeader
                player={player!}
                teamNbaId={player!.currentTeam?.nbaId ?? undefined}
                teamName={player!.currentTeam?.fullName ?? undefined}
                teamImage={player!.currentTeam?.image ?? undefined}
                leagueId={player!.currentTeam?.domesticLeagueId}
              />
            ) : (
              <TeamHeader
                teamNbaId={team?.nbaId ?? undefined}
                teamName={team?.fullName ?? undefined}
                image={team?.image ?? undefined}
                leagueId={team?.domesticLeagueId}
                teamDivision={
                  team?.division || team?.conference || team?.countryCode
                }
              />
            )}

            <IntelForm
              intel={
                upsertedIntel
                  ? intelResponseToFormData(upsertedIntel as IntelRow)
                  : intelResponseToFormData(intel ?? ({} as IntelRow))
              }
              onSave={handleSave}
              onPublish={handleSave}
              saving={saving}
            />
          </>
        )}
      </Card>
    </>
  );
}
