import { QueryParams } from "./types/inputs";
export type TransformedQueryParams = ReturnType<
  typeof transformParamsForRequest
>;

export function transformParamsForRequest(
  params: Omit<QueryParams<"event">, "scope">
) {
  if (params.selfAchievement && !Array.isArray(params.selfAchievement)) {
    params.selfAchievement = [params.selfAchievement];
  }

  return {
    LeagueId: params.leagueId,
    SeasonYear: handleArrayJoin(params.seasonYear),
    SeasonType: handleArrayJoin(params.seasonType),
    PerMode: handleArrayJoin(params.perMode),
    Grouping: params.grouping,
    TeamGrouping: params.teamGrouping,
    Measures: handleArrayJoin(params.measures),
    MeasureType: params.measureType,
    FilterGrouping: handleArrayJoin(params.filterGrouping),
    SelfAchievement: handleArrayJoin(
      params.selfAchievement?.map(
        ({ measure, comparison, value }) => `${measure}|${comparison}|${value}`
      )
    ),
    PlayerId: handleArrayJoin(params.playerId),
    GameId: handleArrayJoin(params.gameId),
    TeamId: handleArrayJoin(params.teamId),
    Conference: handleArrayJoin(params.conference),
    Division: handleArrayJoin(params.division),
    OppTeamId: handleArrayJoin(params.oppTeamId),
    VsConference: handleArrayJoin(params.vsConference),
    VsDivision: handleArrayJoin(params.vsDivision),
    Period: handleArrayJoin(params.period),
    Outcome: handleArrayJoin(params.outcome),
    Location: handleArrayJoin(params.location),
    Month: handleArrayJoin(params.month),
    StarterBench: params.starterBench,
    DraftYear: handleArrayJoin(params.draftYear),
    DraftNumberRange: tupleToMinMaxString(params.draftNumberRange),
    DraftRound: handleArrayJoin(params.draftRound),
    DraftPickType: handleArrayJoin(params.draftPickType),
    LastAffiliation: handleArrayJoin(params.lastAffiliation),
    Country: handleArrayJoin(params.country),
    Height: handleArrayJoin(params.height),
    PlayerPosition: handleArrayJoin(params.playerPosition),
    SeasonExpRange: tupleToMinMaxString(params.seasonExpRange),
    RookieYear: handleArrayJoin(params.rookieYear),
    PoRound: handleArrayJoin(params.poRound),
    SeasonSegment: params.seasonSegment,
    DateRange: tupleToMinMaxString(params.dateRange),
    DayOfWeek: handleArrayJoin(params.dayOfWeek),
    DayOfMonth: handleArrayJoin(params.dayOfMonth),
    PlayerLastNGames: params.playerLastNGames,
    TeamLastNGames: params.teamLastNGames,
    GameSegment: handleArrayJoin(params.gameSegment),
    ClutchTime: handleArrayJoin(params.clutchTime),
    ScoreDiffRange: tupleToMinMaxString(params.scoreDiffRange),
    PlayersOnCourt: handleArrayJoin(params.playersOnCourt),
    PlayersOffCourt: handleArrayJoin(params.playersOffCourt),
    OppPlayersOnCourt: handleArrayJoin(params.oppPlayersOnCourt),
    OppPlayersOffCourt: handleArrayJoin(params.oppPlayersOffCourt),
    ActiveRoster: params.activeRoster,
    MatchupId: handleArrayJoin(params.matchupId),
    GameSubtype: handleArrayJoin(params.gameSubtype),
    ISTRound: handleArrayJoin(params.istRound),
    DaysRestRange: tupleToMinMaxString(params.daysRestRange),
    RestAdvantage: params.restAdvantage,
    GameClockRange: tupleToMinMaxString(params.gameClockRange),
    LineupType: params.lineupType,
    PlayerFirstNGames: params.playerFirstNGames,
    TeamFirstNGames: params.teamFirstNGames,
    PossStartType: params.possStartType,
    PossStartPlayer: handleArrayJoin(params.possStartPlayer),
    OppWinPctRange: tupleToMinMaxString(params.oppWinPctRange),
    SeriesGameNumber: params.seriesGameNumber,
    SeriesGameType: params.seriesGameType,
    PlayerAwardSeason: params.playerAwardSeason,
    PlayerAwardCareer: params.playerAwardCareer,
    SortColumn: params.sortColumn
      ? `${params.sortColumn.measure}|${params.sortColumn.direction}`
      : undefined,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleArrayJoin<T = any>(input: T | T[] | undefined) {
  if (input === undefined) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.join(",");
  }

  return `${input}`;
}

function tupleToMinMaxString(
  minMax: [string | number | null, string | number | null] | undefined
) {
  if (minMax === undefined) return minMax;
  const [min, max] = minMax;
  return `${min === null ? "" : min},${max === null ? "" : max}`;
}
