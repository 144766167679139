import {
  Flex,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Text,
  TextEditor,
} from "@suns/design-system";
import { FormSkillSelect } from "../FormSkillSelect";
import {
  OtherScores as OtherScoreList,
  ReportScoreDescriptions,
} from "@/pages/reports/reports-const";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { useFormState } from "react-hook-form";

export default function OtherScores({ readonly = false }) {
  const formState = useFormState();
  const type = formState.defaultValues?.type ?? ReportResponseItem.type.GAME;

  return (
    <Flex direction="down" gap="md">
      <Text size="md" heading asChild>
        <FormLabel>Other</FormLabel>
      </Text>
      <Flex direction="right" gap="lg" wrap>
        {OtherScoreList.map((score) => (
          <FormSkillSelect
            key={score}
            name={`scores.${score}`}
            label={ReportScoreDescriptions[score].label}
            tooltip={ReportScoreDescriptions[score].description}
            allowNA={type !== ReportResponseItem.type.PHASE}
          />
        ))}
      </Flex>

      <FormField
        name="otherNotes"
        render={({ field }) => (
          <FormItem>
            <Text size="md" muted heading asChild>
              <FormLabel>Other notes</FormLabel>
            </Text>
            <FormControl>
              <TextEditor
                className="h-44"
                readonly={readonly}
                {...field}
                defaultValue={formState.defaultValues?.otherNotes}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </Flex>
  );
}
