import { Navigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "@/msal.config";
import microsoft from "@/assets/img/microsoft.svg";
import sunsLogo from "@/assets/img/suns-word-logo.svg";
import blueprintSunBurst from "@/assets/img/blueprint-sun-burst.svg";
import { Button, Flex, Text } from "@suns/design-system";
import { LoaderCircle } from "@suns/design-system/icons";
import { useAccount } from "@/shared/hooks";

export function Login() {
  const { isAuthenticated, hasDefaultAccess } = useAccount();
  const location = useLocation();
  const { instance } = useMsal();
  const [msLoader, setMsLoader] = useState<boolean>(false);

  const handleLogin = () => {
    setMsLoader(true);
    instance
      .loginPopup(loginRequest)
      .then(async (res) => {
        const account = res.account;
        instance.setActiveAccount(account);
        setMsLoader(false);
      })
      .catch(() => {
        setMsLoader(false);
      });
  };

  if (isAuthenticated && hasDefaultAccess) {
    const navigateTo = location.state?.from || "/";
    return <Navigate to={navigateTo} replace />;
  }

  return (
    <Flex className="h-screen">
      <div className="relative hidden w-1/3 overflow-hidden bg-secondary sm:block">
        <img
          src={blueprintSunBurst}
          className="absolute -bottom-16 -left-40 w-[500px] max-w-none"
        />
      </div>
      <Flex
        direction="down"
        align="center"
        justify="center"
        gap="lg"
        className="w-full sm:w-2/3"
      >
        {isAuthenticated && (
          <Text variant="destructive">
            Your account doesn't have access to this application.
          </Text>
        )}
        <img src={sunsLogo} className="w-64" />
        <Button variant="secondary" size="lg" onClick={handleLogin}>
          {msLoader ? (
            <LoaderCircle className="mr-3 w-5 animate-spin" />
          ) : (
            <img src={microsoft} className="mr-3 w-5" />
          )}
          Sign in
        </Button>
      </Flex>
    </Flex>
  );
}
