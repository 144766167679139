import { useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  Flex,
  Text,
} from "@suns/design-system";
import microsoft from "@/assets/img/microsoft.svg";
import { LoaderCircle } from "@suns/design-system/icons";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "@/msal.config";
import { AccountInfo } from "@azure/msal-browser";

const LOGIN_DIALOG: {
  openDialog?: () => Promise<AccountInfo>;
} = {};

export async function promptLogin() {
  if (!LOGIN_DIALOG.openDialog) {
    throw new Error("LoginDialog attempted to open without being initialized.");
  }
  return LOGIN_DIALOG.openDialog();
}

export function LoginDialog() {
  const [open, setOpen] = useState(false);
  const [msLoader, setMsLoader] = useState<boolean>(false);
  const { instance } = useMsal();
  const loginPromiseRef = useRef<{
    resolve?: (account: AccountInfo) => void;
    reject?: () => void;
  }>({});

  LOGIN_DIALOG.openDialog = async () => {
    const loginPromise = new Promise<AccountInfo>((resolve, reject) => {
      loginPromiseRef.current = { resolve, reject };
    });
    setOpen(true);
    return loginPromise;
  };

  function handleOpenChange(open: boolean) {
    setOpen(open);
    if (!open) {
      loginPromiseRef.current?.reject?.();
    }
  }

  function handleLogin() {
    setMsLoader(true);
    instance
      .loginPopup(loginRequest)
      .then(async ({ account }) => {
        instance.setActiveAccount(account);
        setMsLoader(false);
        setOpen(false);
        loginPromiseRef.current?.resolve?.(account);
      })
      .catch(() => {
        setMsLoader(false);
        setOpen(false);
        loginPromiseRef.current?.reject?.();
      });
  }

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent>
        <DialogTitle>Your session has expired.</DialogTitle>
        <DialogDescription>
          <Flex direction="down" gap="md" align="start">
            <Text>
              You've been logged out due to your Microsoft session expiring.
              Please renew your session by logging in again.
            </Text>
            <Button variant="secondary" size="lg" onClick={handleLogin}>
              {msLoader ? (
                <LoaderCircle className="mr-3 w-5 animate-spin" />
              ) : (
                <img src={microsoft} className="mr-3 w-5" />
              )}
              Sign in
            </Button>
          </Flex>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
