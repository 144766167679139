/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum IntelTag {
    BASKETBALL = 'BASKETBALL',
    BACKGROUND = 'BACKGROUND',
    CHARACTER = 'CHARACTER',
    MEDICAL = 'MEDICAL',
    BODY = 'BODY',
    PSYCH = 'PSYCH',
    COACHING = 'COACHING',
    DRAFT = 'DRAFT',
    NIL = 'NIL',
    TRADE = 'TRADE',
    FRONT_OFFICE = 'FRONT_OFFICE',
    FREE_AGENCY = 'FREE_AGENCY',
    MINI_CAMP = 'MINI_CAMP',
    CONTRACT = 'CONTRACT',
}
