import * as React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@suns/design-system/icons";
import { DayPicker } from "react-day-picker";

import { cn } from "../../utils";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col space-y-1 ",
        month: "space-y-1",
        month_caption: "flex justify-center  relative items-center",
        caption_label: "text-sm font-semibold",
        nav: " flex items-center",
        button_previous:
          "absolute z-10 left-4 border rounded p-1 top-3 cursor-pointer",
        button_next:
          "absolute z-10 right-4 border rounded p-1 top-3 cursor-pointer",
        chevron: "h-4 w-4 text-gray-500",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        weekday: "text-sm font-normal text-gray-500",
        cell: cn(
          "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
            : "[&:has([aria-selected])]:rounded-md"
        ),
        day: "p-0 rounded overflow-hidden",
        day_button:
          "h-8 w-8 hover:text-foreground hover:bg-gray-100 text-center text-sm font-normal aria-selected:opacity-100",
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        selected:
          "bg-gray-400 font-semibold text-primary-foreground hover:bg-gray-400 hover:text-primary-foreground focus:bg-gray-400 focus:text-primary-foreground",
        today: "bg-accent text-accent-foreground",
        outside:
          "day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        hidden: "invisible",
        ...classNames,
      }}
      components={{
        Chevron: ({ orientation }) =>
          orientation === "right" ? (
            <ChevronRightIcon className="h-4 w-4 text-gray-500" />
          ) : (
            <ChevronLeftIcon className="h-4 w-4 text-gray-500" />
          ),
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
