import {
  Select,
  SelectOption,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@suns/design-system";

interface FormSelectOption {
  value: string;
  label: string;
}

interface FormSelectParams {
  title: string | null;
  name: string;
  placeholder: string;
  options: readonly FormSelectOption[];
  labelClassName?: string;
}

export function FormSelect({
  title,
  name,
  placeholder,
  options,
  labelClassName,
}: FormSelectParams) {
  return (
    <FormField
      name={name}
      render={({ field }) => (
        <FormItem>
          {title ? (
            <FormLabel className={labelClassName}>{title}</FormLabel>
          ) : null}
          <FormControl>
            <Select
              {...field}
              placeholder={placeholder}
              onValueChange={field.onChange}
              value={field.value == null ? undefined : field.value}
            >
              {options.map((option) => (
                <SelectOption key={option.value} value={option.value}>
                  {option.label}
                </SelectOption>
              ))}
            </Select>
          </FormControl>
        </FormItem>
      )}
    />
  );
}
