import { Outlet } from "react-router-dom";
import Header from "@/components/header/header";
import { Protected } from "@/components";
import SideBar from "@/components/sidebar/sidebar";
import { cn } from "@suns/design-system";

export function AppLayout() {
  return (
    <Protected>
      <SideBar />
      <main
        className={cn(
          "wrapper-main"
          // location.pathname === "/glossary" && "xs:max-lg:pt-20"
        )}
      >
        <Header />
        <Outlet />
      </main>
    </Protected>
  );
}
