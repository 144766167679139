import { PlayerHeadshot } from "@/components/PlayerHeadshot/PlayerHeadshot";
import { Flex, Skeleton, SkeletonText, Text } from "@suns/design-system";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { TeamLogo } from "@/components/TeamLogo/TeamLogo";
import { ApolloLeagueId } from "@suns/api";
import { PlayerRow } from "@/swagger";

dayjs.extend(relativeTime);

interface PlayerHeaderProps {
  player: PlayerRow;
  teamNbaId?: number;
  teamName?: string;
  variant?: "compact" | "wide";
  teamImage?: string;
  leagueId?: ApolloLeagueId;
}

interface StatCategoryProps {
  label: string;
  value: string;
}

function formatHeight(height: string): string {
  if (height === "0") return "NA";

  const parts = height.split("-");
  if (parts.length === 2) {
    return `${parts[0]}' ${parts[1]}`;
  } else {
    return height;
  }
}

function formatWeight(weight: number): string {
  return `${weight} lbs`;
}

function formatInchesToFeet(inches: number): string {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  return `${feet}' ${remainingInches}`;
}

function formatAge(birthDate: string): string {
  return dayjs().diff(dayjs(birthDate), "year", true).toFixed(1);
}

function StatCategory({ label, value }: StatCategoryProps) {
  return (
    <Flex direction="down">
      <Text size="xs">{label}</Text>
      <Text size="lg" heading>
        {value}
      </Text>
    </Flex>
  );
}

export function PlayerHeader({
  player,
  teamImage,
  teamNbaId,
  teamName,
  variant = "compact",
  leagueId,
}: PlayerHeaderProps) {
  return (
    <Flex gap="lg" className="content-center items-center">
      <Flex className="aspect-square">
        <PlayerHeadshot
          key={player.image}
          src={player.image}
          playerId={player.nbaId}
          leagueId={leagueId}
          size="lg"
        />
      </Flex>
      <Flex
        direction={variant === "compact" ? "down" : "right"}
        gap={variant === "compact" ? undefined : "lg"}
      >
        <Flex direction="down">
          <Text size="xl" heading>
            {player.firstName} {player.lastName}
          </Text>
          <Flex
            direction="right"
            gap="xs"
            className="hidden items-center md:flex"
          >
            {player.nbaId || teamImage ? (
              <TeamLogo
                image={teamImage}
                teamId={teamNbaId}
                leagueId={leagueId}
                size="xxs"
              />
            ) : (
              <div className="h-3" />
            )}
            <Text size="xs">{teamName ?? "Free Agent"}</Text>
          </Flex>
        </Flex>

        <Flex direction="right" className="gap-x-md pt-2 md:gap-x-lg" wrap>
          <StatCategory
            label="Height"
            value={player.height ? formatHeight(player.height) : "NA"}
          />
          <StatCategory
            label="Weight"
            value={player.weight ? formatWeight(player.weight) : "NA"}
          />
          <StatCategory
            label="Age"
            value={player.birthDate ? formatAge(player.birthDate) : "NA"}
          />
          <StatCategory
            label="Wing"
            value={player.wing ? formatInchesToFeet(player.wing) : "NA"}
          />
          <StatCategory
            label="Reach"
            value={player.reach ? formatInchesToFeet(player.reach) : "NA"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export function PlayerHeaderSkeleton() {
  return (
    <Flex gap="lg" className="content-center">
      {/* <PlayerHeadshot playerId={nbaId} size="lg" /> */}
      <Skeleton className="aspect-square h-24 w-auto rounded-full" />
      <Flex direction="down" gap="sm">
        {/* <SkeletonHeading /> */}
        <SkeletonText rows={1} className="h-6 w-64" />
        <SkeletonText rows={1} className="h-4 w-40" />

        <Flex direction="right" gap="md">
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
        </Flex>
      </Flex>
    </Flex>
  );
}
