const SuspenseFallback = () => {
  return (
    <>
      <div className="loader-main">
        <h1>Loading.....</h1>
      </div>
    </>
  );
};

export default SuspenseFallback;
