import { useAccount } from "@/shared/hooks/useAccount";

interface UserRoleGuardProps {
  children: React.ReactNode;
  allowedRoles: string[];
}

export default function UserRoleGuard({
  children,
  allowedRoles,
}: UserRoleGuardProps) {
  const account = useAccount();

  const hasPermission =
    account &&
    allowedRoles.some((role) => account[role as keyof typeof account] === true);

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
}
