export enum GameResults {
  WIN = "Win",
  LOSS = "Loss",
}

export enum SeasonTypes {
  PRE_SEASON = "Pre Season",
  REGULAR_SEASON = "Regular Season",
  POST_SEASON = "Playoffs",
  PLAY_IN_SEASON = "Play-In Season",
}

// OpenAPI converts enums to unions
export type SeasonTypesUnion = `${SeasonTypes}`;

export const SEASON_TYPES_LIST = [
  // { value: SeasonTypes.PRE_SEASON, label: "Pre Season" },
  { value: SeasonTypes.REGULAR_SEASON, label: "Regular Season" },
  { value: SeasonTypes.PLAY_IN_SEASON, label: "Play-In Season" },
  { value: SeasonTypes.POST_SEASON, label: "Post Season" },
];

export const N_GAMES_LIST = [
  { value: "1", label: "1 Game" },
  { value: "5", label: "5 Games" },
  { value: "10", label: "10 Games" },
];

export enum TableTypes {
  OFFENSE = "Offense",
  DEFENSE = "Defense",
}

export enum CalculationTypes {
  PER_75 = "Per_75",
  PER_GAME = "Per_Game",
  PER_100 = "Per_100",
  TOTAL = "Total",
}

export enum TeamDnaDataTypes {
  TEAM_DNA = "TEAM_DNA",
  PLAY_TYPES = "PLAY_TYPES",
}
