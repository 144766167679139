import { useEffect, useRef, useState } from "react";
import "./game-dna-index.scss";
import {
  CourtTransitionAndPaintAttackData,
  GameDnaService,
  PushingThePaceData,
  PlayType,
  ShootingTypeData,
  AdvancedBoxData,
  FrequentPlayerActionsType,
  Game,
  CrashersData,
} from "@/swagger";
import Table from "@/components/table/table";
import { TableSkeleton } from "@/components/table-skeleton/table-skeleton";
import {
  PlayTypesTableTabs,
  BoxScoreTableTabs,
  PushingHalfPaintTableTabs,
} from "@/types/enums/gameDna.enums";
import { useParams /*useRevalidator*/ } from "react-router-dom";
import {
  advancedBoxColumns,
  courtAndTransitionColumns,
  crashersColumns,
  frequentPlayerActionsColumns,
  paintAttackColumns,
  playTypesColumns,
  pushingThePaceColumns,
  shootingColumns,
} from "./game-dna-columns";
import { updateTimestamp } from "@/shared/utils/helper-functions";
import BoxScore from "@/components/box-score/box-score";
import { SeasonTypes } from "@/types/enums/teamDna.enums";
import dayjs from "dayjs";

const GameDnaIndex = () => {
  const { gameId } = useParams();
  const [game, setGame] = useState<Game>();
  const [boxScoreData, setBoxScoreData] = useState<{
    all: object[];
    firstHalf: object[];
    secondHalf: object[];
    overTime: object[];
  }>({ all: [], firstHalf: [], secondHalf: [], overTime: [] });
  const [boxScoreTableData, setBoxScoreTableData] = useState<object[]>([]);
  const [advancedBoxData, setAdvancedBoxData] = useState<
    Array<AdvancedBoxData>
  >([]);
  const [boxScoreLoading, setBoxScoreLoading] = useState(true);
  const [advanceBoxLoading, setAdvanceBoxLoading] = useState(true);
  const [frequentPlayersActionLoading, setFrequentPlayersActionLoading] =
    useState(true);
  const [selectedBoxScoreTab, setSelectedBoxScoreTab] = useState<string>(
    BoxScoreTableTabs.ALL
  );
  const [playTypesTableData, setPlayTypesTableData] = useState<PlayType>({
    [PlayTypesTableTabs.OFFENSE]: [],
    [PlayTypesTableTabs.DEFENSE]: [],
  });
  const [selectedPlayTypesTab, setSelectedPlayTypesTab] = useState<
    PlayTypesTableTabs.OFFENSE | PlayTypesTableTabs.DEFENSE
  >(PlayTypesTableTabs.OFFENSE);
  const [playTypeLoading, setPlayTypeLoading] = useState(true);
  const [scrollTimeout, setScrollTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const [shootingData, setShootingData] = useState<Array<ShootingTypeData>>([]);
  const [crashersData, setCrashersData] = useState<Array<CrashersData>>([]);
  const [shootingLoader, setShootingLoader] = useState<boolean>(true);
  const [isDataFinalized, setIsDataFinalized] = useState<boolean>(true);
  const [crashersLoader, setCrashersLoader] = useState<boolean>(true);
  const componentRef = useRef(null);

  const [pushingHalfPaintTableData, setPushingHalfPaintTableData] =
    useState<PushingHalfPaintTableData>({
      [PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA]: [],
      [PushingHalfPaintTableTabs.PAINT_ATTACK_DATA]: [],
      [PushingHalfPaintTableTabs.COURT_AND_TRANSITION_DATA]: [],
      show: PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA,
    });
  const pushingHalfPaintTableRef: React.RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedAt, setUpdatedAt] = useState("");
  type PushingHalfPaintTableShow =
    | PushingHalfPaintTableTabs.COURT_AND_TRANSITION_DATA
    | PushingHalfPaintTableTabs.PAINT_ATTACK_DATA
    | PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA;

  type PushingHalfPaintTableData = {
    [PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA]: PushingThePaceData[];
    [PushingHalfPaintTableTabs.PAINT_ATTACK_DATA]: CourtTransitionAndPaintAttackData[];
    [PushingHalfPaintTableTabs.COURT_AND_TRANSITION_DATA]: CourtTransitionAndPaintAttackData[];
    show: PushingHalfPaintTableShow;
  };
  const [frequentPlayerActionsData, setFrequentPlayerActionsData] =
    useState<FrequentPlayerActionsType>();
  const [selectedFrequentPlayerAction, setSelectedFrequentPlayerAction] =
    useState<keyof FrequentPlayerActionsType>("ALL");
  // const revalidate = useRevalidator();

  async function update() {
    setBoxScoreLoading(true);
    setAdvanceBoxLoading(true);
    setPlayTypeLoading(true);
    setFrequentPlayersActionLoading(true);
    setShootingLoader(true);
    setCrashersLoader(true);
    setIsUpdating(true);

    const { data: game } = gameId
      ? await GameDnaService.getGame(gameId)
      : await GameDnaService.getLatestMatch();
    setGame(game);

    async function fetchBoxScore() {
      const { data } = await GameDnaService.getBoxScoreData(game.gameId);
      setBoxScoreData(data);
      setBoxScoreTableData(data.all);
      setBoxScoreLoading(false);
    }

    async function fetchPlayTypes() {
      const { data } = await GameDnaService.getPlayTypesData(game.gameId);
      setPlayTypesTableData(data);
      setPlayTypeLoading(false);
    }

    async function fetchAdvancedBoxScore() {
      const { data } = await GameDnaService.getAdvancedBoxData(game.gameId);
      setAdvancedBoxData(
        data.advancedBoxData.map((item) => {
          return item;
        })
      );

      const pushingThePaceData = data[
        PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA
      ].filter((item) => item.TEAM !== "PHX - Pass aheads");

      setPushingHalfPaintTableData({
        ...pushingHalfPaintTableData,
        [PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA]: pushingThePaceData,
        [PushingHalfPaintTableTabs.PAINT_ATTACK_DATA]:
          data[PushingHalfPaintTableTabs.PAINT_ATTACK_DATA],
        [PushingHalfPaintTableTabs.COURT_AND_TRANSITION_DATA]:
          data[PushingHalfPaintTableTabs.COURT_AND_TRANSITION_DATA],
      });
      setAdvanceBoxLoading(false);
    }

    async function fetchShooting() {
      const { data } = await GameDnaService.getShooting(game.gameId);
      setShootingData(data.shootingData);
      setShootingLoader(false);
    }

    async function fetchCrashers() {
      const { data } = await GameDnaService.getCrashers(game.gameId);
      setCrashersData(data);
      setCrashersLoader(false);
    }

    async function fetchFrequentPlayerActions() {
      const { data } = await GameDnaService.getFrequentPlayerActions(
        game.gameId
      );
      setFrequentPlayerActionsData(data);
      setFrequentPlayersActionLoading(false);
    }

    async function fetchCheckIsDataFinalized() {
      const { data } = await GameDnaService.checkIsDataFinalized(game.gameId);
      setIsDataFinalized(data);
    }

    Promise.all([
      fetchBoxScore(),
      fetchPlayTypes(),
      fetchAdvancedBoxScore(),
      fetchShooting(),
      fetchCrashers(),
      fetchFrequentPlayerActions(),
      fetchCheckIsDataFinalized(),
    ])
      .then(() => {
        setIsUpdating(false);
        setUpdatedAt(updateTimestamp(true));
      })
      .catch(() => {
        setIsUpdating(false);
      });
  }

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  useEffect(() => {
    setInterval(() => {
      setUpdatedAt(updateTimestamp());
    }, 1000);
  }, []);

  const onUpdateButtonClick = () => {
    if (isUpdating) {
      return;
    }
    update();
  };

  const updatePushingHalfPaintTableShow = (show: PushingHalfPaintTableShow) => {
    setPushingHalfPaintTableData({ ...pushingHalfPaintTableData, show });
  };

  useEffect(() => {
    const columnWidth =
      document?.getElementById("advance-box-tables")?.offsetWidth ?? 0;
    if (pushingHalfPaintTableRef.current) {
      if (
        pushingHalfPaintTableData.show ===
        PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA
      ) {
        pushingHalfPaintTableRef.current.scrollLeft = 0;
      } else if (
        pushingHalfPaintTableData.show ===
        PushingHalfPaintTableTabs.COURT_AND_TRANSITION_DATA
      ) {
        pushingHalfPaintTableRef.current.scrollLeft = columnWidth;
      } else if (
        pushingHalfPaintTableData.show ===
        PushingHalfPaintTableTabs.PAINT_ATTACK_DATA
      ) {
        pushingHalfPaintTableRef.current.scrollLeft = columnWidth * 2;
      }
    }
  }, [pushingHalfPaintTableData]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const columnWidth =
      (document.getElementById("advance-box-tables") as HTMLDivElement)
        .offsetWidth ?? 0;
    const divElement = e.currentTarget as HTMLDivElement;
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    if (divElement?.scrollLeft >= 0 && divElement?.scrollLeft <= 60) {
      setPushingHalfPaintTableData(() => {
        pushingHalfPaintTableData.show =
          PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA;
        return pushingHalfPaintTableData;
      });
    } else if (
      divElement.scrollLeft >= columnWidth - 60 &&
      divElement.scrollLeft <= columnWidth + 60
    ) {
      setPushingHalfPaintTableData(() => {
        pushingHalfPaintTableData.show =
          PushingHalfPaintTableTabs.COURT_AND_TRANSITION_DATA;
        return pushingHalfPaintTableData;
      });
    } else if (
      divElement.scrollLeft >= columnWidth * 2 - 60 &&
      divElement.scrollLeft <= columnWidth * 2 + 60
    ) {
      setPushingHalfPaintTableData(() => {
        pushingHalfPaintTableData.show =
          PushingHalfPaintTableTabs.PAINT_ATTACK_DATA;
        return pushingHalfPaintTableData;
      });
    }

    const sTimeout = setTimeout(() => {
      setScrollTimeout(null);
    }, 100);

    setScrollTimeout(sTimeout);
  };

  function formatDate(date?: string) {
    if (!date) {
      return "";
    }
    return dayjs(date).format("MMMM D, YYYY");
  }

  return (
    <div ref={componentRef} className="gamDnaUI">
      <header className="header">
        <div className="game-data">{formatDate(game?.gameDate)}</div>
        <div className={`team-comparison ${game?.teamAbbr ? "" : "loading"}`}>
          <div className="team-card team-left">
            <div className="team-name">
              <h5>{game?.teamAbbr}</h5>
              <p>{game?.homeTeamAbbr === "PHX" ? "Home" : "Away"}</p>
            </div>
            <figure>
              <img
                src={`https://cdn.nba.com/logos/nba/${game?.teamId}/primary/L/logo.svg`}
              />
            </figure>
          </div>
          <div className="middle-comparison">
            <h4>{game?.teamScore}</h4>
            <span>vs</span>
            <h4>{game?.oppTeamScore}</h4>
          </div>
          <div className="team-card team-right">
            <figure>
              <img
                src={`https://cdn.nba.com/logos/nba/${game?.oppTeamId}/primary/L/logo.svg`}
              />
            </figure>
            <div className="team-name">
              <h5>{game?.oppTeamAbbr}</h5>
              <p>{game?.homeTeamAbbr === "PHX" ? "Away" : "Home"}</p>
            </div>
          </div>
        </div>
        <div className="update-data">
          {isUpdating ? (
            <div className="update-btn">
              <i className="loader sm"></i> Updating{" "}
            </div>
          ) : (
            <div className="update-btn" onClick={onUpdateButtonClick}>
              <i className="cs-icon icon-rotate"></i>
              Update
            </div>
          )}
          {updatedAt != "" ? (
            <div className="last-updated">
              <strong>Last Update :</strong> {updatedAt}
            </div>
          ) : (
            ""
          )}
        </div>
      </header>
      {!isDataFinalized && (
        <div className="warning-info">
          <strong>Data Not Finalized</strong> - Second Spectrum data has not
          been finalized. Until the data is finalized some metrics may not
          appear.
        </div>
      )}
      <div className="hide-lg legacy-mb-8">
        <BoxScore
          setSelectedBoxScoreTab={setSelectedBoxScoreTab}
          setBoxScoreTableData={setBoxScoreTableData}
          boxScoreLoading={boxScoreLoading}
          boxScoreData={boxScoreData}
          game={game}
          boxScoreTableData={boxScoreTableData}
          selectedBoxScoreTab={selectedBoxScoreTab}
        ></BoxScore>
      </div>
      <div className="game-dna-block">
        <div className="dna-block-item">
          <div className="common-card">
            <div className="card-head">
              <h4>ADVANCED BOX</h4>
            </div>
            <div className="table-container-block">
              <div className="table-ui text-center">
                <TableSkeleton
                  columnsCount={5}
                  rowsCount={20}
                  outerRowsCount={1}
                  fromTeamDNA={false}
                  isLoading={advanceBoxLoading}
                >
                  <Table
                    maxRank={
                      game?.seasonType == SeasonTypes.REGULAR_SEASON ||
                      game?.seasonType == SeasonTypes.PRE_SEASON
                        ? 30
                        : 16
                    }
                    columns={advancedBoxColumns(game?.seasonType)}
                    data={advancedBoxData.filter(
                      (item) => item.stat !== "Passes"
                    )}
                  />
                </TableSkeleton>
              </div>
            </div>
          </div>
          <div className="legacy-mb-8">
            <div className="common-card extra-result-info">
              <div className="tabbing-ui">
                <ul>
                  <li
                    className={
                      pushingHalfPaintTableData.show ==
                      PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      updatePushingHalfPaintTableShow(
                        PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA
                      )
                    }
                  >
                    <a>Pushing The Pace</a>
                  </li>
                  <li
                    className={
                      pushingHalfPaintTableData.show ==
                      PushingHalfPaintTableTabs.COURT_AND_TRANSITION_DATA
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      updatePushingHalfPaintTableShow(
                        PushingHalfPaintTableTabs.COURT_AND_TRANSITION_DATA
                      )
                    }
                  >
                    <a>Half Court vs. Transition</a>
                  </li>
                  <li
                    className={
                      pushingHalfPaintTableData.show ==
                      PushingHalfPaintTableTabs.PAINT_ATTACK_DATA
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      updatePushingHalfPaintTableShow(
                        PushingHalfPaintTableTabs.PAINT_ATTACK_DATA
                      )
                    }
                  >
                    <a>Paint Attack</a>
                  </li>
                </ul>
              </div>
              <div
                id="advance-box-tables"
                className="legacy-flex--3 legacy-gap--16 legacy-flex"
                ref={pushingHalfPaintTableRef}
                onScroll={handleScroll}
              >
                <div className="legacy-flex__item">
                  <div className="card-head">
                    <h4>PUSHING THE PACE</h4>
                  </div>
                  <div className="table-container-block">
                    <div className="table-ui text-center">
                      <TableSkeleton
                        columnsCount={3}
                        rowsCount={3}
                        outerRowsCount={1}
                        fromTeamDNA={false}
                        isLoading={advanceBoxLoading}
                      >
                        <Table
                          columns={pushingThePaceColumns}
                          data={
                            pushingHalfPaintTableData[
                              PushingHalfPaintTableTabs.PUSHING_THE_PACE_DATA
                            ]
                          }
                        />
                      </TableSkeleton>
                    </div>
                  </div>
                </div>
                <div className="legacy-flex__item">
                  <div className="card-head">
                    <h4>HALF COURT VS. TRANSITION</h4>
                  </div>
                  <div className="table-container-block">
                    <div className="ignore-first-column table-ui text-center">
                      <TableSkeleton
                        columnsCount={3}
                        rowsCount={3}
                        outerRowsCount={1}
                        fromTeamDNA={false}
                        isLoading={advanceBoxLoading}
                      >
                        <Table
                          columns={courtAndTransitionColumns}
                          data={
                            pushingHalfPaintTableData[
                              PushingHalfPaintTableTabs
                                .COURT_AND_TRANSITION_DATA
                            ]
                          }
                          upperHeading={[
                            {
                              key: game?.teamAbbr,
                              col: 4,
                              isPrimary: true,
                            },
                            {
                              key: game?.oppTeamAbbr,
                              col: 4,
                              isPrimary: false,
                            },
                          ]}
                        />
                      </TableSkeleton>
                    </div>
                  </div>
                </div>
                <div className="legacy-flex__item">
                  <div className="card-head">
                    <h4>PAINT ATTACK</h4>
                  </div>
                  <div className="table-container-block">
                    <div className="table-ui ignore-first-column text-center">
                      <TableSkeleton
                        columnsCount={3}
                        rowsCount={3}
                        outerRowsCount={1}
                        fromTeamDNA={false}
                        isLoading={advanceBoxLoading}
                      >
                        <Table
                          columns={paintAttackColumns}
                          data={
                            pushingHalfPaintTableData[
                              PushingHalfPaintTableTabs.PAINT_ATTACK_DATA
                            ]
                          }
                          upperHeading={[
                            {
                              key: game?.teamAbbr,
                              col: 4,
                              isPrimary: true,
                            },
                            {
                              key: game?.oppTeamAbbr,
                              col: 4,
                              isPrimary: false,
                            },
                          ]}
                        />
                      </TableSkeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hide-xxl legacy-mb-8">
          <BoxScore
            setSelectedBoxScoreTab={setSelectedBoxScoreTab}
            setBoxScoreTableData={setBoxScoreTableData}
            boxScoreLoading={boxScoreLoading}
            boxScoreData={boxScoreData}
            game={game}
            boxScoreTableData={boxScoreTableData}
            selectedBoxScoreTab={selectedBoxScoreTab}
          ></BoxScore>
        </div>
        <div className="common-card">
          <div className="card-head">
            <h4>PLAY TYPES</h4>
          </div>
          <div className="tabbing-ui">
            <ul>
              <li
                className={
                  selectedPlayTypesTab === PlayTypesTableTabs.OFFENSE
                    ? "active"
                    : ""
                }
              >
                <a
                  onClick={() => {
                    setSelectedPlayTypesTab(PlayTypesTableTabs.OFFENSE);
                  }}
                >
                  {PlayTypesTableTabs.OFFENSE}
                </a>
              </li>
              <li
                className={
                  selectedPlayTypesTab === PlayTypesTableTabs.DEFENSE
                    ? "active"
                    : ""
                }
              >
                <a
                  onClick={() => {
                    setSelectedPlayTypesTab(PlayTypesTableTabs.DEFENSE);
                  }}
                >
                  {PlayTypesTableTabs.DEFENSE}
                </a>
              </li>
            </ul>
          </div>
          <div className="table-container-block">
            <div className="table-ui">
              <TableSkeleton
                columnsCount={5}
                rowsCount={4}
                outerRowsCount={1}
                fromTeamDNA={false}
                isLoading={playTypeLoading}
              >
                <Table
                  columns={playTypesColumns}
                  data={playTypesTableData[selectedPlayTypesTab]}
                />
              </TableSkeleton>
            </div>
          </div>
        </div>
        <div className="common-card">
          <div className="card-head">
            <h4>CRASHERS</h4>
          </div>
          <div className="table-container-block">
            <div className="table-ui">
              <TableSkeleton
                columnsCount={7}
                rowsCount={4}
                outerRowsCount={1}
                fromTeamDNA={false}
                isLoading={crashersLoader}
              >
                <Table columns={crashersColumns} data={crashersData} />
              </TableSkeleton>
            </div>
          </div>
        </div>
        <div className="common-card">
          <div className="card-head">
            <h4>SHOOTING</h4>
          </div>
          <div className="table-container-block">
            <div className="table-ui">
              <TableSkeleton
                columnsCount={9}
                rowsCount={9}
                outerRowsCount={1}
                fromTeamDNA={false}
                isLoading={shootingLoader}
              >
                <Table
                  columns={shootingColumns}
                  data={shootingData}
                  upperHeading={[
                    { key: "", col: 1, isPrimary: true },
                    { key: game?.teamAbbr, col: 4, isPrimary: true },
                    { key: game?.oppTeamAbbr, col: 4, isPrimary: false },
                  ]}
                />
              </TableSkeleton>
            </div>
          </div>
        </div>
        <div className="common-card">
          <div className="card-head">
            <h4>FREQUENT PLAYER ACTIONS</h4>
          </div>
          <div className="tabbing-ui">
            <ul>
              <li
                className={
                  selectedFrequentPlayerAction === "ALL" ? "active" : ""
                }
                onClick={() => {
                  setSelectedFrequentPlayerAction("ALL");
                }}
              >
                <a>All</a>
              </li>
              <li
                className={
                  selectedFrequentPlayerAction === "ISOLATION" ? "active" : ""
                }
                onClick={() => {
                  setSelectedFrequentPlayerAction("ISOLATION");
                }}
              >
                <a>Isolation</a>
              </li>
              <li
                className={
                  selectedFrequentPlayerAction === "DHO" ? "active" : ""
                }
                onClick={() => {
                  setSelectedFrequentPlayerAction("DHO");
                }}
              >
                <a>DHO</a>
              </li>
              <li
                className={
                  selectedFrequentPlayerAction === "POSTUP" ? "active" : ""
                }
                onClick={() => {
                  setSelectedFrequentPlayerAction("POSTUP");
                }}
              >
                <a>Post Up</a>
              </li>
              <li
                className={
                  selectedFrequentPlayerAction === "OFFBALL_SCREEN"
                    ? "active"
                    : ""
                }
                onClick={() => {
                  setSelectedFrequentPlayerAction("OFFBALL_SCREEN");
                }}
              >
                <a>Offball Screen</a>
              </li>
              <li
                className={
                  selectedFrequentPlayerAction === "PAndR" ? "active" : ""
                }
                onClick={() => {
                  setSelectedFrequentPlayerAction("PAndR");
                }}
              >
                <a>P&R</a>
              </li>
            </ul>
          </div>
          <div className="table-container-block">
            <div className="table-ui">
              <TableSkeleton
                columnsCount={5}
                rowsCount={10}
                outerRowsCount={1}
                fromTeamDNA={false}
                isLoading={frequentPlayersActionLoading}
              >
                <Table
                  columns={frequentPlayerActionsColumns}
                  data={
                    frequentPlayerActionsData?.[selectedFrequentPlayerAction] ||
                    []
                  }
                />
              </TableSkeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { GameDnaIndex };
