export enum PlayTypesTableTabs {
  OFFENSE = "Offense",
  DEFENSE = "Defense",
}

export enum BoxScoreTableTabs {
  ALL = "all",
  FIRST_HALF = "firstHalf",
  SECOND_HALF = "secondHalf",
  OVER_TIME = "overTime",
}

export enum PushingHalfPaintTableTabs {
  PUSHING_THE_PACE_DATA = "pushingThePaceData",
  PAINT_ATTACK_DATA = "paintAttackData",
  COURT_AND_TRANSITION_DATA = "courtAndTransitionData",
}

export enum SeasonTypes {
  PRE_SEASON = "Pre Season",
  REGULAR_SEASON = "Regular Season",
  POST_SEASON = "Playoffs",
  PLAY_IN_SEASON = "Play-In Season",
}
