import { toast } from "react-toastify";

export function toastInfo(message: string) {
  toast.info(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    theme: "colored",
  });
}

export function toastSuccess(message: string) {
  toast.success(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    theme: "colored",
  });
}

export function toastError(message: string) {
  toast.error(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    theme: "colored",
  });
}
