import {
  useTable,
  Column,
  HeaderGroup,
  Row,
  Cell,
  ColumnInstance,
  useSortBy,
} from "react-table";
import "./table.scss";
import { useId, useState } from "react";
import { TableTypes, TeamDnaDataTypes } from "../../types/enums/teamDna.enums";
import NoRecordFound from "../no-record-found/no-record-found";
import { range } from "../../shared/utils/helper-functions";

interface SortInput {
  orderDir: string;
  orderBy: string;
}

interface TableData {
  columns: readonly Column<object>[];
  data: object[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleTableRowClick?: (row: any, tableType?: TableTypes) => void;
  tableType?: TableTypes;
  isDashBoard?: boolean;
  teamName?: string;
  forModal?: boolean;
  upperHeading?: {
    key: string | undefined;
    col: number | undefined;
    isPrimary?: boolean;
    className?: string;
  }[];
  freezeFirstColumn?: boolean;
  isSortable?: boolean;
  externalSorting?: boolean;
  theadClass?: string;
  trClass?: string;
  sort?: (input: SortInput) => void;
  initialSort?: { orderBy: string | null; orderDir: string };
  isTableExpanded?: boolean;
  loader?: {
    [TeamDnaDataTypes.TEAM_DNA]?: boolean;
    [TeamDnaDataTypes.PLAY_TYPES]?: boolean;
  };
  maxRank?: number;
}

const Table = ({
  columns,
  data,
  handleTableRowClick,
  tableType,
  isDashBoard = false,
  teamName,
  forModal = false,
  upperHeading,
  freezeFirstColumn = false,
  isSortable = false,
  theadClass,
  trClass,
  externalSorting = false,
  sort,
  initialSort = { orderBy: null, orderDir: "asc" },
  isTableExpanded = true,
  loader = {
    [TeamDnaDataTypes.TEAM_DNA]: false,
    [TeamDnaDataTypes.PLAY_TYPES]: false,
  },
  maxRank = 30,
}: TableData) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );

  const [sortBy, setSortBy] = useState<{
    orderBy: string | null;
    orderDir: string;
  }>(initialSort);
  const id = useId();

  const showSkeleton = (cell: Cell, row: Row, isClick = false) => {
    return (
      !(isClick ? ["title"] : ["title", "stat"]).includes(cell.column.id) &&
      ((loader[TeamDnaDataTypes.PLAY_TYPES] &&
        (row.values?.stat?.includes("EFFICIENCY") ||
          row.values?.stat.includes("VOLUME"))) ||
        (loader[TeamDnaDataTypes.TEAM_DNA] &&
          !row.values?.stat?.includes("EFFICIENCY") &&
          !row.values?.stat?.includes("VOLUME")))
    );
  };

  const getClass = (
    cell: Cell,
    row: Row,
    isDashBoard: boolean,
    forModal = false
  ) => {
    if (showSkeleton(cell, row)) {
      return `cell-skeleton`;
    }
    if (
      ["quarter"].includes(cell.column.id) ||
      row.values.quarter === "Game" ||
      row.values.crashers === "Total"
    ) {
      return "column-middle cell-center";
    }

    if (cell.column.id === "title") {
      return "heading";
    }

    if (forModal) {
      if (row.values.team === "PHX") {
        return `team-phx`;
      } else if (row.values.team === teamName) {
        return `team-${teamName?.toLowerCase()}`;
      } else if (row.values.team === "AVG") {
        return `team-avg`;
      }
    }

    const rankMargin = maxRank > 16 ? 5 : 3;
    if (isDashBoard) {
      if (cell.column.id === "rank" || cell.column.id === "value") {
        const rankValues = range(1, rankMargin).concat(
          range(maxRank - rankMargin + 1, maxRank)
        );
        if (
          typeof row.values.rank == "string" &&
          row.values.rank?.indexOf("T-") >= 0
        ) {
          const numRank = parseInt(row.values.rank.replace("T-", ""), 10);
          return rankValues.includes(numRank) ? `rank-${numRank}` : "";
        }

        if (!rankValues.includes(row.values.rank)) {
          return "";
        }

        if (row.values.rank < maxRank / 2) {
          return `rank-${row.values.rank}`;
        }

        return `rank-${row.values.rank - maxRank - 1}`;
      }
    }

    if (cell.column.id === "wouldBeRank") {
      let rank = row.values?.wouldBeRank;
      if (typeof rank == "string" && rank?.indexOf("T-") >= 0) {
        rank = parseInt(rank?.replace("T-", ""), 10);
      }

      if (rank >= 1 && rank <= rankMargin) {
        return `rank-${rank}`;
      } else if (rank > maxRank - rankMargin && rank <= maxRank) {
        return `rank-${rank - maxRank - 1}`;
      }
    }

    if (cell.column.id === "seasonRank") {
      let rank = row.values?.seasonRank;
      if (typeof rank == "string" && rank?.indexOf("T-") >= 0) {
        rank = parseInt(rank?.replace("T-", ""), 10);
      }
      if (rank >= 1 && rank <= rankMargin) {
        return `rank-${rank}`;
      } else if (rank > maxRank - rankMargin && rank <= maxRank) {
        return `rank-${rank - maxRank - 1}`;
      }
    }

    return "";
  };

  const handleSortClick = (column: ColumnInstance) => {
    if (isSortable) {
      if (externalSorting && sort) {
        if (column.id === "Players") {
          return;
        }
        sort({
          orderBy: column.id,
          orderDir:
            initialSort.orderBy && initialSort.orderBy == column.id
              ? initialSort.orderDir == "asc"
                ? "desc"
                : "asc"
              : "desc",
        });
      } else {
        setSortBy({
          orderBy: column.id,
          orderDir:
            sortBy.orderBy && sortBy.orderBy == column.id
              ? sortBy.orderDir == "asc"
                ? "desc"
                : "asc"
              : "desc",
        });
        if (initialSort.orderBy && initialSort.orderBy == column.id) {
          column.toggleSortBy(initialSort.orderDir == "asc");
        } else {
          column.toggleSortBy(!column.isSortedDesc);
        }
        initialSort.orderBy = null;
      }
    }
  };

  return (
    <>
      {data.length ? (
        <div className="table-ui">
          <table
            className={freezeFirstColumn ? "freeze-start" : ""}
            {...getTableProps()}
            cellPadding={0}
            cellSpacing={0}
          >
            <thead>
              {upperHeading && (
                <tr
                  className={theadClass ? theadClass : "compare-table"}
                  key={id}
                >
                  {upperHeading.map(
                    (
                      header: {
                        key: string | undefined;
                        col: number | undefined;
                        isPrimary?: boolean;
                        className?: string;
                      },
                      index
                    ) => {
                      const key = `${id}-${index}`;
                      if (header.key?.length) {
                        return (
                          <th
                            key={key}
                            className={
                              header?.className
                                ? header.className
                                : header.isPrimary
                                  ? "item-primary"
                                  : "item-secondary"
                            }
                            colSpan={header.col}
                          >
                            {header.key}
                          </th>
                        );
                      }
                      return (
                        <th key={key} className="" colSpan={header.col}></th>
                      );
                    }
                  )}
                </tr>
              )}
              {headerGroups.map((headerGroup: HeaderGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className={trClass}>
                  {headerGroup.headers.map((column: ColumnInstance) => {
                    if (column.id !== "title") {
                      if (isSortable) {
                        return (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps({
                                onClick: () => handleSortClick(column),
                              })
                            )}
                            key={column.id}
                            colSpan={
                              ["quarter", "TYPE"].includes(column.id) ? 2 : 0
                            }
                            className={
                              ["quarter"].includes(column.id)
                                ? "column-middle cell-center"
                                : sortBy.orderBy && column.id == sortBy.orderBy
                                  ? sortBy.orderDir == "desc"
                                    ? "sortable sort-desc"
                                    : "sortable sort-asc"
                                  : "sortable"
                            }
                          >
                            <div className="th">{column.render("Header")}</div>
                          </th>
                        );
                      } else {
                        return (
                          <th
                            key={column.id}
                            colSpan={
                              ["quarter", "TYPE"].includes(column.id) ? 2 : 0
                            }
                            className={
                              ["quarter"].includes(column.id)
                                ? "column-middle cell-center"
                                : ""
                            }
                          >
                            {column.render("Header")}
                          </th>
                        );
                      }
                    }
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: Row) => {
                prepareRow(row);
                return (
                  (isTableExpanded || row.cells[0].value === "Total") && (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell: Cell) => {
                        if (cell.value) {
                          return (
                            <td
                              {...cell.getCellProps()}
                              colSpan={
                                ["quarter", "TYPE"].includes(cell.column.id)
                                  ? 2
                                  : cell.column.id === "title"
                                    ? row.cells.length - 1
                                    : 0
                              }
                              className={getClass(
                                cell,
                                row,
                                isDashBoard,
                                forModal
                              )}
                              onClick={() => {
                                if (
                                  cell.column.id == "title" ||
                                  showSkeleton(cell, row, true) ||
                                  handleTableRowClick == undefined
                                ) {
                                  return;
                                }

                                handleTableRowClick(row, tableType);
                              }}
                            >
                              {showSkeleton(cell, row) ? (
                                <span className="react-loading-skeleton"></span>
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <NoRecordFound />
      )}
    </>
  );
};

export default Table;
