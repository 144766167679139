import { GameHero, NavigateLogin, TeamLogo } from "@/components";
import { StatName } from "@/shared/const";
import { useAsync, useInterval } from "@/shared/hooks";
import { getApolloLeagueIdFromNbaGameId } from "@/shared/utils";
import {
  Alert,
  Button,
  Card,
  Flex,
  Grid,
  Skeleton,
  SkeletonHeading,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
} from "@suns/design-system";
import { Info } from "@suns/design-system/icons";
import { useNavigate, useParams } from "react-router-dom";
import { boxScoreLoader } from "./loaders/boxscore-loader";
import { GameHeroSkeleton } from "@/components/GameHero/GameHero";
import { secondSpectrumLoader } from "./loaders/second-spectrum-loader";
import { useNotify } from "@/components/bugsnag";
import { PageError } from "@/components/PageError/PageError";
import { ShotsResponse } from "@suns/api/generated-client/apollo";
import { SunsApiError } from "@suns/api";
import { toastError } from "@/shared/utils/toast-messages";

export function GameDnaLive() {
  const { gameId } = useParams();
  const navigate = useNavigate();
  const notify = useNotify();

  const {
    loading: boxScoreLoading,
    response: boxScoreResponse,
    error: boxScoreError,
    refresh: boxScoreRefresh,
  } = useAsync(boxScoreLoader, {
    gameId,
  });
  useInterval(async () => {
    if (boxScoreLoading || boxScoreResponse?.game.gameStatus !== 2) {
      return;
    }
    boxScoreRefresh();
  }, 8000);

  const {
    loading: secondSpectrumLoading,
    response: secondSpectrumResponse,
    error: secondSpectrumError,
    refresh: secondSpectrumRefresh,
  } = useAsync(secondSpectrumLoader, {
    nbaId: gameId,
  });
  const secondSpectrumUnavailable =
    !secondSpectrumResponse && Boolean(secondSpectrumError);
  useInterval(async () => {
    if (
      secondSpectrumUnavailable ||
      secondSpectrumLoading ||
      boxScoreResponse?.game.gameStatus !== 2
    ) {
      return;
    }

    secondSpectrumRefresh();
  }, 15000);

  if (!gameId) {
    notify("A game ID is required to view this page.");
    navigate("/games");
    return null;
  }

  if (boxScoreError && !boxScoreResponse) {
    return (
      <PageError
        title="Error with NBA stream"
        message="There was an issue when calling the NBA for data. We've been alerted of the issue. In the meantime, you can try refreshing the page."
        error={boxScoreError}
        refresh
      />
    );
  }

  if (
    secondSpectrumError instanceof SunsApiError &&
    secondSpectrumError.authError
  ) {
    toastError("Please log back in to see game data.");
    return <NavigateLogin />;
  } else if (secondSpectrumError) {
    notify(
      Error("There was an error retrieving the second spectrum stream.", {
        cause: secondSpectrumError,
      })
    );
  }

  if (!boxScoreResponse) {
    return <GameDnaLiveLoading />;
  }

  if (!gameId) {
    throw Error(
      "We couldn't detect a gameID when retrieving the box score stream."
    );
  }

  const { game, team, oppTeam, homeTeam, awayTeam } = boxScoreResponse;

  const ssTeam =
    secondSpectrumResponse?.teamA.teamId == team?.id
      ? secondSpectrumResponse?.teamA
      : secondSpectrumResponse?.teamB;

  const fourFactorTable = [
    [
      padDecimals(awayTeam?.advancedBox.offRating) || "--",
      StatName.ORtg,
      padDecimals(homeTeam?.advancedBox.offRating) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.efgPct) || "--",
      StatName.EFGPct,
      percent(homeTeam?.advancedBox.efgPct) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.tovPct) || "--",
      StatName.TOVPct,
      percent(homeTeam?.advancedBox.tovPct) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.orbPct) || "--",
      StatName.ORBPct,
      percent(homeTeam?.advancedBox.orbPct) || "--",
    ],
    [
      percent(awayTeam?.advancedBox.ftaRate) || "--",
      StatName.FTARate,
      percent(homeTeam?.advancedBox.ftaRate) || "--",
    ],
  ] satisfies [string | number, StatName, string | number][];

  const advancedBoxTable = [
    [
      StatName.NRtg,
      padDecimals(team?.advancedBox.netRating) || "--",
      padDecimals(team?.season?.netRating) || "--",
    ],
    [
      StatName.EFGPct,
      percent(team?.advancedBox.efgPct) || "--",
      percent(team?.season?.efgPct) || "--",
    ],
    [
      StatName.OppEFGPct,
      percent(oppTeam?.advancedBox.efgPct) || "--",
      percent(team?.season?.oppEfgPct) || "--",
    ],
    [
      StatName.TOVPct,
      percent(team?.advancedBox.tovPct) || "--",
      percent(team?.season?.tmTovPct) || "--",
    ],
    [
      StatName.OppTOVPct,
      percent(oppTeam?.advancedBox.tovPct) || "--",
      percent(team?.season?.oppTovPct) || "--",
    ],
    [
      StatName.ORBPct,
      percent(team?.advancedBox.orbPct) || "--",
      percent(team?.season?.orebPct) || "--",
    ],
    [
      StatName.DRBPct,
      percent(team?.advancedBox.drbPct) || "--",
      percent(team?.season?.drebPct) || "--",
    ],
    [
      StatName.PITP,
      team?.boxScore.pointsInThePaint || 0,
      team?.season?.ptsPaint || "--",
    ],
    [
      StatName.OppPITP,
      oppTeam?.boxScore.pointsInThePaint || 0,
      team?.season?.oppPtsPaint || "--",
    ],
  ] satisfies [StatName, number | string, number | string][];

  return (
    <>
      <Card className="mb-4 overflow-hidden">
        <div className="absolute -bottom-16 -right-16 hidden w-1/3 max-w-[400px] lg:block">
          <div className="absolute h-full w-full bg-gradient-to-r from-white to-white/75" />
          <TeamLogo
            teamId={game.homeTeam!.teamId!}
            className="w-full"
            leagueId={getApolloLeagueIdFromNbaGameId(gameId)}
          />
        </div>
        <div className="absolute -bottom-16 -left-16 hidden w-1/3 max-w-[400px] lg:block">
          <div className="absolute h-full w-full bg-gradient-to-l from-white to-white/75" />
          <TeamLogo
            teamId={game.awayTeam!.teamId!}
            className="w-full"
            leagueId={getApolloLeagueIdFromNbaGameId(gameId)}
          />
        </div>
        <GameHero
          leagueId={getApolloLeagueIdFromNbaGameId(gameId)}
          gameStatus={game.gameStatus || 1}
          gameTimeUTC={game.gameTimeUTC!}
          gameClock={game.gameClock!}
          period={game.period!}
          homeTeam={{
            id: game.homeTeam!.teamId!,
            tricode: game.homeTeam!.teamTricode!,
            score: game.homeTeam!.score!,
          }}
          awayTeam={{
            id: game.awayTeam!.teamId!,
            tricode: game.awayTeam!.teamTricode!,
            score: game.awayTeam!.score!,
          }}
        />
        <Table className="mx-auto mt-3 max-w-[600px]">
          <TableCaption>
            This table displays the Four Factors, a proven identifier for
            winning games.
            <Button size="xs" variant="outline" className="ml-1" asChild>
              <a
                href="https://www.basketball-reference.com/about/factors.html"
                target="_blank"
              >
                Learn more.
              </a>
            </Button>
          </TableCaption>
          <TableBody>
            {fourFactorTable.map(([away, stat, home], idx) => (
              <TableRow
                key={`four-factors-${idx}`}
                className="text-md"
                centerAll
              >
                <TableCell>{away}</TableCell>
                <TableCell className="w-24 font-bold">{stat}</TableCell>
                <TableCell>{home}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>

      {game.gameStatus !== 1 && (
        <Grid gap="md" columns="lg:2" align="start" className="max-lg:block">
          <Card className="max-lg:mb-4">
            <Text size="xl" heading className="mb-2">
              Advanced Box
            </Text>
            <Table>
              <TableHeader>
                <TableRow>
                  {["Stat", "Value", "Team Avg."].map((heading, idx) => (
                    <TableHead key={`advanced-box-heading-${idx}`}>
                      {heading}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {advancedBoxTable.map(([stat, value, avg], idx) => (
                  <TableRow key={`advanced-boxscore-${idx}`}>
                    <TableCell>{stat}</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell>{avg}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
          <Flex direction="down" gap="md">
            <Card>
              <Flex align="center" justify="between" className="mb-2">
                <Text size="xl" heading>
                  Crashers
                </Text>
                {secondSpectrumResponse && !secondSpectrumResponse.gameOver ? (
                  <Text size="sm" muted className="italic">
                    Updated at {secondSpectrumResponse.formattedClock}
                  </Text>
                ) : null}
              </Flex>
              {secondSpectrumUnavailable ? (
                <NoTrackingAlert />
              ) : !ssTeam ? (
                <TableLoading />
              ) : (
                <Table>
                  <TableHeader>
                    <TableRow>
                      {[
                        "",
                        "# of times",
                        "% of time",
                        StatName.ORB,
                        StatName.ORBPct,
                        StatName.PPP,
                      ].map((heading, idx) => (
                        <TableHead key={`crasher-heading-${idx}`}>
                          {heading}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {ssTeam.crashers.map((crasher, idx) => (
                      <TableRow key={`crasher-${idx}`}>
                        <TableCell className="font-bold">
                          {crasher.crashers}{" "}
                          <span className="hidden md:inline">Crashed</span>
                        </TableCell>
                        <TableCell>{crasher.shots}</TableCell>
                        <TableCell>{percent(crasher.shotsPct)}</TableCell>
                        <TableCell>{crasher.oreb}</TableCell>
                        <TableCell>{percent(crasher.orebPct)}</TableCell>
                        <TableCell>{padDecimals(crasher.ppp, 2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Card>

            <Card>
              <Flex align="center" justify="between" className="mb-2">
                <Text size="xl" heading>
                  Shooting
                </Text>
                {secondSpectrumResponse && !secondSpectrumResponse.gameOver ? (
                  <Text size="sm" muted className="italic">
                    Updated at {secondSpectrumResponse.formattedClock}
                  </Text>
                ) : null}
              </Flex>
              {secondSpectrumUnavailable ? (
                <NoTrackingAlert />
              ) : !ssTeam ? (
                <TableLoading />
              ) : (
                <Table>
                  <TableHeader>
                    <TableRow>
                      {[
                        "Location",
                        StatName.FGM,
                        "Shot share",
                        StatName.EFGPct,
                        StatName.QSP,
                        StatName.QSM,
                        StatName.PPP,
                      ].map((heading, idx) => (
                        <TableHead key={`crasher-heading-${idx}`}>
                          {heading}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {ssTeam.shooting.map((shooting, idx) => (
                      <TableRow key={`shooting-${idx}`}>
                        <TableCell className="font-bold">
                          {shootingLocations[shooting.location]}
                        </TableCell>
                        <TableCell>
                          {shooting.fgm}/{shooting.fga}
                        </TableCell>
                        <TableCell>{percent(shooting.shotsPct)}</TableCell>
                        <TableCell>{percent(shooting.efgPct)}</TableCell>
                        <TableCell>{padDecimals(shooting.qsp, 1)}</TableCell>
                        <TableCell>{padDecimals(shooting.qsm, 1)}</TableCell>
                        <TableCell>{padDecimals(shooting.ppp, 2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Card>

            <Card>
              <Text size="xl" heading className="mb-2">
                Start types
              </Text>
              <Alert
                title="Coming soon"
                description="We're currently working on integrating real-time data from Second Spectrum."
                variant="info"
                Icon={Info}
              />
            </Card>
          </Flex>
        </Grid>
      )}
    </>
  );
}

function GameDnaLiveLoading() {
  return (
    <>
      <Card className="mb-4">
        <GameHeroSkeleton />
        <Table className="mx-auto mt-3 max-w-[600px]">
          <TableBody>
            {[1, 2, 3, 4].map((idx) => (
              <TableRow key={`loading-${idx}`} className="text-md" centerAll>
                <TableCell>
                  <SkeletonHeading className="mx-auto" />
                </TableCell>
                <TableCell className="w-24 font-bold">
                  <SkeletonHeading className="mx-auto" />
                </TableCell>
                <TableCell>
                  <SkeletonHeading className="mx-auto" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <Grid gap="md" columns="2" align="start">
        <Card>
          <Skeleton className="h-[60px] w-full" />
        </Card>
        <Card>
          <Skeleton className="h-[60px] w-full" />
        </Card>
      </Grid>
    </>
  );
}

const TableLoading = () => (
  <Flex direction="down" gap="sm">
    <Skeleton className="h-10" />
    <Grid columns="3" gap="sm">
      <Skeleton className="h-6" />
      <Skeleton className="h-6" />
      <Skeleton className="h-6" />
    </Grid>
    <Skeleton className="h-6" />
    <Grid columns="3" gap="sm">
      <Skeleton className="h-6" />
      <Skeleton className="h-6" />
      <Skeleton className="h-6" />
    </Grid>
  </Flex>
);

const NoTrackingAlert = () => {
  return (
    <Alert
      title="No tracking data"
      description="Second Spectrum doesn't have tracking for this game at this time."
      variant="info"
      Icon={Info}
    />
  );
};

function padDecimals(input: number | null | undefined, decimals: number = 1) {
  if (input == undefined || input == null || isNaN(input)) {
    return "--";
  }
  return input.toFixed(decimals);
}

function percent(input: number | null | undefined) {
  if (input == undefined || input == null || isNaN(input)) {
    return "--";
  }

  if (input > 1) {
    return `${input.toFixed(1)}%`;
  }

  const percent = Math.round(input * 1000) / 10;
  return `${percent.toFixed(1)}%`;
}

const shootingLocations = {
  [ShotsResponse.location.ALL_SHOTS]: "All Shots",
  [ShotsResponse.location.RIM]: "Rim",
  [ShotsResponse.location.NON_RIM_PAINT]: "Non-Rim Paint",
  [ShotsResponse.location.MIDRANGE]: "Midrange",
  [ShotsResponse.location.ALL_3S]: "All 3s",
  [ShotsResponse.location.CORNER_3]: "Corner 3",
  [ShotsResponse.location.ABOVE_THE_BREAK_3]: "Above the Break 3",
  [ShotsResponse.location.C_AND_S_3]: "Catch and Shoot 3",
  [ShotsResponse.location.OFF_DRIBBLE_3]: "Off Dribble 3",
};
