import React from "react";
import { Flex } from "../Flex/Flex";
import { classVariants, cn, VariantParams } from "../../utils";

export function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("animate-pulse rounded-md bg-muted", className)}
      {...props}
    />
  );
}

const skeletonHeadingVariants = classVariants([], {
  variants: {
    size: {
      md: "h-5 w-20",
      lg: "h-6 w-24",
      xl: "h-7 w-28",
      "2xl": "h-8 w-28",
      "3xl": "h-9 w-28",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export interface SkeletonHeadingProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantParams<typeof skeletonHeadingVariants> {}

export function SkeletonHeading({
  size,
  className,
  ...props
}: SkeletonHeadingProps) {
  return (
    <Skeleton
      className={cn(
        skeletonHeadingVariants({
          size,
        }),
        className
      )}
      {...props}
    />
  );
}

export function SkeletonThumbnail({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Skeleton className={cn("h-16 w-16 rounded-full", className)} {...props} />
  );
}

interface SkeletonTextProps extends React.HTMLAttributes<HTMLDivElement> {
  rows?: number;
}
export function SkeletonText({ rows = 3, ...props }: SkeletonTextProps) {
  return (
    <Flex direction="down" gap="sm" {...props}>
      {[...Array(rows).keys()].map((i) => (
        <Skeleton
          key={`sk-${i}`}
          className={cn("h-3", i == rows - 1 ? "w-3/4" : "w-full")}
        />
      ))}
    </Flex>
  );
}
