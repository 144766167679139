import { apolloApi } from "@/shared/api";
import { ReportQueryParams } from "@/swagger";

export async function createPlayerReportLoader({
  playerId,
  authorUsername,
}: {
  playerId: string;
  authorUsername?: string[];
}) {
  const playerIdNumber = Number(playerId);
  const reportRequest = authorUsername
    ? apolloApi.getReports({
        playerId: playerId,
        authorUsername,
        limit: 1,
        status: ReportQueryParams.status.UNPUBLISHED,
      })
    : null;
  const playerRequet = apolloApi.getPlayer(playerIdNumber);

  const [reportResponse, playerResponse] = await Promise.all([
    reportRequest,
    playerRequet,
  ]);

  const report = reportResponse?.reports[0] ?? null;
  const player = playerResponse.player;
  const team = player.currentTeam;

  return { report, player, team };
}
