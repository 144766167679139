import { apolloApi, getGame } from "@/shared/api";
import { GameResponse, TeamsResponse } from "@/swagger";
import { ApolloLeagueId } from "@suns/api";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { ScoreboardGame } from "@suns/api/generated-client/stats";

export interface ReportGame {
  leagueId?: ApolloLeagueId;
  gameId?: string;
  gameClock?: string;
  gameStatus?: number;
  gameTimeUTC?: string;
  gameVendor: ReportResponseItem.gameVendor;
  period?: number;
  homeTeam: {
    id?: number;
    image?: string;
    tricode?: string;
    score?: number;
    teamName?: string;
  };
  awayTeam: {
    id?: number;
    image?: string;
    tricode?: string;
    score?: number;
    teamName?: string;
  };
}

export async function gameReportLoader({
  gameVendorId,
  authorUsername,
}: {
  gameVendorId?: string;
  authorUsername?: string;
}) {
  let homeTeam;
  let awayTeam;
  let scoreboardGame: ScoreboardGame;
  let game: ReportGame;
  let reportsResponse;

  const reportsRequest = authorUsername
    ? apolloApi.getReports({
        gameVendorId: gameVendorId,
        authorUsername: authorUsername ? [authorUsername] : [],
        limit: 36,
      })
    : null;

  if (gameVendorId?.startsWith("00")) {
    scoreboardGame = await getGame({ gameId: gameVendorId });

    const homeTeamRequest = apolloApi.getTeams({
      nbaId: `${scoreboardGame.homeTeam?.teamId}`,
      includePlayers: true,
    });

    const awayTeamRequest = apolloApi.getTeams({
      nbaId: `${scoreboardGame.awayTeam?.teamId}`,
      includePlayers: true,
    });

    let homeTeams: TeamsResponse;
    let awayTeams: TeamsResponse;
    [reportsResponse, homeTeams, awayTeams] = await Promise.all([
      reportsRequest,
      homeTeamRequest,
      awayTeamRequest,
    ]);

    homeTeam = homeTeams.teams[0];
    awayTeam = awayTeams.teams[0];

    game = {
      leagueId: ApolloLeagueId.NBA,
      gameId: scoreboardGame?.gameId ?? undefined,
      gameClock: scoreboardGame.gameClock ?? undefined,
      gameStatus: scoreboardGame.gameStatus || 1,
      gameTimeUTC: scoreboardGame.gameTimeUTC,
      gameVendor: ReportResponseItem.gameVendor.NBA,
      period: scoreboardGame.period,
      homeTeam: {
        id: scoreboardGame.homeTeam!.teamId,
        tricode: scoreboardGame.homeTeam!.teamTricode ?? undefined,
        score: scoreboardGame.homeTeam!.score,
        teamName: scoreboardGame.homeTeam!.teamName ?? undefined,
      },
      awayTeam: {
        id: scoreboardGame.awayTeam?.teamId,
        tricode: scoreboardGame.awayTeam?.teamTricode ?? undefined,
        score: scoreboardGame.awayTeam?.score,
        teamName: scoreboardGame.awayTeam?.teamName ?? undefined,
      },
    };
  } else {
    const gameRequest = apolloApi.getGameById(Number(gameVendorId));
    let gameResponse: GameResponse | null = null;
    [reportsResponse, gameResponse] = await Promise.all([
      reportsRequest,
      gameRequest,
    ]);

    homeTeam = (await gameRequest).game.homeTeam;
    awayTeam = (await gameRequest).game.awayTeam;
    game = {
      gameId: `${gameResponse.game.id}`,
      gameTimeUTC: gameResponse.game.gameTimeUTC ?? undefined,
      gameVendor: ReportResponseItem.gameVendor.APOLLO,
      homeTeam: {
        image: homeTeam?.image ?? undefined,
        tricode: gameResponse.game.homeTeam?.code ?? undefined,
        teamName: gameResponse.game.homeTeam?.fullName ?? undefined,
      },
      awayTeam: {
        image: awayTeam?.image ?? undefined,
        tricode: gameResponse.game.awayTeam?.code ?? undefined,
        teamName: gameResponse.game.awayTeam?.fullName ?? undefined,
      },
    };
  }

  const reports =
    reportsResponse?.reports?.reduce(
      (acc, report) => {
        acc[`${report.playerId}`] = report;
        return acc;
      },
      {} as Record<string, ReportResponseItem>
    ) ?? {};

  return { reports, game, homeTeam, awayTeam };
}
