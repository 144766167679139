import { redirect } from "react-router-dom";

export function redirectToLogin(request?: Request) {
  let params: URLSearchParams | null = null;
  if (request) {
    params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
  }

  return redirect("/login" + (params ? `?${params.toString()}` : ""));
}
