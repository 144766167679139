import { apolloApi } from "@/shared/api";
import { useAsync } from "@/shared/hooks";
import { ApolloLeagueId } from "@suns/api/src/params";
import { TeamRow } from "@suns/api/generated-client/apollo";

export type TeamCacheItem = {
  id: number;
  name: string;
  searchKey: string;
  teamCode: string;
};

const cachedTeamList = localStorage.getItem("teamListCache") ?? "";

const createSearchKey = (team: TeamRow) => {
  const searchKey = team.fullName ?? team.name;
  return searchKey.normalize("NFD").replace(/\p{Diacritic}/gu, "");
};

export function useTeamList() {
  return useAsync(async () => {
    const now = new Date().getTime();

    if (cachedTeamList) {
      const cachedDate = localStorage.getItem("teamListCacheDate");
      const sevenDays = 7 * 24 * 60 * 60 * 1000;

      if (cachedDate && now - parseInt(cachedDate) > sevenDays) {
        localStorage.removeItem("teamListCache");
        localStorage.removeItem("teamListCacheDate");
        return refresh();
      }
      return JSON.parse(cachedTeamList) as TeamCacheItem[];
    }

    return refresh();
  });
}

async function refresh(): Promise<TeamCacheItem[]> {
  const now = new Date().getTime();

  const response = await apolloApi.getTeams({});
  const teams = response.teams
    .filter((team) => team.domesticLeagueId !== ApolloLeagueId.WNBA)
    .map((team) => {
      return {
        id: team.id,
        name: team.fullName ?? team.name,
        searchKey: createSearchKey(team),
        teamCode: team.code ?? team.countryCode,
      };
    });

  localStorage.setItem("teamListCache", JSON.stringify(teams));
  localStorage.setItem("teamListCacheDate", now.toString());

  return cachedTeamList
    ? JSON.parse(cachedTeamList)
    : (teams as TeamCacheItem[]);
}
