import { glossaryList } from "@/shared/const";
import { Card, Flex, Text, cn } from "@suns/design-system";
import { useState } from "react";

interface GlossaryItem {
  id: number;
  stat: string;
  definition: string;
}

const alphabetArray = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode(97 + i)
);

const combineArray = ["+", "2", "3", ...alphabetArray];

export function Glossary() {
  const [selectedLetter, setSelectedLetter] = useState("");

  const handleLetterClick = (letter: string) => {
    if (
      glossaryList.some((item) => item.stat.toLowerCase().startsWith(letter))
    ) {
      setSelectedLetter(letter);
    }

    const elementTop =
      document.getElementById(`glossary-${letter}`)?.getBoundingClientRect()
        ?.top || 0;
    const scrollY = elementTop + window.scrollY - 100;
    window.scrollTo({ top: scrollY, behavior: "smooth" });
  };

  return (
    <Card>
      <Text heading size="3xl" className="mb-2">
        Glossary
      </Text>
      <Flex align="start" justify="between" gap="md">
        <Flex
          direction="down"
          align="center"
          justify="between"
          className="sticky top-16 h-screen py-2 pb-16 lg:top-0 lg:pb-0"
        >
          {combineArray.map((letter) => (
            <div key={letter}>
              <Text
                heading
                size="xs"
                className={cn(
                  "mb-2 cursor-pointer hover:text-primary",
                  glossaryList.some((item) =>
                    item.stat.toLowerCase().startsWith(letter)
                  )
                    ? "text-black"
                    : "text-gray-300",
                  letter === selectedLetter && "text-primary"
                )}
                onClick={() => handleLetterClick(letter.toLowerCase())}
              >
                {letter.toUpperCase()}
              </Text>
            </div>
          ))}
        </Flex>
        <Flex direction="down" gap="md">
          {[...glossaryList]
            .sort((a, b) => a.stat.localeCompare(b.stat))
            .map((item: GlossaryItem) => (
              <Flex
                key={item.id}
                id={`glossary-${item.stat.charAt(0).toLowerCase()}`}
                direction="down"
                className={cn(
                  "p-2",
                  selectedLetter &&
                    item.stat.toLowerCase().startsWith(selectedLetter)
                    ? "rounded bg-primary/5"
                    : ""
                )}
              >
                <Text heading size="sm">
                  {item.stat}
                </Text>
                <Text size="sm" className="p1">
                  {item.definition}
                </Text>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </Card>
  );
}
