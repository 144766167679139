import { Link } from "react-router-dom";
import { Card, Flex, Text } from "@suns/design-system";

export default function LabsCard({
  slug,
  subTitle,
  title,
  description,
}: {
  slug: string;
  subTitle: string;
  title: string;
  description: string;
}) {
  return (
    <Flex direction="down" align="start">
      <Link to={`/labs/${slug}`} className="w-full">
        <Card className="h-64 hover:bg-black hover:text-white">
          <Flex
            direction="down"
            align="center"
            justify="center"
            className="h-full"
          >
            <Text heading size="sm" className="tracking-[.7rem]">
              {subTitle}
            </Text>
            <Text
              as="strong"
              className="lg:text-md text-sm tracking-[.1rem] text-orange-500 sm:text-xs
                md:tracking-[.3rem] lg:tracking-[.4rem] xl:text-lg xl:tracking-[.5rem]
                2xl:text-xl 2xl:tracking-[.7rem]"
            >
              {title.toUpperCase()}
            </Text>
          </Flex>
        </Card>
      </Link>
      <Link to={`/labs/${slug}`}>
        <Text heading size="sm" className="pt-2 hover:text-orange-500">
          {subTitle} {title}
        </Text>
      </Link>
      <Text size="sm">{description}</Text>
    </Flex>
  );
}
