import { getSchedule } from "@/shared/api";
import { LeagueId } from "@suns/api";
import dayjs from "dayjs";

interface Team {
  id: string;
  abbr: string;
  name: string;
}

type Schedule = Awaited<ReturnType<typeof getSchedule>>;

export interface Game {
  gameId: string;
  homeTeamId: number;
  homeTeamAbbr: string;
  homeTeamScore: number;
  awayTeamId: number;
  awayTeamAbbr: string;
  awayTeamScore: number;
  gameWinnerTeamId: number;
  gameDateTimeUtc: string;
  gameNumber?: number;
}

export async function loader(teamId: number) {
  const schedule = await getSchedule({
    teamId,
    season: "2024-25",
    leagueId: LeagueId.NBA,
  });
  return {
    games: formatGames(schedule),
    teams: formatTeams(schedule, teamId),
  };
}

function formatGames(schedule: Schedule) {
  const yesterday = dayjs().subtract(1, "day").endOf("day");

  if (!schedule.gameDates) {
    return { nextGame: undefined, upcomingGames: [], pastGames: [] };
  }

  const { nextGame, upcomingGames, pastGames } = schedule.gameDates.reduce<{
    nextGame?: Game;
    upcomingGames: Game[];
    pastGames: Game[];
  }>(
    ({ nextGame, upcomingGames, pastGames }, gameDate) => {
      const gameData = gameDate?.games?.[0];

      if (!gameData?.homeTeam || !gameData?.awayTeam) {
        return { nextGame, upcomingGames, pastGames };
      }

      const game = {
        gameId: gameData.gameId!,
        homeTeamId: gameData.homeTeam.teamId!,
        homeTeamAbbr: gameData.homeTeam.teamTricode!,
        homeTeamScore: gameData.homeTeam.score!,
        awayTeamId: gameData.awayTeam.teamId!,
        awayTeamAbbr: gameData.awayTeam.teamTricode!,
        awayTeamScore: gameData.awayTeam.score!,
        gameWinnerTeamId:
          gameData.homeTeam.score! > gameData.awayTeam.score!
            ? gameData.homeTeam.teamId!
            : gameData.awayTeam.teamId!,
        gameDateTimeUtc: gameData.gameDateTimeUTC!,
        gameNumber:
          gameData.seasonType == "Playoffs" ? gameData.gameSequence : undefined,
      } satisfies Game;

      const gameTime = dayjs(game.gameDateTimeUtc);

      if (!nextGame && gameTime.isAfter(yesterday)) {
        nextGame = game;
        return { nextGame, upcomingGames, pastGames };
      }

      if (gameTime.isBefore(yesterday)) {
        pastGames.push(game);
        return { nextGame, upcomingGames, pastGames };
      }

      upcomingGames.push(game);

      return { nextGame, upcomingGames, pastGames };
    },
    {
      upcomingGames: [],
      pastGames: [],
    }
  );

  return { nextGame, upcomingGames, pastGames };
}

function formatTeams(schedule: Schedule, teamId: number) {
  if (!schedule.gameDates) {
    return [];
  }

  const teams = schedule.gameDates.reduce<Map<string, Team>>(
    (teams, gameDate) => {
      const game = gameDate?.games?.[0];
      if (!game) {
        return teams;
      }

      const oppTeamData =
        teamId == game.homeTeam?.teamId ? game.awayTeam : game.homeTeam;

      if (
        !oppTeamData?.teamId ||
        !oppTeamData?.teamTricode ||
        !oppTeamData?.teamName
      ) {
        return teams;
      }

      const team = {
        id: oppTeamData.teamId.toString(),
        abbr: oppTeamData.teamTricode,
        name: oppTeamData.teamName,
      };

      if (teams.has(team.id)) {
        return teams;
      } else {
        teams.set(team.id, team);
      }

      return teams;
    },
    new Map()
  );

  return Array.from(teams.values()).sort((teamA, teamB) =>
    teamA.name.localeCompare(teamB.name)
  );
}
