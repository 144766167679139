/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReboundingPlayerResponse } from '../models/ReboundingPlayerResponse';
import type { ReboundingResponse } from '../models/ReboundingResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReboundingService {
    /**
     * @param season
     * @param teamAbbr
     * @param seasonTypes
     * @param noOfGames
     * @returns ReboundingResponse Success.
     * @throws ApiError
     */
    public static getTeamReboundingStatsData(
        season: string,
        teamAbbr: string,
        seasonTypes: Array<string>,
        noOfGames?: number,
    ): CancelablePromise<ReboundingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rebounding/team',
            query: {
                'season': season,
                'teamAbbr': teamAbbr,
                'noOfGames': noOfGames,
                'seasonTypes': seasonTypes,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * @param season
     * @param seasonTypes
     * @param teamId
     * @param noOfGames
     * @returns ReboundingPlayerResponse Success.
     * @throws ApiError
     */
    public static getPlayerReboundingStatsData(
        season: string,
        seasonTypes: Array<string>,
        teamId: number,
        noOfGames?: number,
    ): CancelablePromise<ReboundingPlayerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rebounding/player-stats',
            query: {
                'season': season,
                'seasonTypes': seasonTypes,
                'teamId': teamId,
                'noOfGames': noOfGames,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
}
