import { Button, Container, Flex, Text } from "@suns/design-system";
import { CircleAlert } from "@suns/design-system/icons";
import { useNotify } from "../bugsnag";

interface PageErrorProps {
  title: string;
  message: string;
  error?: Error;
  refresh?: boolean;
}

export function PageError({
  title,
  message,
  error,
  refresh = false,
}: PageErrorProps) {
  const notify = useNotify();

  if (error) {
    notify(error);
  }

  return (
    <Container size="sm" className="pt-4">
      <Flex direction="down" align="center" gap="md">
        <Flex align="center" gap="md">
          <CircleAlert className="size-7 text-destructive lg:size-8" />
          <Text size={["lg", "lg:2xl"]} heading>
            {title}
          </Text>
        </Flex>
        <Text className="text-center">{message}</Text>
        {refresh && (
          <Button
            onClick={() => {
              location.reload();
              return false;
            }}
          >
            Refresh
          </Button>
        )}
      </Flex>
    </Container>
  );
}
