import { apolloApi } from "@/shared/api";

export async function intelByIdLoader({ intelId }: { intelId?: number }) {
  if (!intelId) {
    return null;
  }

  const response = await apolloApi.getIntel({
    id: intelId,
  });

  return response?.intel[0] || null;
}
