import { useMemo } from "react";
import dayjs from "dayjs";
import {
  Flex,
  Skeleton,
  SkeletonHeading,
  SkeletonThumbnail,
  Text,
  Badge,
} from "@suns/design-system";
import { TeamLogo } from "@/components";
import { ApolloLeagueId } from "@suns/api";

interface Team {
  id?: number;
  image?: string;
  tricode?: string;
  score?: number;
}

export type GameHeroProps = {
  leagueId?: ApolloLeagueId;
  homeTeam?: Team;
  awayTeam?: Team;
  gameStatus?: number;
  gameClock?: string;
  gameTimeUTC?: string;
  period?: number;
};

export function GameHero(props: GameHeroProps) {
  const {
    leagueId,
    homeTeam,
    awayTeam,
    gameStatus,
    period,
    gameClock,
    gameTimeUTC,
  } = props;
  const date = useMemo(() => dayjs(gameTimeUTC || ""), [gameTimeUTC]);

  let status;
  if (!gameStatus) {
    const gameTime = dayjs(gameTimeUTC);
    const currentTime = dayjs(Date());
    const mins = currentTime.diff(gameTime, "minutes");
    if (mins < 0) {
      status = "Pregame";
    } else if (mins > 120) {
      status = "Final";
    } else {
      status = "Live";
    }
  } else {
    status = {
      1: "Pregame",
      2: "Live",
      3: "Final",
    }[gameStatus];
  }

  const { minute, second } = gameClock
    ? splitGameClock(gameClock)
    : { minute: null, second: null };

  return (
    <Flex direction="down" align="center" gap="sm">
      <Flex align="center" gap="md">
        <Text size="sm">{date.format("MMMM D, YYYY")}</Text>
        <Badge variant={status == "Live" ? "destructive" : "default"}>
          {status}
        </Badge>
      </Flex>
      {gameStatus && status == "Live" && (
        <Flex gap="sm">
          <Text size="xl">Q{period}</Text>
          <Text size="xl">
            {minute}:{second}
          </Text>
        </Flex>
      )}
      <Flex gap="md" align="center" justify="center">
        <GameHeroTeam {...awayTeam} leagueId={leagueId} />
        <Text size={["sm", "md:xl"]}>{"@"}</Text>
        <GameHeroTeam
          {...homeTeam}
          direction="left"
          isHome
          leagueId={leagueId}
        />
      </Flex>
    </Flex>
  );
}

function GameHeroTeam({
  id,
  tricode,
  score,
  image,
  isHome,
  leagueId,
  direction = "right",
}: Pick<Team, "id" | "tricode" | "score"> & {
  image?: string;
  isHome?: boolean;
  direction?: "right" | "left";
  leagueId?: ApolloLeagueId;
}) {
  return (
    <Flex direction={direction} gap="sm" align="center" wrap>
      <Flex direction="down">
        <Text size={["md", "sm:2xl"]} heading>
          {tricode}
        </Text>
        <Text size={["sm", "md:md"]}>{isHome ? "Home" : "Away"}</Text>
      </Flex>
      <TeamLogo
        image={image}
        teamId={id}
        leagueId={leagueId}
        size={["sm", "md:md"]}
      />
      {score != null ? (
        <Text size={["md", "sm:2xl"]} heading className="md:mx-md">
          {score}
        </Text>
      ) : null}
    </Flex>
  );
}

export function GameHeroSkeleton() {
  function Team({ direction = "right" }: { direction?: "right" | "left" }) {
    return (
      <Flex direction={direction} gap="md" align="center" wrap>
        <Flex direction="down" gap="sm">
          <Skeleton className="h-[30px] w-[40px]" />
          <SkeletonHeading />
        </Flex>
        <SkeletonThumbnail />
        <Skeleton className="h-[30px] w-[40px]" />
      </Flex>
    );
  }

  return (
    <Flex direction="right" gap="md" align="center" justify="center">
      <Team />
      <SkeletonHeading />
      <Team direction="left" />
    </Flex>
  );
}

function splitGameClock(gameClock: string) {
  const [minute, second, milliSecond] = gameClock
    .replace(/PT|S/, "")
    .split(/M|\./);
  return { minute, second, milliSecond };
}
