import { ReportResponseItem } from "@suns/api/generated-client/apollo/models/ReportResponseItem";
import { cn, Flex } from "@suns/design-system";
import {
  ArrowRight,
  CheckCircle,
  LoaderCircle,
} from "@suns/design-system/icons";
import {
  PlayerMetadataRow,
  PlayerRow,
} from "@suns/api/generated-client/apollo";

interface PlayerReportItemProps {
  player: PlayerRow;
  reports: Record<string, ReportResponseItem>;
  activePlayer: PlayerRow | null;
  isSaving: boolean;
}

export function PlayerReportItem({
  player,
  reports,
  activePlayer,
  isSaving,
}: PlayerReportItemProps) {
  const isActive = activePlayer?.id === player.id;
  const reportStatus = reports[player.id]?.status;

  return (
    <Flex align="center" gap="xs">
      {player.metadata?.target &&
      [
        PlayerMetadataRow.target.FREE_AGENT,
        PlayerMetadataRow.target.TRADE,
      ].includes(player.metadata?.target) ? (
        <ArrowRight
          size={18}
          className={cn(
            player.metadata.target === PlayerMetadataRow.target.FREE_AGENT
              ? "text-pink-500"
              : "text-yellow-500",
            !isActive && "opacity-30"
          )}
        />
      ) : null}
      {player.lastName}, {player.firstName}
      {isSaving ? (
        <LoaderCircle size={18} className="animate-spin" />
      ) : reportStatus === ReportResponseItem.status.PUBLISHED ? (
        <CheckCircle size={18} className="text-green-700" />
      ) : reportStatus === ReportResponseItem.status.UNPUBLISHED ? (
        <CheckCircle size={18} />
      ) : null}
    </Flex>
  );
}
