import { apolloApi } from "@/shared/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";

export async function intelLoader({
  playerId,
  teamId,
  authorUsername,
}: {
  playerId?: number;
  teamId?: number[];
  authorUsername?: string[];
}) {
  const response = await apolloApi.getIntel({
    playerId,
    teamId,
    authorUsername: authorUsername ?? undefined,
    limit: 1,
    status: IntelQueryParams.status.UNPUBLISHED,
  });

  return response?.intel[0] || null;
}
