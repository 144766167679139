import { Suspense } from "react";
import SuspenseFallback from "@/components/suspense-fallback/suspense-fallback";
import ErrorFallback from "@/components/error-fallback/error-fallback";
import { RouteObject } from "react-router-dom";
import {
  IntelCreate,
  IntelEdit,
  IntelListings,
  IntelView,
} from "@/pages/intel/index";
import { IntelCreatePlayerTeam } from "@/pages/intel/intel-create-player-team/intel-create-player-team";

export const IntelRoutes = {
  path: "reports/intel",
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelListings />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "create",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelCreate />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "create/player/:playerId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelCreatePlayerTeam />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "create/team/:teamId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelCreatePlayerTeam />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "edit/player/:playerId/:intelId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelEdit />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "edit/team/:teamId/:intelId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelEdit />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "view/player/:playerId/:intelId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelView />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: "view/team/:teamId/:intelId",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <IntelView />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
  ],
} satisfies RouteObject;

export default IntelRoutes;
