import { apolloApi } from "@/shared/api";
import { ReportQueryParams } from "@/swagger";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";

export async function allReportsLoader({
  offset = 0,
  limit = 10,
  teamId,
  playerId,
  authorUsername,
  level,
}: {
  offset?: number;
  limit?: number;
  level?: string;
  teamId?: string[];
  playerId?: string;
  authorUsername?: string[];
}) {
  const response = await apolloApi.getReports({
    offset,
    limit,
    level: level as ReportQueryParams.level,
    playerId,
    teamId,
    authorUsername,
    status: ReportResponseItem.status.PUBLISHED,
  });
  return response;
}

export async function allAuthorUsernamesLoader() {
  const response = await apolloApi.getReportAuthors();
  return response;
}
