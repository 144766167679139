import {
  Flex,
  RadioGroup,
  RadioGroupItem,
  FormControl,
  FormField,
  FormItem,
} from "@suns/design-system";
import { FormLabelWithTooltip } from "./FormLabelWithTooltip";

interface FormSkillSelectParams {
  name: string;
  label: string;
  tooltip?: string;
  allowNA?: boolean;
  max?: number;
}

export function FormSkillSelect({
  label,
  tooltip,
  name,
  allowNA = false,
  max = 5,
}: FormSkillSelectParams) {
  return (
    <Flex className="break-inside-avoid">
      <FormField
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormLabelWithTooltip
              className="text-sm"
              tooltip={tooltip}
              title={label}
            />
            <FormControl>
              <RadioGroup
                {...field}
                className="flex flex-col"
                onValueChange={field.onChange}
              >
                <Flex direction="right" gap="sm">
                  {[...Array(max).keys()].map((_, i) => (
                    <RadioGroupItem
                      key={`${name}-${i + 1}`}
                      value={`${i + 1}`}
                    />
                  ))}
                  {allowNA ? <RadioGroupItem value="NA" /> : null}
                </Flex>
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
    </Flex>
  );
}
