import {
  ApolloGradeScopeLabels,
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
} from "@/pages/reports/reports-const";
import { FormSelect } from "../FormSelect";
import { Flex, FormField, Text } from "@suns/design-system";
import { ReportUpsertParams } from "@suns/api/generated-client/apollo";

export default function PositionAndRole({ readonly = false }) {
  if (readonly) {
    return (
      <Flex direction="right" gap="lg">
        <FormField
          name="apolloGrades"
          render={({ field }) => {
            return (
              <Flex direction="right" gap="lg">
                {Object.keys(field.value).map((key) => (
                  <Flex direction="down" gap="xs" key={key}>
                    <Text size="md" muted heading>
                      {
                        ApolloGradeScopeLabels[
                          key as keyof typeof ApolloGradeScopeLabels
                        ]
                      }
                    </Text>
                    <Text size="lg" heading>
                      {field.value &&
                        ApolloGradeValueLabels[
                          field.value[
                            key
                          ] as keyof typeof ApolloGradeValueLabels
                        ]}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            );
          }}
        />

        <FormField
          name="position"
          render={({ field }) => (
            <Flex direction="down" gap="xs">
              <Text size="md" muted heading>
                Position
              </Text>
              <Text size="lg" heading>
                {field.value &&
                  ReportPositionLabels[
                    field.value as keyof typeof ReportPositionLabels
                  ]}
              </Text>
            </Flex>
          )}
        />
        <FormField
          name="role"
          render={({ field }) => (
            <Flex direction="down" gap="xs">
              <Text size="md" muted heading>
                Role
              </Text>
              <Text size="lg" heading>
                {field.value &&
                  ReportRoleLabels[
                    field.value as keyof typeof ReportRoleLabels
                  ]}
              </Text>
            </Flex>
          )}
        />
      </Flex>
    );
  }

  return (
    <Flex direction="right" gap="lg">
      <FormSelect
        title="Position"
        name="position"
        placeholder="Select an option"
        options={Object.values(ReportUpsertParams.position).map((value) => ({
          value,
          label: ReportPositionLabels[value],
        }))}
        labelClassName="text-lg font-bold"
      />

      <FormSelect
        title="Role"
        name="role"
        placeholder="Select an option"
        options={Object.values(ReportUpsertParams.role).map((value) => ({
          value,
          label: ReportRoleLabels[value],
        }))}
        labelClassName="text-lg font-bold"
      />
    </Flex>
  );
}
