import { defer } from "react-router-dom";
import { FiltersService, GameDnaService } from "../../swagger";
import { MultiValue } from "react-select";
import { MultiValuePlayersType } from "../../pages/box-scores/box-scores-types";

type GenericMultiValueType<T> = MultiValue<T> | null;

export const getFilterData = async () => {
  try {
    const teamsList: {
      label: string;
      options: { label: string; value: string; id: number }[];
    }[] = [];
    const seasonsList: { label: string; value: string }[] = [];
    const teamsResponse = await FiltersService.getTeams();
    teamsResponse.data.forEach((group) => {
      const teams: { label: string; value: string; id: number }[] = [];
      group.options.forEach(
        (team: {
          TEAM_FULL_NAME: string;
          TEAM_ABBR: string;
          TEAM_ID: number;
        }) => {
          teams.push({
            value: team.TEAM_ABBR,
            label: team.TEAM_FULL_NAME,
            id: team.TEAM_ID,
          });
        }
      );
      teamsList.push({ label: group.label, options: teams });
    });

    const seasonsResponse = await FiltersService.getSeasons();
    seasonsResponse.data.forEach((season) => {
      seasonsList.push({ value: season.SEASON, label: season.SEASON });
    });

    return defer({ teamsList, seasonsList });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error:", error);
  }
};

export const getLastMatchData = async () => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      const { data: latestMatch } = await GameDnaService.getLatestMatch();
      return defer({ latestMatch });
    }
    return null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error:", error);
  }
};

export const shortenName = (fullName: string) => {
  const words = fullName.split(" ");
  if (words.length >= 2) {
    const firstName = words[0];
    const lastName = words[words.length - 1].includes("Jr.")
      ? words.splice(-2).join(" ")
      : words[words.length - 1];
    return `${firstName[0]}.${lastName}`;
  }
  return fullName;
};

export const updateTimestamp = (updateTime = false) => {
  const storedTimestamp = localStorage.getItem("updateAt");
  if (storedTimestamp && !isNaN(parseInt(storedTimestamp, 10)) && !updateTime) {
    const storedTime = parseInt(storedTimestamp, 10);
    const currentTime = Date.now();
    const seconds = Math.floor((currentTime - storedTime) / 1000);
    if (seconds < 30) {
      return "a few seconds ago";
    }

    const intervals: { [key: string]: number } = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    for (const i in intervals) {
      const counter = Math.floor(seconds / intervals[i]);
      if (counter > 0) {
        const unit = counter === 1 ? i : `${i}s`;
        return `${counter} ${unit} ago`;
      }
    }
  } else {
    const timestamp = Date.now();
    localStorage.setItem("updateAt", timestamp.toString());
    return "a few second ago";
  }
  return "a few second ago";
};

export function dedupe<T>(a: T[]) {
  return [...new Set(a)];
}

export function range(start: number, end: number) {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
}

export function extractValues<
  T extends { value: string | number; label: string; isDisabled?: boolean },
>(items: GenericMultiValueType<T>): string[] {
  if (!items) {
    return [];
  }
  return items.map((data) => data.value.toString());
}

export const convertPlayers = (ids: string[], arr: MultiValuePlayersType) => {
  const playerIds = new Set(ids?.map((id) => parseInt(id)));
  const filteredPlayers = arr?.filter((player) => playerIds.has(player.value));
  return (
    filteredPlayers?.map((player) => ({
      value: player.value,
      label: player.label,
      isDisabled: player.isDisabled,
    })) || []
  );
};

export const removeEmptyValues = (obj: object) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, v]) => v !== null && v !== "" && v !== undefined
    )
  );
};
