import { apolloApi } from "@/shared/api";
import { useAsync } from "@/shared/hooks";
import { PlayerCacheItem } from "@suns/api/generated-client/apollo";

export function usePlayerList() {
  return useAsync(async () => {
    const hash = localStorage.getItem("playerListHash");

    if (!hash) {
      return refresh();
    }

    const response = await apolloApi.getPlayerCache({ hashOnly: true });
    const localHash = localStorage.getItem("playerListCache");
    if (hash === response.hash && localHash) {
      return JSON.parse(localHash) as PlayerCacheItem[];
    }

    return refresh();
  });
}

async function refresh(): Promise<PlayerCacheItem[]> {
  const response = await apolloApi.getPlayerCache({});
  localStorage.setItem("playerListCache", JSON.stringify(response.cache));
  localStorage.setItem("playerListHash", response.hash);
  return response.cache ?? [];
}
