import {
  Card,
  Flex,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import {
  allAuthorUsernamesLoader,
  allReportsLoader,
} from "./loaders/all-reports-loader";
import ReportListItem from "./components/ReportListItem";
import { PaginationControl } from "@/components";
import { useEffect, useState } from "react";
import { allIntelLoader } from "./loaders/all-intel-loader";
import { IntelListItem } from "@/pages/intel/intel-listings/components/IntelListItem";
import { ApolloLeagueId, SunsApiError } from "@suns/api";
import { useTeams } from "@/pages/intel/hooks/useTeams";
import { defaultFilterValues } from "@/shared/const";
import { useSearchParams } from "react-router-dom";
import { PlayerLevel } from "@/pages/intel/intel-create-player-team/tags";
import {
  IntelQueryParams,
  PlayerCacheItem,
  TeamRow,
} from "@suns/api/generated-client/apollo";
import ReportFilters from "./components/ReportFilters";
import { usePlayerList } from "../reports-create/hooks/usePlayerList";

export interface FilterValues {
  level?: string;
  teamId?: string[];
  playerId?: string;
  authorUsername?: string[];
  defaultSelectedPlayer?: PlayerCacheItem | null;
}

const PAGE_SIZE = 20;

export function AllReportsListings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { response: playerList, loading: playerListLoading } = usePlayerList();

  const getParamsFromUrl = () => ({
    level: searchParams.get("level") || PlayerLevel.PRO,
    teamId: searchParams.getAll("teamId") || undefined,
    playerId: searchParams.getAll("playerId")?.[0] || undefined,
    authorUsername: searchParams.getAll("authorUsername") || undefined,
  });

  const [activeTab, setActiveTab] = useState("reports");
  const [reportsPage, setReportsPage] = useState(0);
  const [intelPage, setIntelPage] = useState(0);

  const [filterValues, setFilterValues] =
    useState<FilterValues>(getParamsFromUrl());

  const {
    response: reportsResponse,
    loading: reportsLoading,
    error: reportsError,
  } = useAsync(allReportsLoader, {
    offset: reportsPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    level: filterValues.level,
    teamId: filterValues.teamId,
    playerId: filterValues.playerId,
    authorUsername: filterValues.authorUsername,
  });

  const {
    response: intelResponse,
    loading: intelLoading,
    error: intelError,
  } = useAsync(allIntelLoader, {
    offset: intelPage * PAGE_SIZE,
    limit: PAGE_SIZE,
    level: filterValues.level as IntelQueryParams.level | undefined,
    teamId: filterValues.teamId?.map(Number),
    playerId: filterValues.playerId
      ? parseInt(filterValues.playerId)
      : undefined,
    authorUsername: filterValues.authorUsername,
  });

  const league = filterValues.level?.includes(PlayerLevel.PRO)
    ? ApolloLeagueId.NBA
    : ApolloLeagueId.NCAA;

  const {
    response: teamsReponse,
    loading: teamsLoading,
    error: teamsError,
  } = useTeams(league);

  const {
    response: authorsResponse,
    loading: authorsLoading,
    error: authorsError,
  } = useAsync(allAuthorUsernamesLoader);

  const teams = teamsReponse?.conferences
    .reduce<TeamRow[]>((allTeams, conference) => {
      const teamsInConference = teamsReponse.teams[conference] || [];
      return allTeams.concat(teamsInConference);
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((team) => ({
      key: team.id.toString(),
      name: team.name,
    }));

  const authors = authorsResponse?.map((author) => ({
    key: author.authorUsername,
    name: author.authorName,
  }));

  const handleChangeFilterValues = (value: FilterValues) => {
    setReportsPage(0);
    setIntelPage(0);
    const updatedFilters = { ...filterValues, ...value };
    setFilterValues(updatedFilters);

    const params = new URLSearchParams();

    Object.entries(updatedFilters).forEach(([key, values]) => {
      if (values == null) return;

      if (Array.isArray(values)) {
        values.forEach((val) => val && params.append(key, val));
      } else {
        params.append(key, values.toString());
      }
    });

    setSearchParams(params);
  };

  const handleClear = () => {
    setSearchParams({});
    setFilterValues(defaultFilterValues);
  };

  useEffect(() => {
    const urlParams = getParamsFromUrl();
    setFilterValues(urlParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, reportsPage, intelPage]);

  if (reportsError) {
    throw new SunsApiError("Unable to load reports.", { cause: reportsError });
  }

  if (intelError) {
    throw new SunsApiError("Unable to load intel.", { cause: intelError });
  }

  if (teamsError) {
    throw new SunsApiError("Unable to load teams.", { cause: teamsError });
  }

  if (authorsError) {
    throw new SunsApiError("Unable to load authors.", { cause: authorsError });
  }

  return (
    <Card
      header={
        <Text size="xl" heading>
          All reports
        </Text>
      }
    >
      <Tabs onValueChange={setActiveTab} value={activeTab}>
        <TabsList>
          <TabsTrigger value="reports">
            <Text>Reports</Text>
          </TabsTrigger>
          <TabsTrigger value="intel">
            <Text>Intel</Text>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="reports">
          <ReportFilters
            type="Report"
            onChange={handleChangeFilterValues}
            onClear={handleClear}
            filterValues={filterValues}
            teams={teams || []}
            authors={authors || []}
            loading={teamsLoading || authorsLoading}
            playerList={playerList || []}
            playerListLoading={playerListLoading}
          />
          {!reportsResponse || reportsLoading ? (
            <ReportsLoading />
          ) : (
            <>
              {reportsResponse.reports.length > 0 ? (
                <Flex direction="down" className="divide-y-2">
                  {reportsResponse.reports.map((report) => (
                    <ReportListItem
                      key={`report-${report.id}`}
                      className="py-4"
                      report={report}
                    />
                  ))}
                </Flex>
              ) : (
                <Text className="py-10 text-center" size="lg" muted>
                  No reports found.
                </Text>
              )}
              <PaginationControl
                {...reportsResponse}
                onPageClicked={(page) => {
                  setReportsPage(page - 1);
                  window.scrollTo({ top: 0 });
                }}
              />
            </>
          )}
        </TabsContent>
        <TabsContent value="intel">
          <ReportFilters
            type={"Intel"}
            onChange={handleChangeFilterValues}
            onClear={handleClear}
            filterValues={filterValues}
            teams={teams || []}
            authors={authors || []}
            loading={teamsLoading || authorsLoading}
            playerList={playerList || []}
            playerListLoading={playerListLoading}
          />
          {!intelResponse || intelLoading ? (
            <ReportsLoading />
          ) : (
            <>
              {intelResponse.intel.length > 0 ? (
                <Flex direction="down" className="divide-y-2">
                  {intelResponse.intel.map((intel) => (
                    <IntelListItem
                      key={`report-${intel.id}`}
                      className="py-4"
                      intel={intel}
                    />
                  ))}
                </Flex>
              ) : (
                <Text className="py-10 text-center" size="lg" muted>
                  No intel found.
                </Text>
              )}
              <PaginationControl
                {...intelResponse}
                onPageClicked={(page) => {
                  setIntelPage(page - 1);
                  window.scrollTo({ top: 0 });
                }}
              />
            </>
          )}
        </TabsContent>
      </Tabs>
    </Card>
  );
}

function ReportsLoading() {
  return (
    <Flex className="py-4" direction="down" gap="lg">
      <SkeletonText />
      <SkeletonText />
    </Flex>
  );
}
