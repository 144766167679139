import { toast, type ToastOptions } from "react-toastify";
import { notify } from "../bugsnag";

interface ShowErrorProps {
  message: string;
  notifyBugsnag?: boolean;
  error?: Error;
  options?: ToastOptions;
}

export function showError({
  message,
  notifyBugsnag = true,
  error,
  options = {},
}: ShowErrorProps) {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    ...options,
  });
  if (notifyBugsnag) {
    notify(error || message);
  }
}
