import { configureStore } from "@reduxjs/toolkit";
import toastSlice from "./reducers/toaster.reducer";
import printRef from "./reducers/print.reducer";
import setConstantsSlice from "./reducers/constants.reducer";

const store = configureStore({
  reducer: {
    toast: toastSlice,
    printRef: printRef,
    setConstants: setConstantsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
