import {
  Flex,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Text,
  TextEditor,
} from "@suns/design-system";
import { FormSkillSelect } from "../FormSkillSelect";
import {
  DefenseOffBallScores,
  DefenseOnBallScores,
  ReportCanDefendKeys,
  ReportScoreDescriptions,
} from "@/pages/reports/reports-const";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { useFormState } from "react-hook-form";
import { ThreeStateToggle } from "../ThreeStateToggle/ThreeStateToggle";

export default function DefenseScores({ readonly = false }) {
  const formState = useFormState();
  const type = formState.defaultValues?.type ?? ReportResponseItem.type.GAME;

  return (
    <Flex direction="down" gap="md">
      <Text size="lg" heading asChild>
        <FormLabel>Defense</FormLabel>
      </Text>

      <Text size="md" muted heading asChild>
        <FormLabel>Can defend</FormLabel>
      </Text>

      <Flex direction="right" gap="sm">
        {ReportCanDefendKeys.map((score) => (
          <FormField
            key={score}
            name={`scores.${score}`}
            render={({ field }) => (
              <FormItem key={score}>
                <FormControl>
                  <ThreeStateToggle
                    {...field}
                    value={field.value ? Number(field.value) : 0}
                    onChange={(value) => {
                      field.onChange(`${value}`);
                    }}
                  >
                    {ReportScoreDescriptions[score].label}
                  </ThreeStateToggle>
                </FormControl>
              </FormItem>
            )}
          />
        ))}
      </Flex>

      <Text size="md" muted heading asChild>
        <FormLabel>On-ball</FormLabel>
      </Text>
      <Flex direction="right" gap="lg" wrap>
        {DefenseOnBallScores.map((score) => (
          <FormSkillSelect
            key={score}
            name={`scores.${score}`}
            label={ReportScoreDescriptions[score].label}
            tooltip={ReportScoreDescriptions[score].description}
            allowNA={type !== ReportResponseItem.type.PHASE}
          />
        ))}
      </Flex>

      <Text size="md" className="mt-4" muted heading asChild>
        <FormLabel>Off-ball</FormLabel>
      </Text>
      <Flex direction="right" gap="lg" wrap>
        {DefenseOffBallScores.map((score) => (
          <FormSkillSelect
            key={score}
            name={`scores.${score}`}
            label={ReportScoreDescriptions[score].label}
            tooltip={ReportScoreDescriptions[score].description}
            allowNA={type !== ReportResponseItem.type.PHASE}
          />
        ))}
      </Flex>

      <FormField
        name="defensiveNotes"
        render={({ field }) => (
          <FormItem>
            <Text size="md" muted heading asChild>
              <FormLabel>Defensive notes</FormLabel>
            </Text>
            <FormControl>
              <TextEditor
                readonly={readonly}
                className="h-44"
                {...field}
                defaultValue={formState.defaultValues?.defensiveNotes}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </Flex>
  );
}
