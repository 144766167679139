import {
  Button,
  Card,
  Container,
  Flex,
  Skeleton,
  SkeletonHeading,
  Text,
  cn,
} from "@suns/design-system";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { labsList } from "../../shared/const";

import { FlaskConicalOff } from "@suns/design-system/icons";

export function LabsIndex() {
  const { slug } = useParams();
  const navigate = useNavigate();

  const lab = labsList.find((lab) => lab.slug === slug);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <Container className="h-dvh max-w-full">
      {lab ? (
        <Card className="h-full w-full">
          {iframeLoaded ? null : (
            <>
              <SkeletonHeading className="mb-4 h-12 w-64" />
              <Skeleton className="mb-4 h-6 w-1/4" />
              <Skeleton className="h-5/6 w-full" />
            </>
          )}
          {iframeLoaded ? (
            <Flex direction="down" className="mb-6">
              <Text heading size="xl">
                {lab?.subTitle} {lab?.title}
              </Text>
              <Text size="sm">{lab?.description}</Text>
            </Flex>
          ) : null}
          <iframe
            ref={iframeRef}
            onLoad={handleIframeLoad}
            src={lab?.url}
            className={cn("h-5/6 w-full rounded", !iframeLoaded && "hidden")}
          />
        </Card>
      ) : (
        <Card className="h-full">
          <Flex
            direction="down"
            align="center"
            justify="center"
            className="h-full"
          >
            <FlaskConicalOff size={128} />
            <Text heading size="xl" className="mt-4">
              Lab not found
            </Text>
            <Text size="lg" className="mt-2 font-medium text-gray-600">
              The lab you are looking for does not exist.
            </Text>
            <Button
              onClick={() => navigate(-1)}
              size="lg"
              variant="secondary"
              className="mt-4"
            >
              Go Back
            </Button>
          </Flex>
        </Card>
      )}
    </Container>
  );
}
