/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GamesPlayedResponse } from '../models/GamesPlayedResponse';
import type { GroupedTeamListDataResponse } from '../models/GroupedTeamListDataResponse';
import type { PlayersListResponse } from '../models/PlayersListResponse';
import type { SeasonsListResponse } from '../models/SeasonsListResponse';
import type { WinsLossesResponse } from '../models/WinsLossesResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FiltersService {
    /**
     * @returns GroupedTeamListDataResponse Success.
     * @throws ApiError
     */
    public static getTeams(): CancelablePromise<GroupedTeamListDataResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/filters/teams',
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @returns SeasonsListResponse Success.
     * @throws ApiError
     */
    public static getSeasons(): CancelablePromise<SeasonsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/filters/seasons',
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param seasons
     * @param teamAbbr
     * @returns PlayersListResponse Success.
     * @throws ApiError
     */
    public static getPlayers(
        seasons: Array<string>,
        teamAbbr: string,
    ): CancelablePromise<PlayersListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/filters/players',
            query: {
                'seasons': seasons,
                'teamAbbr': teamAbbr,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param seasons
     * @param seasonType
     * @param teamAbbr
     * @param oppTeamAbbr
     * @returns GamesPlayedResponse Success.
     * @throws ApiError
     */
    public static getGamesPlayed(
        seasons: Array<string>,
        seasonType: Array<string>,
        teamAbbr: string,
        oppTeamAbbr?: string,
    ): CancelablePromise<GamesPlayedResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/filters/games-played',
            query: {
                'seasons': seasons,
                'seasonType': seasonType,
                'teamAbbr': teamAbbr,
                'oppTeamAbbr': oppTeamAbbr,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
            },
        });
    }
    /**
     * @param seasons
     * @param seasonTypes
     * @param teamAbbr
     * @param noOfGames
     * @param oppTeamAbbr
     * @returns WinsLossesResponse Success.
     * @throws ApiError
     */
    public static getWinsLosses(
        seasons: Array<string>,
        seasonTypes: Array<'Pre Season' | 'Regular Season' | 'Playoffs' | 'Play-In Season'>,
        teamAbbr: string,
        noOfGames?: number,
        oppTeamAbbr?: string,
    ): CancelablePromise<WinsLossesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/filters/wins-losses',
            query: {
                'seasons': seasons,
                'seasonTypes': seasonTypes,
                'teamAbbr': teamAbbr,
                'noOfGames': noOfGames,
                'oppTeamAbbr': oppTeamAbbr,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
}
