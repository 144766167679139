import { ReportGame } from "@/pages/reports/reports-create-game/loaders/create-game-report-loader";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { Flex, Text, SkeletonText, Button } from "@suns/design-system";
import dayjs from "dayjs";
import { URL } from "@/shared/utils/route-constant";
import { Link } from "react-router-dom";

interface PhaseReportGameSelectionProps {
  reports: ReportResponseItem[];
  games: Record<string, ReportGame>;
}
export default function PhaseReportGameSelection({
  reports,
  games,
}: PhaseReportGameSelectionProps) {
  return (
    <Flex direction="down" gap="lg">
      {reports != null ? (
        <Flex direction="down" gap="sm">
          <Text heading size="lg">
            Phase Report Games
          </Text>
          <Flex direction="down" gap="xs">
            {reports.map((report) => {
              if (report.gameVendorId) {
                const game = games[report.gameVendorId];
                if (!game) {
                  return (
                    <SkeletonText
                      key={report?.id}
                      rows={1}
                      className="h-4 w-96"
                    />
                  );
                }

                const opponent =
                  game.homeTeam.id === report.teamId
                    ? game.awayTeam
                    : game.homeTeam;

                return (
                  <Flex
                    direction="right"
                    gap="xs"
                    align="center"
                    key={report.id}
                  >
                    <Text heading>
                      {dayjs(games[report.gameVendorId].gameTimeUTC).format(
                        "MMMM Do"
                      )}
                    </Text>
                    against
                    <Text heading>{opponent.teamName}</Text>
                    <Button variant="link" size="xs" type="button">
                      <Link
                        to={`/${URL.REPORTS.path}/${URL.REPORTS.children.VIEW_REPORT.path}/${report.id}`}
                      >
                        (View Report)
                      </Link>
                    </Button>
                  </Flex>
                );
              } else {
                return null;
              }
            })}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
}
