import { logout } from "@/shared/auth";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

export function NavigateLogin() {
  const { pathname } = useLocation();

  useEffect(() => {
    logout();
  }, []);
  return <Navigate to="/login" state={{ from: pathname }} replace />;
}

export function useNavigateLogin() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return () => {
    logout();

    navigate("/login", {
      state: { from: pathname },
      replace: true,
    });
  };
}
