import { useState } from "react";
import { PlayerHeader, useNavigateLogin } from "@/components";
import { useAccount } from "@azure/msal-react";
import { useNavigate, useParams } from "react-router-dom";
import { apolloApi } from "@/shared/api";
import IntelForm, { IntelFormSchema } from "./components/IntelForm";
import { Button, Card, Flex, Skeleton, Text } from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";
import { usePlayer } from "../hooks/usePlayers";
import { IntelUpsertParams } from "@suns/api/generated-client/apollo";
import { intelLoader } from "./loader/intel-loader";
import { useAsync } from "@/shared/hooks";
import {
  intelFormDataToUpsertParams,
  intelResponseToFormData,
} from "./intel-utils";
import { toast, ToastType } from "@/shared/utils/toaster";

import { SunsApiError } from "@suns/api";
import { useTeamById } from "../hooks/useTeams";
import { TeamHeader } from "../components/TeamHeader.tsx/TeamHeader";
import { PlayerLevel } from "./tags";
function defaultIntel({
  teamId,
  playerId,
  level,
  authorUsername,
  authorName,
}: Pick<
  IntelFormSchema,
  "teamId" | "playerId" | "level" | "authorUsername" | "authorName"
>) {
  return {
    teamId,
    playerId,
    level,
    status: IntelUpsertParams.status.UNPUBLISHED,
    authorUsername,
    authorName,
  };
}

export function IntelCreatePlayerTeam() {
  const navigateLogin = useNavigateLogin();
  const navigate = useNavigate();
  const account = useAccount();
  const { playerId, teamId } = useParams();
  const [saving, setSaving] = useState(false);
  const [savedIntelId, setSavedIntelId] = useState<number>();

  const {
    player,
    loading: playerLoading,
    error: playerError,
  } = usePlayer(Number(playerId));

  const {
    response: team,
    loading: teamLoading,
    error: teamError,
  } = useTeamById(teamId ? Number(teamId) : undefined);
  const {
    response: intel,
    loading: intelLoading,
    error: intelError,
  } = useAsync(intelLoader, {
    teamId: teamId ? [Number(teamId)] : undefined,
    playerId: playerId ? Number(playerId) : undefined,
    authorUsername: account?.username ? [account.username] : undefined,
  });

  const handleSave = async (intelFormData: IntelFormSchema) => {
    try {
      setSaving(true);
      const savedIntel = await apolloApi.saveIntel({
        ...intelFormDataToUpsertParams(intelFormData),
        id: intel?.id ?? savedIntelId,
      });

      setSavedIntelId(savedIntel.intel.id);

      if (intelFormData.status === IntelUpsertParams.status.PUBLISHED) {
        toast(ToastType.SUCCESS, "Intel Published");
        navigate(-1);
      } else {
        toast(ToastType.INFO, "Intel Saved");
      }
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toast(ToastType.ERROR, "Please log back in to save.");
        navigateLogin();
      }
      toast(ToastType.ERROR, "Unable to save intel. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  if (intelError) {
    throw new SunsApiError("There was an error requesting intel.", {
      cause: intelError,
    });
  }

  if (playerError) {
    throw new SunsApiError("There was an error requesting a player.", {
      cause: playerError,
    });
  }

  if (teamError) {
    throw new SunsApiError("There was an error requesting teams.", {
      cause: teamError,
    });
  }

  return (
    <>
      <Flex className="mb-2" align="center">
        <Button
          size="xs"
          variant="ghost"
          className="p-0 text-sm text-gray-500"
          onClick={() => navigate("/reports/intel/create")}
        >
          Intel
        </Button>
        <ChevronRight className="text-gray-500 " size={20} />
        <Button size="xs" variant="ghost" className="p-0 text-sm text-gray-500">
          New Intel
        </Button>
      </Flex>
      <Text heading={true} size="4xl" className="mb-4">
        New Intel
      </Text>
      <Card>
        {playerLoading || intelLoading || teamLoading ? (
          <Flex gap="lg" className="mt-1">
            <Skeleton className="h-24 w-24 rounded-full" />
            <Flex direction="down" gap="md">
              <Skeleton className="h-10 w-20 md:w-40" />
              <Skeleton className="h-10 w-40 md:w-72" />
            </Flex>
          </Flex>
        ) : (
          <>
            {playerId ? (
              <PlayerHeader
                player={player!}
                teamNbaId={player!.currentTeam?.nbaId ?? undefined}
                teamName={player!.currentTeam?.fullName ?? undefined}
                teamImage={player!.currentTeam?.image ?? undefined}
                leagueId={player!.currentTeam?.domesticLeagueId}
              />
            ) : (
              <TeamHeader
                teamNbaId={team?.nbaId ?? undefined}
                teamName={team?.fullName ?? undefined}
                image={team?.image ?? undefined}
                leagueId={team?.domesticLeagueId}
                teamDivision={
                  team?.division || team?.conference || team?.countryCode
                }
              />
            )}

            <IntelForm
              intel={
                intel
                  ? intelResponseToFormData(intel)
                  : defaultIntel(
                      playerId
                        ? {
                            playerId: player!.id,
                            level: player!.level,
                            authorUsername: account!.username,
                            authorName: account!.name!,
                          }
                        : {
                            teamId: teamId ? Number(teamId) : undefined,
                            level:
                              team?.level.toLocaleUpperCase() ===
                              PlayerLevel.PRO
                                ? PlayerLevel.PRO
                                : PlayerLevel.AMATEUR,
                            authorUsername: account!.username,
                            authorName: account!.name!,
                          }
                    )
              }
              onSave={handleSave}
              onPublish={handleSave}
              saving={saving}
            />
          </>
        )}
      </Card>
    </>
  );
}
