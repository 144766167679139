import { apolloApi } from "@/shared/api";

export async function secondSpectrumLoader({ nbaId }: { nbaId?: string }) {
  if (!nbaId) {
    return null;
  }

  const { stats } = await apolloApi.getSecondSpectrumStats(nbaId);

  const clock = stats.clock;
  const gameOver =
    clock.minutes === 0 && clock.seconds === 0 && clock.period >= 4;

  return {
    ...stats,
    formattedClock: `${clock.minutes}:${padSeconds(clock.seconds)} in the ${ordinal(clock.period)} quarter`,
    gameOver,
  };
}

function padSeconds(seconds: number) {
  return seconds.toString().padStart(2, "0");
}

function ordinal(num: number) {
  const suffix = ["th", "st", "nd", "rd"];
  const idx = num % 100;
  return `${num}${suffix[(idx - 20) % 10] || suffix[idx] || suffix[0]}`;
}
