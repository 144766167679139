import { labsList } from "@/shared/const";
import { useAccount } from "@/shared/hooks";
import { Button, Card, Container, Flex, Grid, Text } from "@suns/design-system";
import {
  Dribbble,
  FlaskConical,
  Play,
  Users,
  Icon as LIcon,
} from "@suns/design-system/icons";
import { Link } from "react-router-dom";

interface HomeCard {
  title: string;
  description: string;
  Icon: LIcon;
  link: string;
}

const homeCards = [
  {
    Icon: Users,
    title: "Players",
    description:
      "View boxscore, lineup, and rebounding stats for individual players.",
    link: "/box-scores",
  },
  {
    Icon: Play,
    title: "Team",
    description: "Filter through data on how our team is performing.",
    link: "/team",
  },
  {
    Icon: Dribbble,
    title: "Games",
    description: "View data on how we've performed in our latest games.",
    link: "/game",
  },
] satisfies HomeCard[];

export function Home() {
  const { name, hasDeveloperAccess } = useAccount();

  return (
    <Container size="xl" className="px-4">
      <Flex direction="down" gap="md" className="mb-8 mt-4 lg:mt-8">
        <Text size="5xl" heading>
          Welcome {name}
        </Text>
        <Text size="lg">
          This is the home of Phoenix Suns Basketball Analytics.
        </Text>
      </Flex>

      <Grid columns={["md:3"]} gap="md" className="mb-4">
        {homeCards.map(({ Icon, title, description, link }, idx) => (
          <Link key={idx} to={link}>
            <Card className="hover:bg-gray-50">
              <Flex gap="md">
                <Icon className="mt-1 size-8 shrink-0 text-primary" />
                <Flex direction="down" gap="xs">
                  <Text size="xl" heading>
                    {title}
                  </Text>
                  <Text>{description}</Text>
                </Flex>
              </Flex>
            </Card>
          </Link>
        ))}
      </Grid>

      {hasDeveloperAccess && (
        <Card>
          <Flex direction="down" gap="md">
            <Flex gap="md" align="center">
              <FlaskConical className="mt-1 size-8 shrink-0 text-primary" />
              <Text size="xl" heading>
                Labs
              </Text>
            </Flex>
            <Text>
              Check out new reports and tools with our latest insights.
            </Text>

            <Flex gap="md">
              {labsList.map(({ slug, subTitle, title }) => (
                <Button key={`button-${slug}`} variant="outline" asChild>
                  <Link to={`labs/${slug}`}>
                    <span className="mr-1 font-bold">{subTitle}</span>
                    {title}
                  </Link>
                </Button>
              ))}
            </Flex>
          </Flex>
        </Card>
      )}
    </Container>
  );
}
