import { Badge, Button, Flex, Text, TextEditor } from "@suns/design-system";
import { IntelRow } from "@suns/api/generated-client/apollo";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export function IntelListItem({
  intel,
  className,
}: {
  intel: IntelRow;
  className?: string;
}) {
  const player = intel.players?.[0];
  const team = intel.teams?.[0];

  return (
    <Flex direction="down" gap="sm" className={className}>
      <Link
        className="w-96"
        to={`/reports/intel/view/${player ? "player" : "team"}/${
          player?.id ?? team?.id
        }/${intel.id}`}
      >
        {player ? (
          <Text size="2xl" heading>
            {player?.firstName} {player?.lastName}
          </Text>
        ) : (
          <Text size="2xl" heading>
            {team?.fullName}
          </Text>
        )}
      </Link>
      <Flex gap="sm" wrap>
        {intel.tags?.map((key) => {
          return (
            <Badge key={`tag-${key}`} variant="secondary">
              {key}
            </Badge>
          );
        })}
      </Flex>

      <TextEditor defaultValue={intel.notes ?? undefined} readonly />
      <Flex gap="sm">
        <Button size="sm" variant="outline">
          <Link
            to={`/reports/intel/view/${player ? "player" : "team"}/${
              player?.id ?? team?.id
            }/${intel.id}`}
          >
            View
          </Link>
        </Button>
        <Button size="sm" variant="outline">
          <Link
            to={`/reports/intel/edit/${player ? "player" : "team"}/${
              player?.id ?? team?.id
            }/${intel?.id}`}
          >
            Edit
          </Link>
        </Button>
      </Flex>
      <Flex direction="right" gap="xs">
        <Text muted heading>
          {dayjs(intel.createdAt).format("MMMM Do YYYY")}
        </Text>
        <Text muted>by</Text>
        <Text muted heading>
          {intel.authorName}
        </Text>
      </Flex>
      <Text size="sm" heading>
        Source: {intel.source}
      </Text>
    </Flex>
  );
}
