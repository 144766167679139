const NoRecordFound = () => {
  return (
    <div className="no-record">
      {/* <figure> <img src={noRecord} /> </figure> */}
      <h2>No matching record found.</h2>
    </div>
  );
};

export default NoRecordFound;
