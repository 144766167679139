import { Suspense } from "react";
import SuspenseFallback from "@/components/suspense-fallback/suspense-fallback";
import ErrorFallback from "@/components/error-fallback/error-fallback";
import { GameDnaIndex, GameDnaListings, GameDnaLive } from "@/pages/game-dna";
import { RouteObject } from "react-router-dom";

export const GameDnaRoutes = {
  path: "game",
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <GameDnaListings />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: `:gameId`,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <GameDnaIndex />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: ":gameId/live",
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <GameDnaLive />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
    {
      path: `latest`,
      element: (
        <Suspense fallback={<SuspenseFallback />}>
          <GameDnaIndex />
        </Suspense>
      ),
      errorElement: <ErrorFallback />,
    },
  ],
} satisfies RouteObject;
