/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LeagueRow = {
    id: number;
    dxId: string | null;
    nbaId: string | null;
    ssId: string | null;
    name: string;
    countryCode: string;
    level: LeagueRow.level;
    createdAt: string;
    updatedAt: string;
};
export namespace LeagueRow {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
}

