import { shortenName } from "../../shared/utils/helper-functions";
import { LineupsRow } from "../../swagger";

export const playerStatsColumns = [
  {
    Header: "Player",
    fixed: "right",
    accessor: "FULL_NAME",
  },
  {
    Header: "GP",
    accessor: "GAMES_PLAYED",
  },
  {
    Header: "MP",
    accessor: "MP",
  },
  {
    Header: "FGM",
    accessor: "FGM",
  },
  {
    Header: "FGA",
    accessor: "FGA",
  },
  {
    Header: "FG%",
    accessor: "FG_PCT",
  },
  {
    Header: "3PM",
    accessor: "FG3M",
  },
  {
    Header: "3PA",
    accessor: "FG3A",
  },
  {
    Header: "3P%",
    accessor: "FG3_PCT",
  },
  {
    Header: "FTM",
    accessor: "FTM",
  },
  {
    Header: "FTA",
    accessor: "FTA",
  },
  {
    Header: "FT%",
    accessor: "FT_PCT",
  },
  {
    Header: "ORB",
    accessor: "ORB",
  },
  {
    Header: "DRB",
    accessor: "DRB",
  },
  {
    Header: "REB",
    accessor: "REB",
  },
  {
    Header: "AST",
    accessor: "AST",
  },
  {
    Header: "STL",
    accessor: "STL",
  },
  {
    Header: "BLK",
    accessor: "BLK",
  },
  {
    Header: "TOV",
    accessor: "TOV",
  },
  {
    Header: "PF",
    accessor: "PF",
  },
  {
    Header: "+/-",
    accessor: "PLUS_MINUS",
  },
  {
    Header: "PTS",
    accessor: "PTS",
  },
];

export const lineupsColumns = [
  {
    Header: "Players",
    accessor: (row: LineupsRow) => {
      return `${playerNames(row)}`;
    },
  },
  {
    Header: "MP",
    accessor: "MP",
  },
  {
    Header: "ORtg",
    accessor: "ORTG",
  },
  {
    Header: "DRtg",
    accessor: "DRTG",
  },
  {
    Header: "NRtg",
    accessor: "NET_RTG",
  },
  {
    Header: "+ / -",
    accessor: "PLUS_MINUS",
  },
];

export const reboundingColumns = [
  {
    Header: "",
    accessor: "crashers",
  },
  {
    Header: "Crash % (Time)",
    accessor: "crashPct",
  },
  {
    Header: "Crash REB%",
    accessor: "orbPct",
  },
  {
    Header: "Crash PTS",
    accessor: "pointsPerOpp",
  },
  {
    Header: "Crash xPTS Add",
    accessor: "expPointsPerOpp",
  },
  {
    Header: "Box % (Time)",
    accessor: "boxPct",
  },
  {
    Header: "Box REB%",
    accessor: "boxOrbPct",
  },
  {
    Header: "Box PTS",
    accessor: "boxPointsPerOpp",
  },
  {
    Header: "Box xPTS Save ",
    accessor: "boxPointsPerOppXPPP",
  },
];

export const reboundingPlayerColumns = [
  {
    Header: "Player",
    accessor: "playerName",
  },
  {
    Header: "GP",
    accessor: "gp",
  },
  {
    Header: "MP",
    accessor: "mpg",
  },
  {
    Header: "ORB%",
    accessor: "orbPct",
  },
  {
    Header: "DRB%",
    accessor: "drbPct",
  },
  {
    Header: "Crash%",
    accessor: "crashPct",
  },
  {
    Header: "ORB% Diff ON",
    accessor: "teamOrbDiffOn",
  },
  {
    Header: "DRB% Diff ON",
    accessor: "teamDrbDiffOn",
  },
];

const playerNames = (row: LineupsRow) => {
  let shortenedNames = "";

  for (let i = 1; i <= 5; i++) {
    const fullName = row[`PLAYER_${i}_FULL_NAME` as keyof LineupsRow];
    if (fullName) {
      shortenedNames += `${shortenName(fullName)} - `;
    }
  }

  if (shortenedNames.endsWith(" - ")) {
    shortenedNames = shortenedNames.slice(0, -3);
  }
  return shortenedNames;
};
