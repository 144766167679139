import {
  Flex,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  RadioGroup,
  RadioGroupItem,
} from "@suns/design-system";

interface FormMultiSelectOption {
  value: string;
  label: string;
}

interface FormMultiSelectParams {
  name: string;
  options: FormMultiSelectOption[];
}

export function FormMultiSelect({ name, options }: FormMultiSelectParams) {
  return (
    <FormField
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-lg font-bold">Suns Fit</FormLabel>
          <FormControl>
            <RadioGroup
              className="flex flex-col pt-2"
              onValueChange={field.onChange}
              value={field.value}
            >
              <Flex direction="right" gap="sm">
                {options.map((option, index) => (
                  <RadioGroupItem key={`${name}-${index}`} value={option.value}>
                    {option.label}
                  </RadioGroupItem>
                ))}
              </Flex>
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );
}
