import { useAccount } from "@/shared/hooks";
import { NavigateLogin } from "../NavigateLogin/NavigateLogin";

interface ProtectedProps {
  children: React.ReactNode;
}
export function Protected({ children }: ProtectedProps) {
  const { isAuthenticated, hasDefaultAccess } = useAccount();
  return isAuthenticated && hasDefaultAccess ? children : <NavigateLogin />;
}

export default Protected;
