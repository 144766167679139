import { apolloApi } from "@/shared/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";

export async function allIntelLoader({
  offset = 0,
  limit = 20,
  level,
  playerId,
  teamId,
  authorUsername,
}: {
  offset: number;
  limit: number;
  teamId?: number[];
  level?: IntelQueryParams.level;
  trustworthiness?: string[];
  authorUsername?: string[];
  playerId?: number;
}) {
  const response = await apolloApi.getIntel({
    offset,
    limit,
    level,
    playerId,
    teamId,
    authorUsername,
    status: IntelQueryParams.status.PUBLISHED,
  });
  return response;
}
