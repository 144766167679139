import { apolloApi } from "@/shared/api";
import { IntelQueryParams } from "@suns/api/generated-client/apollo";

export async function myIntelLoader({
  offset = 0,
  limit = 20,
  level,
  playerId,
  teamId,
  authorUsername,
  status,
}: {
  offset: number;
  limit: number;
  level?: IntelQueryParams.level;
  playerId?: number;
  teamId?: number[];
  authorUsername?: string[];
  status:
    | IntelQueryParams.status.PUBLISHED
    | IntelQueryParams.status.UNPUBLISHED;
}) {
  const response = await apolloApi.getIntel({
    offset,
    limit,
    status,
    teamId,
    playerId,
    level,
    authorUsername: authorUsername,
  });

  return response;
}
