import { useEffect, useReducer, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Card,
  Flex,
  Select,
  SelectOption,
  SkeletonText,
  Tabs,
  Text,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";
import {
  PlayerRow,
  ReportResponseItem,
  TeamRow,
} from "@suns/api/generated-client/apollo";
import {
  PlayerHeader,
  PlayerHeaderSkeleton,
  GameHero,
  GameHeroSkeleton,
  NavigateLogin,
} from "@/components";
import { useAccount, useAsync } from "@/shared/hooks";
import { apolloApi } from "@/shared/api";
import {
  gameReportLoader,
  ReportGame,
} from "./loaders/create-game-report-loader";
import {
  reportFormDataToUpsertParams,
  reportResponseToFormData,
} from "../report-utils";
import {
  ReportForm,
  ReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { notify } from "@/components/bugsnag";
import { PlayerReportItem } from "./components/PlayerReportItem";
import { PlayerList } from "./components/PlayerList";
import { SunsApiError } from "@suns/api";
import { toast, ToastType } from "@/shared/utils/toaster";
import Page from "@/components/Page/Page";

type TeamTabValues = "home" | "away";

const sortByMetadataAndName = (
  player1: PlayerRow,
  player2: PlayerRow,
  reports: Record<string, ReportResponseItem>
) => {
  if (player1.metadata?.target && !player2.metadata?.target) return -1;
  if (!player1.metadata?.target && player2.metadata?.target) return 1;

  const hasReport1 = !!reports[player1.id];
  const hasReport2 = !!reports[player2.id];

  if (hasReport1 && !hasReport2) return -1;
  if (!hasReport1 && hasReport2) return 1;

  const lastNameComparison = player1.lastName.localeCompare(player2.lastName);
  if (lastNameComparison !== 0) {
    return lastNameComparison;
  }
  return player1.firstName.localeCompare(player2.firstName);
};

export function CreateGameReport() {
  const { gameId } = useParams();
  const [navigateLogin, setNavigateLogin] = useState(false);
  const account = useAccount();
  const [searchParams] = useSearchParams();
  const playerId = searchParams.get("playerId");

  const savingPlayersRef = useRef<Record<string, boolean>>({});
  const publishingPlayersRef = useRef<Record<string, boolean>>({});
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);

  const reportsRef = useRef<Record<string, ReportResponseItem>>({});
  const [activeTab, setActiveTab] = useState<TeamTabValues>("home");
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerRow | null>(null);

  const {
    loading,
    response,
    error: responseError,
  } = useAsync(gameReportLoader, {
    gameVendorId: gameId ?? "",
    authorUsername: account.info?.username ?? "",
  });

  useEffect(() => {
    if (response && response.reports) {
      reportsRef.current = response.reports;

      const firstPlayer =
        response.homeTeam?.currentPlayers?.sort((player1, player2) =>
          sortByMetadataAndName(player1, player2, reportsRef.current)
        )[0] ?? null;
      setSelectedPlayer(firstPlayer);
    }
  }, [response]);

  useEffect(() => {
    if (response && response.reports && playerId && selectedPlayer === null) {
      let initialPlayer = response.homeTeam?.currentPlayers?.find(
        (player) => player.id.toString() === playerId
      );
      if (!initialPlayer) {
        initialPlayer = response.awayTeam?.currentPlayers?.find(
          (player) => player.id.toString() === playerId
        );
      }

      if (initialPlayer) {
        if (response.homeTeam?.id === initialPlayer.currentTeamId) {
          setActiveTab("home");
        } else {
          setActiveTab("away");
        }

        setSelectedPlayer(initialPlayer);
      }
    }
  }, [response, playerId, selectedPlayer]);

  useEffect(() => {
    if (
      response?.homeTeam?.currentPlayers?.length &&
      !response?.awayTeam?.currentPlayers?.length
    ) {
      handleTabChange("home");
    } else if (
      !response?.homeTeam?.currentPlayers?.length &&
      response?.awayTeam?.currentPlayers?.length
    ) {
      handleTabChange("away");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  if (navigateLogin) {
    return <NavigateLogin />;
  }

  if (responseError) {
    throw new SunsApiError("Error loading the game.", { cause: responseError });
  }

  if (loading) {
    return <CreateGameReportLoading />;
  }

  if (!gameId) {
    throw Error("Error loading the game.");
  }

  if (!account.info) {
    throw Error("Error loading account info.");
  }

  const { game, homeTeam, awayTeam } = response;

  if (!homeTeam || !awayTeam || !game) {
    throw Error("There was an error loading the rosters.");
  }

  if (
    !selectedPlayer ||
    (homeTeam.currentPlayers?.length === 0 &&
      awayTeam.currentPlayers?.length === 0)
  ) {
    return <CreateGameReportEmpty {...game} />;
  }

  function handleTabChange(newTab: string) {
    if (newTab !== activeTab) {
      setActiveTab(newTab as TeamTabValues);
      if (newTab === "home") {
        handlePlayerSelected(homeTeam?.currentPlayers?.[0] ?? null);
      } else {
        handlePlayerSelected(awayTeam?.currentPlayers?.[0] ?? null);
      }
    }
  }

  function handlePlayerSelected(player: PlayerRow | null) {
    if (player) {
      setSelectedPlayer(player);
    }
  }

  async function handleSubmit(
    report: ReportFormSchema,
    publish: boolean = false
  ) {
    try {
      const upsertParams = reportFormDataToUpsertParams(report);

      const existingReport = report.playerId
        ? reportsRef.current[report.playerId]
        : undefined;
      if (existingReport != undefined) {
        upsertParams.id = existingReport.id;
      }

      if (publish) {
        publishingPlayersRef.current[report.playerId] = true;
      }

      savingPlayersRef.current[report.playerId] = true;
      forceUpdate();

      const res = await apolloApi.saveReport(upsertParams);
      reportsRef.current[res.report.playerId] = res.report;

      if (publish) {
        toast(ToastType.SUCCESS, "Report Published");
      } else {
        toast(ToastType.INFO, "Report Saved");
      }

      return res.report.id;
    } catch (e) {
      if (e instanceof SunsApiError && e.authError) {
        toast(ToastType.ERROR, "Please log back in to save.");
        notify(new Error("Auth error trying to save report.", { cause: e }));
        setNavigateLogin(true);
      }
      notify(new Error("Error saving report.", { cause: e }));
      toast(
        ToastType.ERROR,
        `Unable to ${publish ? "publish" : "save"} report. Please try again.`
      );
    } finally {
      delete savingPlayersRef.current[report.playerId];
      delete publishingPlayersRef.current[report.playerId];
      forceUpdate();
    }
  }

  const activeTeam = activeTab === "home" ? homeTeam : awayTeam;

  const activeReport = reportResponseToFormData(
    selectedPlayer,
    account.info,
    ReportResponseItem.type.GAME,
    [game],
    reportsRef.current[selectedPlayer.id] ?? null,
    homeTeam,
    awayTeam
  );

  return (
    <Page title="Create Report">
      <Card className="mb-4 overflow-hidden">
        <GameHero
          leagueId={game.leagueId}
          gameStatus={game.gameStatus || 1}
          gameTimeUTC={game.gameTimeUTC}
          gameClock={game.gameClock}
          period={game.period}
          homeTeam={{
            id: game.homeTeam.id,
            image: game.homeTeam.image,
            tricode: game.homeTeam.tricode ?? game.homeTeam.teamName,
            score: game.homeTeam.score,
          }}
          awayTeam={{
            id: game.awayTeam.id,
            image: game.awayTeam.image,
            tricode: game.awayTeam.tricode ?? game.awayTeam.teamName,
            score: game.awayTeam.score,
          }}
        />
      </Card>
      <Card className="mb-4 md:hidden">
        <TeamTabs
          value={activeTab}
          handleTabChange={handleTabChange}
          handlePlayerSelected={handlePlayerSelected}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          activePlayer={selectedPlayer}
          reports={reportsRef.current}
          savingPlayers={savingPlayersRef.current}
        />
      </Card>
      <Card className="md:grid md:grid-cols-5 md:space-x-8">
        <Flex className="hidden md:flex">
          <TeamTabs
            value={activeTab}
            handleTabChange={handleTabChange}
            handlePlayerSelected={handlePlayerSelected}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            activePlayer={selectedPlayer}
            reports={reportsRef.current}
            savingPlayers={savingPlayersRef.current}
          />
        </Flex>
        <Flex className="col-span-4" direction="down" gap="lg">
          <PlayerHeader
            player={selectedPlayer}
            teamNbaId={activeTeam.nbaId ?? undefined}
            teamName={activeTeam.name}
            teamImage={activeTeam.image ?? undefined}
            leagueId={activeTeam.domesticLeagueId}
          />
          <ReportForm
            key={selectedPlayer.id}
            report={activeReport}
            onSubmit={handleSubmit}
            isSaving={!!savingPlayersRef.current[selectedPlayer.id]}
            isPublishing={!!publishingPlayersRef.current[selectedPlayer.id]}
          />
        </Flex>
      </Card>
    </Page>
  );
}

interface TeamTabsProps {
  value: TeamTabValues;
  handleTabChange: (value: string) => void;
  homeTeam: TeamRow;
  awayTeam: TeamRow;
  activePlayer: PlayerRow;
  reports: Record<string, ReportResponseItem>;
  savingPlayers: Record<string, boolean>;
  handlePlayerSelected: (player: PlayerRow) => void;
}

function TeamTabs({
  value,
  handleTabChange,
  homeTeam,
  awayTeam,
  activePlayer,
  reports,
  savingPlayers,
  handlePlayerSelected,
}: TeamTabsProps) {
  const sortedHomePlayers =
    homeTeam?.currentPlayers?.sort((player1, player2) =>
      sortByMetadataAndName(player1, player2, reports)
    ) ?? [];
  const sortedAwayPlayers =
    awayTeam?.currentPlayers?.sort((player1, player2) =>
      sortByMetadataAndName(player1, player2, reports)
    ) ?? [];

  return (
    <Tabs
      defaultValue="home"
      value={value}
      className="w-full"
      onValueChange={handleTabChange}
    >
      <TabsList>
        <TabsTrigger
          value="away"
          disabled={awayTeam.currentPlayers?.length === 0}
        >
          <span>{awayTeam.name}</span>
        </TabsTrigger>
        <TabsTrigger
          value="home"
          disabled={homeTeam.currentPlayers?.length === 0}
        >
          <span>{homeTeam.name}</span>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="away">
        <PlayerList
          players={sortedAwayPlayers}
          activePlayer={activePlayer}
          reports={reports}
          savingPlayers={savingPlayers}
          onPlayerClicked={handlePlayerSelected}
          className="hidden md:flex"
        />
        <Select
          className="w-full md:hidden"
          value={`${activePlayer.id}`}
          onValueChange={(value) => {
            const player = awayTeam.currentPlayers?.find(
              (player) => `${player.id}` === value
            );
            if (player) {
              handlePlayerSelected(player);
            }
          }}
        >
          {sortedAwayPlayers.map((player) => (
            <SelectOption
              key={player.id}
              value={`${player.id}`}
              disabled={!!savingPlayers[player.id]}
            >
              <PlayerReportItem
                player={player}
                reports={reports}
                activePlayer={activePlayer}
                isSaving={!!savingPlayers[player.id]}
              />
            </SelectOption>
          ))}
        </Select>
      </TabsContent>
      <TabsContent value="home">
        <PlayerList
          players={sortedHomePlayers}
          activePlayer={activePlayer}
          reports={reports}
          onPlayerClicked={handlePlayerSelected}
          savingPlayers={savingPlayers}
          className="hidden md:flex"
        />
        <Select
          className="w-full md:hidden"
          value={`${activePlayer.id}`}
          onValueChange={(value) => {
            const player = homeTeam.currentPlayers?.find(
              (player) => `${player.id}` === value
            );
            if (player) {
              handlePlayerSelected(player);
            }
          }}
        >
          {sortedHomePlayers.map((player) => (
            <SelectOption
              key={player.id}
              value={`${player.id}`}
              disabled={!!savingPlayers[player.id]}
            >
              <PlayerReportItem
                player={player}
                reports={reports}
                activePlayer={activePlayer}
                isSaving={!!savingPlayers[player.id]}
              />
            </SelectOption>
          ))}
        </Select>
      </TabsContent>
    </Tabs>
  );
}

function CreateGameReportLoading() {
  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Create Report</Text>
      </Flex>
      <Card className="mb-4">
        <GameHeroSkeleton />
      </Card>
      <Card className="grid grid-cols-4 space-x-8">
        <Flex direction="down">
          <SkeletonText rows={5} />
        </Flex>
        <PlayerHeaderSkeleton />
      </Card>
    </>
  );
}

function CreateGameReportEmpty(game: ReportGame) {
  return (
    <Page title="Create Report">
      <Card className="mb-4 overflow-hidden">
        <GameHero
          leagueId={game.leagueId}
          gameStatus={game.gameStatus || 1}
          gameTimeUTC={game.gameTimeUTC}
          gameClock={game.gameClock}
          period={game.period}
          homeTeam={{
            id: game.homeTeam.id,
            image: game.homeTeam.image,
            tricode: game.homeTeam.tricode ?? game.homeTeam.teamName,
            score: game.homeTeam.score,
          }}
          awayTeam={{
            id: game.awayTeam.id,
            image: game.awayTeam.image,
            tricode: game.awayTeam.tricode ?? game.awayTeam.teamName,
            score: game.awayTeam.score,
          }}
        />
      </Card>
      <Card className="mb-4 text-center">
        <Text size="sm" heading muted>
          One or both of the rosters aren’t available.
        </Text>
      </Card>
    </Page>
  );
}
