import React from "react";
import { labsList } from "@/shared/const";
import { Container, Grid } from "@suns/design-system";
import LabsCard from "./components/LabsCard";

export function LabsListings() {
  return (
    <Container size="2xl">
      <Grid columns="sm:3" gap="lg">
        {labsList.map((lab) => (
          <React.Fragment key={`lab-${lab.id}`}>
            <LabsCard {...lab} />
          </React.Fragment>
        ))}
      </Grid>
    </Container>
  );
}
