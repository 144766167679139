import { AccountInfo } from "@azure/msal-browser";
import { msalInstance } from "./instance";

const Roles = {
  DEFAULT: "Default",
  DEVELOPER: "Developer",
  SCOUT: "Scout",
  SCOUT_ADMIN: "ScoutAdmin",
  BETA_SCOUTING: "BetaScouting",
  BETA_SHINY: "BetaShiny",
};

export type AccountWithRoles = {
  isAuthenticated: boolean;
  hasDefaultAccess: boolean;
  hasDeveloperAccess: boolean;
  hasScoutAccess: boolean;
  hasScoutAdminAccess: boolean;
  hasBetaScoutingAccess: boolean;
  hasBetaShinyAccess: boolean;
  name: string | null;
  info: AccountInfo | null;
};

export function getAccount() {
  const [account] = msalInstance.getAllAccounts();
  return addRolesToAccount(account);
}

export function addRolesToAccount(
  account?: AccountInfo | null
): AccountWithRoles {
  if (account === undefined || account === null) {
    return {
      isAuthenticated: false,
      hasDefaultAccess: false,
      hasDeveloperAccess: false,
      hasScoutAccess: false,
      hasScoutAdminAccess: false,
      hasBetaScoutingAccess: false,
      hasBetaShinyAccess: false,
      info: null,
      name: null,
    };
  }

  const roles = account.idTokenClaims?.roles || [];

  const isDeveloper = roles.includes(Roles.DEVELOPER);
  const isScout = roles.includes(Roles.SCOUT);
  const isScoutAdmin = roles.includes(Roles.SCOUT_ADMIN);

  return {
    isAuthenticated: true,
    hasDefaultAccess: Object.values(Roles).some((role) => roles.includes(role)),
    hasDeveloperAccess: isDeveloper,
    hasScoutAccess: isDeveloper || isScoutAdmin || isScout,
    hasScoutAdminAccess: isDeveloper || isScoutAdmin,
    hasBetaScoutingAccess: isDeveloper || roles.includes(Roles.BETA_SCOUTING),
    hasBetaShinyAccess:
      isDeveloper || roles.includes(Roles.BETA_SHINY) || false,
    name: formatAccountName(account.name),
    info: account,
  };
}

function formatAccountName(name?: string) {
  if (!name) {
    return "";
  }

  if (!name.includes(",")) {
    return name;
  }

  return name.split(",").splice(1).join(" ").trim();
}
