export enum SunsApiErrorName {
  SunsApiError = "SunsApiError",
  SunsApiAuthError = "SunsApiAuthError",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SunsApiErrorCause = Record<string, any> & Error;

type SunsApiErrorOptions =
  | {
      cause: SunsApiErrorCause;
      authError?: boolean;
    }
  | {
      cause?: SunsApiErrorCause;
      authError: true;
    };

export class SunsApiError extends Error {
  options: SunsApiErrorOptions;
  cause?: SunsApiErrorCause;

  constructor(message: string, options: SunsApiErrorOptions) {
    super(message);
    this.options = options;
    this.cause = options.cause;
  }

  get name() {
    if (this.authError) {
      return SunsApiErrorName.SunsApiAuthError;
    }
    return SunsApiErrorName.SunsApiError;
  }

  get authError() {
    return (
      this.options.authError ||
      this.cause?.authError ||
      this.cause?.response?.data?.message == "Apollo authorization failed."
    );
  }
}
