export enum PlayerLevel {
  PRO = "PRO",
  AMATEUR = "AMATEUR",
}

export const tags = [
  {
    key: "BASKETBALL",
    name: "Basketball",
    PlayerLevel: "all",
  },
  {
    key: "BACKGROUND",
    name: "Background",
    PlayerLevel: "all",
  },
  {
    key: "CHARACTER",
    name: "Character",
    PlayerLevel: "all",
  },
  {
    key: "MEDICAL",
    name: "Medical",
    PlayerLevel: "all",
  },
  {
    key: "BODY",
    name: "Body",
    PlayerLevel: "all",
  },
  {
    key: "PSYCH",
    name: "Psych",
    PlayerLevel: "all",
  },
  {
    key: "COACHING",
    name: "Coaching",
    PlayerLevel: "all",
  },
  {
    key: "DRAFT",
    name: "Draft",
    PlayerLevel: PlayerLevel.AMATEUR,
  },
  {
    key: "NIL",
    name: "NIL",
    PlayerLevel: PlayerLevel.AMATEUR,
  },
  {
    key: "TRADE",
    name: "Trade",
    PlayerLevel: PlayerLevel.PRO,
  },
  {
    key: "FRONT_OFFICE",
    name: "Front Office",
    PlayerLevel: PlayerLevel.PRO,
  },
  {
    key: "FREE_AGENCY",
    name: "Free Agency",
    PlayerLevel: PlayerLevel.PRO,
  },
  {
    key: "MINI_CAMP",
    name: "Mini Camp",
    PlayerLevel: PlayerLevel.PRO,
  },
  {
    key: "CONTRACT",
    name: "Contract",
    PlayerLevel: PlayerLevel.PRO,
  },
];
