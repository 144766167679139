/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlayerRow } from './PlayerRow';
export type TeamRow = {
    id: number;
    domesticLeagueId: number;
    internationalLeagueId: number | null;
    name: string;
    code: string | null;
    city: string | null;
    countryCode: string;
    level: TeamRow.level;
    nbaId: number | null;
    ssId: string | null;
    dxId: string | null;
    fullName: string | null;
    division: string | null;
    conference: string | null;
    image: string | null;
    currentPlayers?: Array<PlayerRow>;
    createdAt: string;
    updatedAt: string;
};
export namespace TeamRow {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
}

