import {
  Flex,
  FormControl,
  FormField,
  FormItem,
  Textarea,
  Text,
} from "@suns/design-system";
import { FormMultiSelect } from "../FormMultiSelect";
import { ReportUpsertParams } from "@suns/api/generated-client/apollo";
import { ReportTeamFitLabels } from "@/pages/reports/reports-const";

export default function TeamFit({ readonly = false }) {
  if (readonly) {
    return (
      <Flex direction="down" gap="sm">
        <FormField
          name="teamFit"
          render={({ field }) => {
            return (
              <Flex direction="right" gap="sm">
                <Text heading muted size="lg">
                  Suns Fit
                </Text>
                <Text heading size="lg">
                  {
                    ReportTeamFitLabels[
                      field.value as ReportUpsertParams.teamFit
                    ]
                  }
                </Text>
              </Flex>
            );
          }}
        />
        <FormField
          name="teamFitNotes"
          render={({ field }) => (
            <Text size="sm" className="whitespace-pre text-wrap">
              {field.value}
            </Text>
          )}
        />
      </Flex>
    );
  }

  return (
    <Flex direction="down" gap="md">
      <FormMultiSelect
        name="teamFit"
        options={Object.values(ReportUpsertParams.teamFit).map((value) => ({
          value,
          label: ReportTeamFitLabels[value],
        }))}
      />
      <FormField
        name="teamFitNotes"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Textarea {...field} />
            </FormControl>
          </FormItem>
        )}
      />
    </Flex>
  );
}
